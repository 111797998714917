/**
 * @module MessengerInternationalApiMcupAuctionAppPledgesProject
 */
import { apiClient } from 'api/core';
import {
  MI_API_BASE_URL,
  MI_API_REQUEST_OPTIONS,
} from 'api/messengerinternational/constants';

/**
 * Function to retrieve a list of pledges for the specified project.
 *
 * @param {object} params - The function parameters.
 * @param {number|string} params.projectNumber - The project number.
 *
 * @throws {Error} - Throws an error if `projectNumber` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getPledgesByProject({ projectNumber }) {
  if (
    !projectNumber ||
    (typeof projectNumber !== 'number' && typeof projectNumber !== 'string')
  ) {
    throw new Error(
      'Sorry, there was a problem validating the project number provided.',
    );
  }

  return apiClient.get({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/pledges/project/${projectNumber}`,
  });
}
