/**
 * @module AuctionWebsite
 */
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { STATUS_TYPES } from 'utils/constants';
import { ResetView } from 'views/common/reset';
import { AdminView } from './admin';
import { HomeView } from './home';
import { PageNotFoundView } from '../common/page-not-found';

/**
 * Represents the Auction Website view.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.apiStatus] - The API status, to help determine the state of API calls.
 * @param {string} [props.className] - Additional classes to be applied to the AuctionApp.
 * @param {Array} props.donorList - The list of donors.
 * @param {Function} [props.onBackdropUpdate] - Function to call to update the main app backdrop properties state.
 * @param {Function} [props.onError] - Function to call to update the main app error state.
 * @param {Function} [props.onFetchAppData] - Handler function for data fetch request.
 * @param {Function} [props.onFetchPledgesForProject] - Handler function for pledges for project fetch request.
 * @param {Function} [props.onNewDonorSubmit] - Handler function for New Donor form submit.
 * @param {Function} [props.onResetApp] - Handler function for app reset request.
 * @param {Function} props.onSignOut - Handler function for user sign out.
 * @param {Function} [props.onUserAuthenticated] - The handler function to set the user data to state.
 * @param {object} props.pledgesForProjectsList - The list of pledges for projects.
 * @param {Array} props.projectList - The list of projects.
 * @param {User} [props.user] - The User data object.
 *
 * @returns {React.ReactElement} - The AuctionWebsite view component.
 */
export function AuctionWebsite({
  apiStatus,
  // eslint-disable-next-line no-unused-vars
  className,
  donorList,
  onBackdropUpdate,
  // eslint-disable-next-line no-unused-vars
  onError,
  onFetchAppData,
  onFetchPledgesForProject,
  onNewDonorSubmit,
  onResetApp,
  onUserAuthenticated,
  onSignOut,
  pledgesForProjectsList,
  projectList,
  user,
}) {
  return (
    <Routes>
      <Route element={<PageNotFoundView user={user} />} path="/404" />

      <Route
        element={
          <HomeView
            apiStatus={apiStatus}
            donorList={donorList}
            onBackdropUpdate={onBackdropUpdate}
            onFetchAppData={onFetchAppData}
            onFetchPledgesForProject={onFetchPledgesForProject}
            onResetApp={onResetApp}
            onUserAuthenticated={onUserAuthenticated}
            pledgesForProjectsList={pledgesForProjectsList}
            projectList={projectList}
            user={user}
          />
        }
        path="/"
      />
      <Route
        element={
          <AdminView
            apiStatus={apiStatus}
            donorList={donorList}
            onBackdropUpdate={onBackdropUpdate}
            onFetchAppData={onFetchAppData}
            onNewDonorSubmit={onNewDonorSubmit}
            onResetApp={onResetApp}
            onSignOut={onSignOut}
            onUserAuthenticated={onUserAuthenticated}
            projectList={projectList}
            user={user}
          />
        }
        path="/admin"
      />

      <Route element={<ResetView />} path="/reset" />

      {/* Default Not Found View */}
      <Route element={<PageNotFoundView user={user} />} path="*" />
    </Routes>
  );
}

AuctionWebsite.propTypes = {
  apiStatus: PropTypes.string,
  className: PropTypes.string,
  donorList: PropTypes.array,
  onBackdropUpdate: PropTypes.func,
  onError: PropTypes.func,
  onFetchAppData: PropTypes.func,
  onFetchPledgesForProject: PropTypes.func,
  onNewDonorSubmit: PropTypes.func,
  onResetApp: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onUserAuthenticated: PropTypes.func,
  pledgesForProjectsList: PropTypes.object,
  projectList: PropTypes.array,
  user: PropTypes.shape({
    expiry: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.string,
    username: PropTypes.string,
  }),
};

AuctionWebsite.defaultProps = {
  apiStatus: STATUS_TYPES.IDLE,
  className: '',
  onBackdropUpdate: () => {},
  onError: () => {},
  onFetchAppData: () => {},
  onFetchPledgesForProject: () => {},
  onNewDonorSubmit: () => {},
  onResetApp: () => {},
  onSignOut: () => {},
  onUserAuthenticated: () => {},
  user: null,
};
