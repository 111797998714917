/**
 * @module apiClient
 */

/* eslint-disable max-classes-per-file */
import 'cross-fetch/polyfill';
import * as Sentry from '@sentry/browser';
import { paramsToQuery } from 'utils/helpers';

/**
 * Function to build a normalized ApiResponse object.
 *
 * @param {object} config - The config object.
 * @param {string} config.message - The message value.
 * @param {object} config.response - The response object.
 * @param {number} config.status - The response status code.
 *
 * @returns {ApiResponse} - The ApiResponse object.
 */
function buildApiResponse({ message, response, status }) {
  let data = null;
  let errors = [];
  let pagination = {
    offset: 0,
  };

  // Since not all `response` objects have `data` attribute, check it
  // conditionally to ensure we get the data we want.
  let responseData = response;
  if (response?.data) {
    responseData = response.data;
  }

  const errorsData = response?.errors || null;
  const paginationData = response?.pagination || { offset: 0 };

  // If there is pagination data, set our object values accordingly.
  if (paginationData) {
    pagination = {
      offset: paginationData.offset
        ? paginationData.offset + 1
        : paginationData.page || 0,
    };
    // Only set limit if it exists in pagination data, as not all return data
    // will use and have this attribute set.
    if (paginationData.limit) {
      pagination.limit = paginationData.limit;
    }
  }

  // If there are errors, set our errors array accordingly.
  if (errorsData && Array.isArray(errorsData)) {
    errors = errorsData.map((item) => {
      if (typeof item === 'object') {
        const {
          errors: itemErrors,
          key: itemKey,
          message: itemMessage,
        } = item.error || item;

        if (itemErrors && Array.isArray(itemErrors)) {
          const nestedErrors = itemErrors.map((errorItem) => {
            return new Error(errorItem.message);
          });
          return {
            errors: nestedErrors,
            key: itemKey,
          };
        }

        return new Error(itemMessage);
      }
      // For tests, this reports uncovered as we don't have case for this in
      // any integration yet.
      return item;
    });
  }

  if (errors && errors.length) {
    data = {};
  } else if (Array.isArray(responseData)) {
    data = responseData.map((item) => {
      return item;
    });
  } else if (typeof responseData === 'object') {
    data = responseData;
  }

  return {
    data,
    errors,
    message,
    pagination,
    status,
  };
}

/**
 * Base apiClient that wraps fetch.
 *
 * @param {object} config - The config object.
 * @param {object} config.options - The config options.
 * @param {string} config.url - The url to fetch.
 *
 * @throws {Error}
 *
 * @returns {Promise} - The response model object.
 */
async function apiClient({ options, url }) {
  let errorMessage;
  try {
    const response = await fetch(url, options);
    if (response.status === 204) {
      return buildApiResponse({
        message: 'No Content',
        response: {},
        status: response.status,
      });
    }

    let json;
    try {
      json = await response.json();
    } catch (e) {
      errorMessage = `Sorry, there was a problem parsing the data.`;
      Sentry.captureException(e);
    }

    return buildApiResponse({
      message: json?.message || `Response received.`, // `from ${url}`,
      response: json,
      status: response.status,
    });
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(errorMessage || error.message);
  }
}

/**
 * Make a DELETE call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} [config.params] - DELETE params, will be converted to valid query string with support for bracket-less arrays.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function del({
  options = {},
  params = {},
  url,
  ...passThroughProps
}) {
  const queryParams = paramsToQuery(params).length
    ? `?${paramsToQuery(params)}`
    : '';
  return apiClient({
    options: {
      ...options,
      method: 'DELETE',
    },
    url: `${url}${queryParams}`,
    ...passThroughProps,
  });
}

/**
 * Make a GET call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} [config.params] - GET params, will be converted to valid query string with support for bracket-less arrays.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function get({
  options = {},
  params = {},
  url,
  ...passThroughProps
}) {
  const queryParams = paramsToQuery(params).length
    ? `?${paramsToQuery(params)}`
    : '';
  return apiClient({
    options: {
      ...options,
      method: 'GET',
    },
    url: `${url}${queryParams}`,
    ...passThroughProps,
  });
}

/**
 * Make a PATCH call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} config.params - PATCH body params.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function patch({
  options = {},
  params,
  url,
  ...passThroughProps
}) {
  const formBody = [];
  const keyValues = Object.entries(params);
  keyValues.forEach((entry) => {
    const encodedKey = encodeURIComponent(entry[0]);
    const encodedValue = encodeURIComponent(entry[1]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  return apiClient({
    options: {
      ...options,
      body: formBody.join('&'),
      headers: {
        ...options.headers,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      method: 'PATCH',
    },
    url,
    ...passThroughProps,
  });
}

/**
 * Make a POST call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object|string} config.params - POST body params.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function post({ options = {}, params, url, ...passThroughProps }) {
  const formBody = [];
  const keyValues = Object.entries(params);
  keyValues.forEach((entry) => {
    const encodedKey = encodeURIComponent(entry[0]);
    const encodedValue = encodeURIComponent(entry[1]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  return apiClient({
    options: {
      ...options,
      body: formBody.join('&'),
      headers: {
        ...options.headers,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      method: 'POST',
    },
    url,
    ...passThroughProps,
  });
}

/**
 * Make a PUT call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} config.params - PUT body params.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function put({ options = {}, params, url, ...passThroughProps }) {
  const formBody = [];
  const keyValues = Object.entries(params);
  keyValues.forEach((entry) => {
    const encodedKey = encodeURIComponent(entry[0]);
    const encodedValue = encodeURIComponent(entry[1]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  return apiClient({
    options: {
      ...options,
      body: formBody.join('&'),
      headers: {
        ...options.headers,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      method: 'PUT',
    },
    url,
    ...passThroughProps,
  });
}
