/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module LocaleContext
 */
import React from 'react';
import PropTypes from 'prop-types';
import useLocaleUtils from 'hooks/useLocale';

const LocaleStateContext = React.createContext();

/**
 * Represents the Locale provider.
 *
 * @param {object} props - The provider props object.
 * @param {React.ReactElement} props.children - The provider content.
 *
 * @returns {React.Context} The LocaleProvider.
 */
export function LocaleProvider({ children, ...props }) {
  const state = useLocaleUtils('en');
  return (
    <LocaleStateContext.Provider value={state} {...props}>
      {children}
    </LocaleStateContext.Provider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.node,
};

LocaleProvider.defaultProps = {
  children: null,
};

/**
 * Represents the locale context.
 *
 * @throws {Error} - Throws an error if not used within a LocaleProvider.
 *
 * @returns {React.Context} The LocaleStateContext context.
 */
export function useLocale() {
  const context = React.useContext(LocaleStateContext);
  if (context === undefined) {
    throw new Error('LocaleContext must be used within a LocaleProvider');
  }
  return context;
}
