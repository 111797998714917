/**
 * @module Helpers
 */
import React from 'react';
import md5 from 'md5';
import { LOCAL_STORAGE_KEYS } from './constants';

/**
 * Convenience method to return boolean value of whether or not the specified
 * API response object contains a successful HTTP response status code.
 *
 * @param {ApiResponse} apiResponseObject - The API response object.
 *
 * @returns {boolean} True if API response object status code is successful.
 */
export function apiResponseIsSuccess(apiResponseObject) {
  return apiResponseObject.status >= 200 && apiResponseObject.status <= 299;
}

/**
 * Convenience method to return boolean value of whether or not the specified
 * API response object contains a data object that is an array.
 *
 * @param {ApiResponse} apiResponseObject - The API response object.
 *
 * @returns {boolean} True if API response object data is an array.
 */
export function apiResponseDataIsArray(apiResponseObject) {
  if (!apiResponseObject || !apiResponseObject.data) {
    return false;
  }
  return (
    apiResponseObject.data &&
    typeof apiResponseObject.data === 'object' &&
    Array.isArray(apiResponseObject.data)
  );
}

/**
 * Convenience method to return boolean value of whether or not the specified
 * API response object contains a data object that is an object.
 *
 * @param {ApiResponse} apiResponseObject - The API response object.
 *
 * @returns {boolean} True if API response object data is an object.
 */
export function apiResponseDataIsObject(apiResponseObject) {
  if (!apiResponseObject || !apiResponseObject.data) {
    return false;
  }
  return (
    apiResponseObject.data &&
    typeof apiResponseObject.data === 'object' &&
    !Array.isArray(apiResponseObject.data)
  );
}

/**
 * Convenience method to return boolean value of whether or not the specified
 * API response object has a valid HTTP status and contains a data object that
 * is an object.
 *
 * @param {ApiResponse} apiResponseObject - The API response object.
 * @param {ApiResponse} dataObjectType - The data object type which the apiResponseObject's `data` attribute should match.
 *
 * @returns {boolean} True if API response object data is an object and has a successful HTTP status code.
 */
export function apiResponseComplete(
  apiResponseObject,
  dataObjectType = 'object',
) {
  return (
    apiResponseObject.status === 204 ||
    (apiResponseIsSuccess(apiResponseObject) && dataObjectType === 'array'
      ? apiResponseDataIsArray(apiResponseObject)
      : apiResponseDataIsObject(apiResponseObject))
  );
}

/**
 * Convenience method to return the specified string with line breaks (\n)
 * replaced with paragraph tags for HTML output.
 *
 * @param {string} string - The string to use in replacing line breaks.
 *
 * @throws {Error} - Throws an error if the specified string is not a string.
 *
 * @returns {string} The string with applicable HTML markup applied using <p> tags.
 */
export function convertTextAreaContentToHtml(string) {
  if (typeof string !== 'string') {
    throw new Error('Sorry, there was a problem validating the data provided.');
  }

  return `<p>${string.replace(/\n/g, '</p><p>')}</p>`;
}

/**
 * Convenience function to find and return the object in the specified array
 * that has the specified attribute.
 *
 * @param {object} params - The function parameters object.
 * @param {Array} params.array - The source array of objects in which to search.
 * @param {number|string} params.attribute - The object attribute name for which to search.
 * @param {number|string} params.attributeValue - The attribute value for which to search.
 *
 * @returns {object} The object from the specified array that contains the attribute and value provided.
 */
export function getArrayObjectByAttribute({
  array,
  attribute,
  attributeValue,
}) {
  if (!array || !attribute || !attributeValue) {
    return null;
  }
  const result = array.find((obj) => {
    return obj[attribute] === attributeValue;
  });
  return result || null;
}

/**
 * Convenience function to has the specified string with the md5 hash algorithm.
 *
 * @param {string} string - The string value to be hashed with md5 algorithm.
 *
 * @returns {string} The md5-hashed value of the specified string.
 */
export function createMd5Hash(string) {
  return md5(string);
}

/**
 * Convenience function to generate and return a custom local donor ID value.
 *
 * @param {Donor} donor - The donor data object.
 *
 * @returns {string} The generated local donor ID.
 */
export function generateLocalDonorId(donor) {
  if (!donor) {
    return '';
  }
  return createMd5Hash(
    `${donor.donorDisplayName || ''}|${donor.firstName || ''}|${
      donor.lastName || ''
    }}`,
  );
}

/**
 * Convenience function to generate and return a custom pledge ID value.
 *
 * @param {number|string} amount - The pledge amount.
 *
 * @returns {string} The generated pledge ID.
 */
export function generatePledgeId(amount) {
  if (!amount) {
    return '';
  }
  return createMd5Hash(`${amount}|${Date.now()}}`);
}

/**
 * Parses a query string into an object of key/value string pairs.
 *
 * @param {string} queryString - The query string.
 *
 * @throws {Error} - Throws an error if this function is not run in a browser.
 *
 * @returns {object<string>} - The querystring key/value string pairs.
 *
 * @example
 * // returns `{length: '12', status: 'active', disabled: 'false'}`
 * paramsFromQuery('?length=12&status=active&disabled=false')
 *
 * @example
 * import { useLocation } from 'react-router-dom'
 * import { paramsFromQuery } from '@youversion/utils'
 *
 * function MyComponent() {
 *   const location = useLocation()
 *
 *   const [query, setQuery] = React.useState(
 *     () => paramsFromQuery(location.search)
 *   )
 *
 *   React.useEffect(() => {
 *     setQuery(paramsFromQuery(location.search))
 *   }, [location.search])
 *
 *   return (
 *     <>
 *       {query?.status === 'active' ?? 'Displaying active plans'}
 *     </>
 *   )
 * }
 */
export function paramsFromQuery(queryString) {
  const output = {};

  const queryIterator = new URLSearchParams(queryString);
  queryIterator.forEach((value, key) => {
    output[key] = value;
  });

  return output;
}

/**
 * Convert params object to JSON encoded string.
 *
 * @param {object} params - A params object.
 *
 * @throws {Error} - Throws an error if `params` is not an object.
 *
 * @returns {string} - The JSON encoded string.
 */
export function paramsToJSON(params) {
  if (!params) return '';
  if (!(typeof params === 'object')) {
    throw new Error('Sorry, there was a problem validating the data provided.');
  }
  return JSON.stringify(params);
}

/**
 * Convert params object to valid query string with support for bracket-less arrays.
 *
 * @param {object} params - A params object.
 *
 * @throws {Error} - Throws an error if `params` is not an object.
 *
 * @returns {string} - The query string.
 */
export function paramsToQuery(params) {
  if (!(typeof params === 'object')) {
    throw new Error('Sorry, there was a problem validating the data provided.');
  }

  return Object.keys(params)
    .map((name) => {
      const value = params[name];

      if (typeof value === 'undefined') {
        return '';
      }

      if (Array.isArray(value)) {
        return value
          .map((arrayValue) => {
            return `${name}=${arrayValue}`;
          })
          .sort()
          .join('&');
      }

      if (['number', 'string'].indexOf(typeof value) === -1) {
        throw new Error(
          `Sorry, there was a problem validating the data provided.`,
        );
      }

      return `${name}=${value}`;
    })
    .filter((value) => value && value.length)
    .sort()
    .join('&');
}

/**
 * Convenience function to return a pluralized version of the specified name.
 *
 * @param {string} name - The name to pluralize.
 *
 * @throws {Error} - Throws an error if `name` is not an object.
 *
 * @returns {string} The pluralized name.
 */
export function pluralizeName(name) {
  if (!(typeof name === 'string')) {
    throw new Error(
      'Sorry, there was a problem pluralizing the specified name.',
    );
  }

  const isLastCharacterS = Boolean(
    name.charAt(name.length - 1).toLowerCase() === 's',
  );
  if (isLastCharacterS) {
    return `${name}'`;
  }
  return `${name}'s`;
}

/**
 * Convenience function to format the specified numerical amount as a
 * string with commas and decimals, in USD currency format.
 *
 * @param {number} usdAmount - The amount to be formatted in USD.
 * @param {number} numDecimals - The number of decimal digits to include (default 2).
 *
 * @returns {string} - The formatted amount in USD (e.g. $1,234,000).
 */
export function formatUSD(usdAmount, numDecimals) {
  return Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits:
      numDecimals !== null && numDecimals !== undefined ? numDecimals : 2,
    style: 'currency',
  }).format(usdAmount);
}

/**
 * Convenience function to format the specified value for display in the calculator display.
 *
 * @param {string} value - The value to format for display.
 *
 * @returns {string} The formatted value ready for display.
 */
export function formatNumberForDisplay(value) {
  if (!value) {
    return '$0';
  }
  const valueHasDecimal = value.indexOf('.') > -1;
  const formattedUSD = formatUSD(value);
  const formattedParts = formattedUSD.split('.');
  if (!valueHasDecimal) {
    return formattedParts[0];
  }
  return `${formattedParts[0]}.${value.split('.')[1]}`;
}

/**
 * Convenience function to retrieve localStorage item, if found. Returns `null` if not found.
 *
 * @param {string} key - The key of the localStorage item.
 * @param {SerializationOptions} serializationOptions - Object of options.
 *
 * @returns {*} - The value of the local storage item.
 */
export function getLocalStorageItem(key, { deserialize = JSON.parse } = {}) {
  const valueInLocalStorage = window.localStorage.getItem(key);
  if (valueInLocalStorage) {
    try {
      return deserialize(valueInLocalStorage);
    } catch (error) {
      return null;
    }
  }
  return null;
}

/**
 * Convenience function to remove localStorage item with the specified key, if found.
 *
 * @param {string} key - The key of the localStorage item.
 */
export function removeLocalStorageItem(key) {
  const valueInLocalStorage = window.localStorage.getItem(key);
  if (valueInLocalStorage) {
    window.localStorage.removeItem(key);
  }
}

/**
 * Convenience function to clear all Faith Story App local storage items.
 *
 * @param {boolean} retainUser - Optional boolean flag denoting whether or not to keep the user data (Default: false).
 */
export function clearMiLocalStorageItems(retainUser = false) {
  Object.entries(LOCAL_STORAGE_KEYS).forEach((entry) => {
    if (!retainUser || (retainUser && entry[1] !== LOCAL_STORAGE_KEYS.user)) {
      removeLocalStorageItem(entry[1]);
    }
  });
}

/**
 * Convenience function to use localStorage for state-based logic.
 *
 * @param {string} key - The key of the localStorage item.
 * @param {*} defaultValue - The default value of the item to store.
 * @param {SerializationOptions} serializationOptions - Object of options.
 *
 * @returns {Array} - React state array.
 */
export function useLocalStorageState(
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {},
) {
  const [state, setState] = React.useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      try {
        return deserialize(valueInLocalStorage);
      } catch (error) {
        window.localStorage.removeItem(key);
      }
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  });

  const prevKeyRef = React.useRef(key);

  React.useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;
    window.localStorage.setItem(key, serialize(state));
  }, [key, serialize, state]);

  return [state, setState];
}
