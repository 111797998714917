/**
 * @module ConfirmationDialog
 */
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useLocale } from 'context/locale';
import { useStyles } from 'utils/styles';

/**
 * Represents the Confirmation Dialog view.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.cancelLabel] - The cancel button label.
 * @param {string} [props.confirmLabel] - The confirm button label.
 * @param {string} props.message - The dialog message.
 * @param {Function} [props.onCancelClick] - The function to fire on Cancel button click.
 * @param {Function} [props.onClose] - The function to fire on dialog close.
 * @param {Function} [props.onConfirmClick] - The function to fire on Confirm button click.
 * @param {Function} [props.onTertiaryClick] - The function to fire on tertiary button click.
 * @param {boolean} [props.open] - Boolean flag for whether or not dialog is open.
 * @param {string} [props.tertiaryLabel] - The tertiary button label.
 * @param {string} [props.title] - The dialog title.
 *
 * @returns {React.ReactElement} - The ConfirmationDialog component.
 */
export function ConfirmationDialog({
  cancelLabel,
  confirmLabel,
  message,
  onCancelClick,
  onClose,
  onConfirmClick,
  onTertiaryClick,
  open,
  tertiaryLabel,
  title,
}) {
  const { strings } = useLocale();
  const classes = useStyles();

  return (
    <>
      {strings ? (
        <>
          <Dialog
            aria-labelledby="confirmation-dialog-title"
            id="dialog_confirm"
            maxWidth="sm"
            onClose={onClose}
            open={open}
            scroll="paper"
          >
            {title ? (
              <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            ) : null}
            <DialogContent dividers={false}>
              <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions className={classes.flexJustifyContentCenter}>
              {cancelLabel ? (
                <Button
                  color="secondary"
                  id="btn_confirmation_dialog_cancel"
                  onClick={onCancelClick}
                  variant="outlined"
                >
                  {cancelLabel || strings.labels.cancel}
                </Button>
              ) : null}
              {tertiaryLabel ? (
                <Button
                  className="primary"
                  color="primary"
                  id="btn_confirmation_dialog_tertiary"
                  onClick={onTertiaryClick}
                  variant="contained"
                >
                  {tertiaryLabel}
                </Button>
              ) : null}
              {confirmLabel ? (
                <Button
                  color="secondary"
                  id="btn_confirmation_dialog_confirm"
                  onClick={onConfirmClick}
                  variant="contained"
                >
                  {confirmLabel || strings.labels.ok}
                </Button>
              ) : null}
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
}

ConfirmationDialog.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  message: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func,
  onClose: PropTypes.func,
  onConfirmClick: PropTypes.func,
  onTertiaryClick: PropTypes.func,
  open: PropTypes.bool,
  tertiaryLabel: PropTypes.string,
  title: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  cancelLabel: null,
  confirmLabel: null,
  onCancelClick: () => {},
  onClose: () => {},
  onConfirmClick: () => {},
  onTertiaryClick: () => {},
  open: false,
  tertiaryLabel: null,
  title: null,
};
