/**
 * @module MessengerInternationalApiMcupAuctionAppProjectsDonor
 */
import { apiClient } from 'api/core';
import {
  MI_API_BASE_URL,
  MI_API_REQUEST_OPTIONS,
} from 'api/messengerinternational/constants';

/**
 * Function to retrieve project pledges for all donors.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getProjects() {
  return apiClient.get({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/projects/donor`,
  });
}
