/**
 * @module AppMenu
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Drawer,
  IconButton,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import { useLocale } from 'context/locale';
import { useStyles } from 'utils/styles';
import { IS_APP, LOCAL_STORAGE_KEYS } from 'utils/constants';
import { getLocalStorageItem } from 'utils/helpers';
import { ConfirmationDialog } from '../dialogs';

/**
 * The App Menu component for the application AppBar.
 *
 * @param {object} props - The component props object.
 * @param {string} props.className - Additional classes to be applied to the menu.
 * @param {Array} props.donorList - The list of donors.
 * @param {Function} [props.onCloseAuction] - Handler function for auction close request.
 * @param {Function} [props.onFetchAppData] - Handler function for data fetch request.
 * @param {Function} [props.onResetApp] - Handler function for app reset request.
 * @param {Function} props.onSignOut - Function to handle user sign out.
 * @param {Function} [props.onUploadAndSyncData] - Handler function for data fetch request.
 *
 * @returns {React.ReactElement} - The AppMenu component.
 */
export function AppMenu({
  className,
  donorList,
  onCloseAuction,
  onFetchAppData,
  onResetApp,
  onSignOut,
  onUploadAndSyncData,
  ...props
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const { strings } = useLocale();

  const open = Boolean(anchorEl);

  const defaultGenericDialogData = {
    message: '',
    open: false,
    title: '',
  };

  const [genericDialogData, setGenericDialogData] = React.useState(
    defaultGenericDialogData,
  );
  const [isCloseAuctionDialogOpen, setIsCloseAuctionDialogOpen] =
    React.useState(false);
  const [isResetAppDialogOpen, setIsResetAppDialogOpen] = React.useState(false);
  const filteredDonors = donorList
    ? donorList.filter((donor) => donor.donorID === null)
    : [];
  const storedPledges =
    getLocalStorageItem(LOCAL_STORAGE_KEYS.appDonations) || {};
  const filteredPledges = Object.values(storedPledges).filter(
    (storedPledge) => !storedPledge.isSynced,
  );

  return (
    <>
      {strings ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Box className={className} {...props}>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            aria-label={strings.menu.menu}
            className={classes.appBarMenuIcon}
            color="inherit"
            edge="end"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MenuIcon className={classes.appMenuIcon} />
          </IconButton>
          <Drawer
            anchor="top"
            className={classes.menuDrawer}
            id="menu-appbar"
            keepMounted={true}
            onClose={() => {
              setAnchorEl(null);
            }}
            open={open}
          >
            <Box className={classes.textRight}>
              <IconButton
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                <ClearIcon className={classes.appMenuCloseIcon} />
              </IconButton>
            </Box>
            {IS_APP ? (
              <>
                <ListItem
                  className={['menuListItem', 'primaryItem'].join(' ')}
                  component={RouterLink}
                  onClick={() => {
                    setIsResetAppDialogOpen(true);
                  }}
                  to=""
                >
                  <ListItemText
                    classes={{ primary: classes.appMenuPrimaryItem }}
                    primary={strings.menu.reset_app_data}
                  />
                </ListItem>
                <ListItem
                  className={['menuListItem', 'primaryItem'].join(' ')}
                  component={RouterLink}
                  onClick={() => {
                    setAnchorEl(null);
                    onFetchAppData();
                  }}
                  to=""
                >
                  <ListItemText
                    classes={{ primary: classes.appMenuPrimaryItem }}
                    primary={strings.menu.download_data}
                  />
                </ListItem>
                <ListItem
                  className={['menuListItem', 'primaryItem'].join(' ')}
                  component={RouterLink}
                  onClick={() => {
                    setAnchorEl(null);
                    onUploadAndSyncData();
                  }}
                  to=""
                >
                  <ListItemText
                    classes={{ primary: classes.appMenuPrimaryItem }}
                    primary={`${strings.menu.upload_data} (${
                      filteredDonors.length
                    } donor${filteredDonors.length !== 1 ? 's' : ''}, ${
                      filteredPledges.length
                    } pledge${filteredPledges.length !== 1 ? 's' : ''})`}
                  />
                </ListItem>
                <ListItem
                  className={['menuListItem', 'primaryItem'].join(' ')}
                  component={RouterLink}
                  onClick={() => {
                    setIsCloseAuctionDialogOpen(true);
                  }}
                  to=""
                >
                  <ListItemText
                    classes={{ primary: classes.appMenuPrimaryItem }}
                    primary={strings.menu.close_auction}
                  />
                </ListItem>
              </>
            ) : (
              <ListItem
                className={['menuListItem', 'primaryItem'].join(' ')}
                component={RouterLink}
                onClick={() => {
                  setIsResetAppDialogOpen(true);
                }}
                to=""
              >
                <ListItemText
                  classes={{ primary: classes.appMenuPrimaryItem }}
                  primary={strings.menu.reset_app_data}
                />
              </ListItem>
            )}
            <ListItem
              className={['menuListItem', 'primaryItem'].join(' ')}
              component={RouterLink}
              onClick={onSignOut}
              to=""
            >
              <ListItemText
                classes={{ primary: classes.appMenuPrimaryItem }}
                primary={strings.menu.sign_out}
              />
            </ListItem>
          </Drawer>

          {/* Reset App dialog. */}
          <ConfirmationDialog
            cancelLabel={strings.labels.cancel}
            confirmLabel={strings.labels.yes}
            message={`${strings.dialogs.messages.reset_app_data}`}
            onCancelClick={() => {
              setIsResetAppDialogOpen(false);
            }}
            onClose={() => {
              setIsResetAppDialogOpen(false);
            }}
            onConfirmClick={() => {
              setIsResetAppDialogOpen(false);
              setAnchorEl(null);
              onResetApp();
            }}
            open={Boolean(isResetAppDialogOpen)}
            title={strings.dialogs.titles.reset_app_data}
          />

          {/* Generic confirmation dialog. */}
          <ConfirmationDialog
            confirmLabel={strings.labels.ok}
            message={genericDialogData.message}
            onCancelClick={() => {
              setGenericDialogData(() => {
                return defaultGenericDialogData;
              });
            }}
            onClose={() => {
              setGenericDialogData(() => {
                return defaultGenericDialogData;
              });
            }}
            onConfirmClick={() => {
              setGenericDialogData(() => {
                return defaultGenericDialogData;
              });
            }}
            open={Boolean(genericDialogData.open)}
            title={genericDialogData.title}
          />

          {/* Close Auction dialog. */}
          <ConfirmationDialog
            cancelLabel={strings.labels.cancel}
            confirmLabel={strings.labels.yes}
            message={`${strings.dialogs.messages.close_auction}`}
            onCancelClick={() => {
              setIsCloseAuctionDialogOpen(false);
            }}
            onClose={() => {
              setIsCloseAuctionDialogOpen(false);
            }}
            onConfirmClick={() => {
              setIsCloseAuctionDialogOpen(false);
              setAnchorEl(null);
              onCloseAuction();
            }}
            open={Boolean(isCloseAuctionDialogOpen)}
            title={strings.dialogs.titles.close_auction}
          />
        </Box>
      ) : null}
    </>
  );
}

AppMenu.propTypes = {
  className: PropTypes.string,
  donorList: PropTypes.array,
  onCloseAuction: PropTypes.func,
  onFetchAppData: PropTypes.func,
  onResetApp: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onUploadAndSyncData: PropTypes.func,
};

AppMenu.defaultProps = {
  className: '',
  onCloseAuction: () => {},
  onFetchAppData: () => {},
  onResetApp: () => {},
  onUploadAndSyncData: () => {},
};
