/**
 * @module SignInView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Grid } from '@material-ui/core';
import { user } from 'api/messengerinternational/mcup-auction-app';
import { SignInForm } from 'components/ui/forms';
import { useLocale } from 'context/locale';
import { STATUS_TYPES } from 'utils/constants';
import { apiResponseComplete } from 'utils/helpers';
import { useStyles } from 'utils/styles';

/**
 * Represents the Sign In view shown to unauthenticated users.
 *
 * @param {object} props - The function properties object.
 * @param {string} [props.className] - Additional classes to be applied to the main container.
 * @param {Function} [props.onBackdropUpdate] - Function to call to update the backdrop properties state.
 * @param {Function} [props.onError] - Function to call to update the main app error state.
 * @param {Function} [props.onUserAuthenticated] - The handler function for user authentication success. Includes the parameter `user`, which is a User data object containing all user data from the API.
 * @param {string} [props.referrer] - Optional referrer query parameter value to pass along to Sign In.
 *
 * @returns {React.ReactElement} - The SignInView component.
 */
export function SignInView({
  className,
  onBackdropUpdate,
  onError,
  onUserAuthenticated,
  referrer,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { strings } = useLocale();
  const [apiStatus, setApiStatus] = React.useState(STATUS_TYPES.IDLE);
  const [formError, setFormError] = React.useState(null);

  /**
   * Handler function for sign in form submit click.
   *
   * @param {object} formData - The form data submitted.
   */
  async function handleSubmit(formData) {
    onBackdropUpdate({
      open: true,
      statusMessage: strings?.status_messages?.submitting || '',
      value: 0,
    });
    setFormError(null);
    setApiStatus(STATUS_TYPES.PENDING);
    try {
      const loginUserResponse = await user.loginUser({
        password: formData.password,
        username: formData.username,
      });
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      if (apiResponseComplete(loginUserResponse)) {
        setApiStatus(STATUS_TYPES.RESOLVED);
        onUserAuthenticated(loginUserResponse.data);
        navigate(referrer);
      } else {
        const loginError = new Error(loginUserResponse.message);
        setApiStatus(STATUS_TYPES.ERROR);
        setFormError(loginError);
      }
    } catch (error) {
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      setApiStatus(STATUS_TYPES.ERROR);
      setFormError(error);
      Sentry.captureException(error);
      onError(error);
    }
  }

  /**
   * Single-run convenience effect to ensure the page loads at the top.
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {strings ? (
        <Grid
          className={[
            className,
            classes.appWrapper,
            classes.backgroundSandLight,
          ].join(' ')}
        >
          <Grid
            className={[
              classes.appContainer,
              classes.gridAlignItemsCenter,
            ].join(' ')}
            container={true}
          >
            <SignInForm
              apiStatus={apiStatus}
              className={classes.contentCenter}
              error={formError}
              onSubmit={handleSubmit}
              referrer={referrer}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

SignInView.propTypes = {
  className: PropTypes.string,
  onBackdropUpdate: PropTypes.func,
  onError: PropTypes.func,
  onUserAuthenticated: PropTypes.func,
  referrer: PropTypes.string,
};

SignInView.defaultProps = {
  className: '',
  onBackdropUpdate: () => {},
  onError: () => {},
  onUserAuthenticated: () => {},
  referrer: '',
};
