/**
 * @module Styles
 */
import { makeStyles } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
import { COLORS, WEBSITE_FONT_FAMILY } from "./constants";

const breakpoints = createBreakpoints({});

export const theme = createTheme({
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: `0 1px 2px rgba(0, 0, 0, 0.25)`,
      },
      containedPrimary: {
        "& > a": {
          color: `${COLORS.forest.dark} !important`,
        },
        "&:disabled": {
          backgroundColor: `${COLORS.sand.light} !important`,
          boxShadow: `0 1px 2px rgba(0, 0, 0, 0.125)`,
          color: `${COLORS.forest.dark} !important`,
        },
        "&:hover": {
          color: `${COLORS.white} !important`,
        },
        color: `${COLORS.forest.dark} !important`,
      },
      containedSecondary: {
        "& > a": {
          color: `${COLORS.sand.light} !important`,
        },
        "&:disabled": {
          backgroundColor: `rgba(0, 0, 0, 0.15) !important`,
          color: `rgba(0, 0, 0, 0.20) !important`,
        },
        "&:hover": {
          backgroundColor: `${COLORS.forest.dark} !important`,
          color: `${COLORS.sand.light} !important`,
        },
        backgroundColor: `${COLORS.forest.medium} !important`,
        color: `${COLORS.sand.light} !important`,
      },
      outlinedPrimary: {
        "& > a": {
          color: `${COLORS.sand.dark} !important`,
        },
        "&:hover": {
          backgroundColor: COLORS.sand.dark,
          color: `${COLORS.white} !important`,
        },
        "@media (hover: none)": {
          "&:hover": {
            color: `${COLORS.sand.dark} !important`,
          },
          color: `${COLORS.sand.dark} !important`,
        },
        color: `${COLORS.sand.dark} !important`,
      },
      outlinedSecondary: {
        "& > a": {
          borderColor: COLORS.forest.medium,
          color: `${COLORS.forest.medium} !important`,
        },
        "&:hover": {
          backgroundColor: COLORS.forest.light,
          borderColor: COLORS.forest.medium,
          color: `${COLORS.forest.dark} !important`,
        },
        "@media (hover: none)": {
          "&:hover": {
            borderColor: COLORS.forest.medium,
            color: `${COLORS.forest.medium} !important`,
          },
          color: `${COLORS.forest.medium} !important`,
        },
        borderColor: COLORS.forest.medium,
        color: `${COLORS.forest.medium} !important`,
      },
      root: {
        "&.active": {
          backgroundColor: `${COLORS.sand.medium} !important`,
        },
        "&.transparent": {
          "&:active": {
            backgroundColor: "transparent !important",
          },
          "&:hover": {
            backgroundColor: "transparent !important",
          },
          backgroundColor: "transparent !important",
        },
        "&:hover": {
          backgroundColor: COLORS.sand.medium,
        },
        borderRadius: 99,
        fontWeight: 700,
        lineHeight: 1.25,
        padding: "0.5rem 1rem 0.5rem !important",
        textTransform: "inherit",
      },
      textSecondary: {
        "&:hover": {
          backgroundColor: COLORS.forest.dark,
        },
        color: COLORS.forest.dark,
      },
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiCardActionArea: {
      focusHighlight: {
        opacity: "0 !important",
      },
      root: {
        "&:hover": {
          focusHighlight: {
            opacity: "0 !important",
          },
        },
      },
    },
    MuiCardActions: {
      root: {
        padding: "0.5rem 1.5rem 1.5rem !important",
      },
    },
    MuiCardContent: {
      root: {
        padding: "1.5rem",
      },
    },
    MuiCollapse: {
      hidden: {
        "& > .MuiCollapse-wrapper": {
          opacity: "0",
        },
      },
      root: {
        "& > .MuiCollapse-wrapper": {
          opacity: "1",
          transition: "opacity 1.5s",
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: "1.5rem !important",
      },
    },
    MuiDialogContent: {
      root: {
        "&:first-child": {
          paddingTop: "1.5rem",
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "1.5rem 1.5rem 0.5rem 1.5rem",
      },
    },
    MuiDrawer: {
      paperAnchorTop: {
        backgroundColor: COLORS.forest.medium,
        padding: "1rem 1rem 4rem",
      },
    },
    MuiError: {
      root: {
        color: COLORS.utility.danger.dark,
      },
    },
    MuiFormControlLabel: {
      root: {
        "& > .MuiFormControlLabel-label": {
          [breakpoints.down("sm")]: {
            fontSize: "0.875rem",
            lineHeight: "1.25",
          },
        },
        alignItems: "baseline !important",
        [breakpoints.down("sm")]: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiFormGroup: {
      root: {
        paddingLeft: "1.5rem",
        [breakpoints.down("sm")]: {
          paddingLeft: "1rem",
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: COLORS.gray.muted,
        },
        color: COLORS.gray.muted,
      },
    },
    MuiInputBase: {
      input: {
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
        },
      },
      "input[tpe=number]": {
        "-moz-appearance": "textfield",
      },
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: COLORS.forest.medium,
      },
      colorPrimary: {
        backgroundColor: COLORS.gray[10],
      },
      root: {
        height: "0.5rem",
      },
    },
    MuiLink: {
      root: {
        "&:visited": {
          color: COLORS.a.visited,
        },
        color: COLORS.a.link,
        textDecoration: "none",
      },
      underlineAlways: {
        textDecoration: "none",
      },
    },
    MuiListItem: {
      root: {
        "&.menuListItem": {
          color: `${COLORS.sand.light} !important`,
          fontSize: "1.5rem",
          textAlign: "center",
        },
        "&.primaryItem": {
          fontSize: "2.5rem",
          fontWeight: 700,
          textTransform: "uppercase",
        },
        "&.secondaryItem": {
          textTransform: "unset",
        },
        "&:first-of-type": {
          marginTop: "1rem",
        },
        alignItems: "baseline !important",
      },
    },
    MuiMobileStepper: {
      progress: {
        borderRadius: "0.5rem",
        width: "100%",
      },
      root: {
        padding: "0",
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: "0",
      },
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: COLORS.forest.medium,
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&.Mui-checked": {
          color: COLORS.forest.dark,
        },
        color: COLORS.forest.medium,
      },
      root: {},
    },
    MuiSwitch: {
      thumb: {
        marginTop: "-0.1875rem",
      },
    },
    MuiTouchRipple: {
      root: {
        display: "none !important",
        visibility: "hidden !important",
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: "700",
        lineHeight: 1.25,
        marginBottom: "0.5rem",
      },
      root: {
        "& sup": {
          fontSize: "68.75%",
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: "6px 9px !important",
        top: "6px !important",
      },
    },
  },
  palette: {
    background: {
      default: COLORS.gray.background,
    },
    error: {
      dark: COLORS.utility.danger.dark,
      light: COLORS.utility.danger.light,
      main: COLORS.utility.danger.medium,
    },
    info: {
      dark: COLORS.utility.info.dark,
      light: COLORS.utility.info.light,
      main: COLORS.utility.info.medium,
    },
    primary: {
      dark: COLORS.sand.dark,
      light: COLORS.sand.medium,
      main: COLORS.sand.medium,
    },
    secondary: {
      dark: COLORS.gray[15],
      light: COLORS.gray[10],
      main: COLORS.gray[10],
    },
    success: {
      dark: COLORS.utility.success.dark,
      light: COLORS.utility.success.light,
      main: COLORS.utility.success.medium,
    },
    warning: {
      dark: COLORS.utility.caution.dark,
      light: COLORS.utility.caution.light,
      main: COLORS.utility.caution.medium,
    },
    white: {
      dark: COLORS.white,
      light: COLORS.white,
      main: COLORS.white,
    },
  },
  typography: {
    fontFamily: ["Open Sans", "Helvetica", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "2.4375rem",
      fontWeight: 900,
      letterSpacing: "-0.0625rem",
      lineHeight: "1.1",
      marginBottom: "0.75rem !important",
      [breakpoints.down("sm")]: {
        fontSize: "1.9375rem",
      },
    },
    h2: {
      fontSize: "1.5625rem",
      fontWeight: 700,
      letterSpacing: "-0.0625rem",
      lineHeight: "1.25",
      [breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    p: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      margin: "0.75rem 0",
    },
  },
});

export const useStyles = makeStyles(() => ({
  adminAppBar: {
    backgroundColor: "transparent !important",
    bottom: "0 !important",
    justifyContent: "end",
    left: "0 !important",
    margin: "0 auto",
    maxWidth: "inherit",
    pointerEvents: "none",
    position: "fixed",
    right: "0 !important",
    top: "unset !important",
    width: "inherit",
  },
  adminAppBarTab: {
    "&.Mui-selected": {
      "&:after": {
        borderBottom: `0.5rem ${COLORS.white} solid`,
        borderLeft: "0.5rem transparent solid",
        borderRight: "0.5rem transparent solid",
        content: '""',
        height: "0",
        left: "calc(50% - 0.5rem)",
        position: "absolute",
        top: "calc(100% - 0.5rem)",
        width: "0",
      },
      background: COLORS.sand.dark,
      borderColor: `${COLORS.sand.dark} !important`,
      fontWeight: "700 !important",
      textShadow: `0 0 0.375rem rgba(255, 255, 255, 0.55), 0 1px 1px rgba(0, 0, 0, 0.35)`,
    },
    "&:first-of-type": {
      borderRight: "none !important",
    },
    background: COLORS.gray[50],
    border: `1px ${COLORS.gray[30]} solid`,
    borderColor: `${COLORS.gray[30]} !important`,
    color: COLORS.white,
    fontSize: "1rem !important",
    margin: "0",
    outlineColor: "transparent !important",
  },
  adminAppBarTabs: {
    backgroundColor: `${COLORS.gray[50]} !important`,
    color: `${COLORS.white} !important`,
    pointerEvents: "auto",
  },
  adminContainer: {
    alignContent: "flex-start",
    backgroundColor: COLORS.white,
    height: "calc(100% - 5.25rem)", // Subtract amount accounts for tab bar + padding.
    margin: "0 auto !important",
    maxWidth: "inherit",
    padding: "0 0 3rem !important",
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0 !important",
    },
  },
  adminSubmitButton: {
    fontSize: "1.5rem",
    padding: "1.25rem 3rem !important",
  },
  adminWrapper: {
    backgroundColor: COLORS.white,
    height: "100vh",
    margin: "0 auto",
    maxWidth: "40rem",
  },
  alignItemsBaseline: {
    alignItems: "baseline",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  alignItemsEnd: {
    alignItems: "end",
  },
  appBarContainer: {
    backgroundColor: `${COLORS.gray[50]} !important`,
    color: `${COLORS.white} !important`,
    padding: "1rem 1.75rem 0.75rem 1.75rem",
  },
  appBarMenuIcon: {
    color: "inherit !important",
    margin: "0 !important",
    padding: "1rem",
  },
  appContainer: {
    alignItems: "center",
    height: "100%",
    margin: "0 auto !important",
    padding: "4.5rem 1rem 3rem !important",
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "5.5rem !important",
    },
  },
  appDonorList: {
    backgroundColor: COLORS.white,
    borderRadius: "0.5rem",
    boxShadow: `inset 0 1px 4px rgba(0, 0, 0, 0.35)`,
    margin: "0.25rem",
    overflow: "scroll",
    padding: "0",
    width: "calc(100% - 0.5rem)",
  },
  appDonorListItem: {
    "&.Mui-selected": {
      "&:hover": {
        backgroundColor: `${COLORS.sand.light} !important`,
      },
      backgroundColor: `${COLORS.sand.light} !important`,
      boxShadow: `inset 0 0 4px rgba(0, 0, 0, 0.35)`,
      fontWeight: "bold",
    },
    "&:first-of-type": {
      marginTop: "0 !important",
    },
    "&:hover": {
      backgroundColor: "none !important",
    },
    cursor: "pointer",
    padding: "0.75rem 1.25rem 0.5rem",
  },
  appDonorListItemText: {
    "&.Mui-selected": {
      fontSize: "1.75rem",
      fontWeight: "bold",
    },
    "&::first-letter": {
      fontWeight: "bold",
    },
    fontSize: "1.625rem",
    lineHeight: "1.25",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      "&.Mui-selected": {
        fontSize: "1.25rem",
      },
      fontSize: "1rem",
    },
  },
  appFormButton: {
    fontSize: "1.25rem",
    padding: "1rem 2rem !important",
    width: "auto",
  },
  appMenu: {
    backgroundColor: COLORS.white,
    left: "0",
    position: "absolute",
    right: "0",
    textAlign: "right",
    top: "0",
    [theme.breakpoints.down("xs")]: {
      boxShadow: `0 1px 0.1875rem rgba(0, 0, 0, 0.2)`,
      position: "fixed",
    },
  },
  appMenuAdmin: {
    "& > .appMenuCloseIcon": {
      color: `${COLORS.white} !important`,
    },
    position: "absolute",
    right: "-1rem",
    top: "-0.125rem",
  },
  appMenuCloseIcon: {
    color: COLORS.black,
    height: "auto",
    width: "1.5rem",
  },
  appMenuIcon: {
    color: "inherit !important",
    height: "2.5rem !important",
    width: "2.5rem !important",
  },
  appMenuPrimaryItem: {
    fontSize: "1.5rem",
    fontWeight: 700,
  },
  appPanelBottom: {
    "@media (orientation: portrait)": {
      padding: "1rem 0 !important",
    },
    alignItems: "center",
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "center",
    padding: "1rem 4rem !important",
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
      paddingTop: "0 !important",
    },
  },
  appPanelCenter: {
    "@media (orientation: portrait)": {
      width: "100% !important",
    },
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    margin: "0 auto !important",
    padding: "0 1rem",
    textAlign: "center",
    width: "calc(35 / 65 * 100%)",
    [theme.breakpoints.down("xs")]: {
      height: "60vh",
      width: "100%",
    },
  },
  appPanelFilterLineup: {
    "& .MuiChip-colorPrimary": {
      backgroundColor: COLORS.forest.medium,
      boxShadow: `inset 0 1px 0.375rem rgba(0, 0, 0, 0.75)`,
      color: COLORS.sand.light,
    },
    "& .MuiChip-root": {
      "&.active": {
        fontWeight: "bold",
      },
      borderRadius: "3rem !important",
      boxShadow: `0 1px 0.1875rem rgba(0, 0, 0, 0.25)`,
      fontSize: "0.875rem !important",
      padding: "1.25rem 0.375rem 1.25rem !important",
    },
    justifyContent: "space-between",
    margin: "0 0.25rem 0.5rem 0.25rem !important",
    width: "calc(100% - 0.5rem)",
  },
  appPanelLeft: {
    "@media (orientation: portrait)": {
      width: "52.5%",
    },
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "0 auto !important",
    textAlign: "center",
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      height: "55vh",
      width: "100%",
    },
  },
  appPanelProjectName: {
    "@media (orientation: portrait)": {
      fontSize: "1.25rem",
    },
    fontSize: "1.75rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  appPanelProjectNameLabel: {
    alignItems: "center",
    display: "flex",
    flexGrow: "1",
    height: "auto",
    justifyContent: "center",
  },
  appPanelProjectNumber: {
    "@media (orientation: portrait)": {
      fontSize: "2rem",
    },
    fontSize: "2.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  appPanelProjectScroller: {
    alignItems: "center",
    flexGrow: "1",
    height: "auto",
    width: "100%",
  },
  appPanelProjectScrollerArrow: {
    height: "6.0rem !important",
    width: "6.0rem !important",
  },
  appPanelProjectScrollerArrowButton: {
    "&.left": {
      marginLeft: "-1.125rem !important",
      marginRight: "-0.625rem !important",
    },
    "&.right": {
      marginLeft: "-0.625rem !important",
      marginRight: "-1.125rem !important",
    },
    padding: "0 !important",
  },
  appPanelProjectSelectLabel: {
    "@media (orientation: portrait)": {},
    padding: "0 !important",
    textAlign: "center",
  },
  appPanelProjectSelectLabelText: {
    "@media (orientation: portrait)": {
      fontSize: "2.25rem",
    },
    color: `${COLORS.black} !important`,
    fontSize: "2.75rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  appPanelRight: {
    "@media (orientation: portrait)": {
      padding: "1rem 1rem 0 1rem",
      width: "100% !important",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto !important",
    textAlign: "center",
    width: "calc(30 / 65 * 100%)",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem 1rem 2rem 1rem !important",
      width: "100%",
    },
  },
  appPanelRightContainer: {
    "@media (orientation: portrait)": {
      flexDirection: "column-reverse !important",
    },
    display: "flex",
    flexDirection: "row !important",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "1rem !important",
      paddingTop: "1rem",
      width: "100%",
    },
  },
  appPanelRightOuterWrapper: {
    "@media (orientation: portrait)": {
      alignItems: "center",
      justifyContent: "center",
      width: "47.5%",
    },
    display: "flex",
    flexDirection: "column !important",
    width: "65%",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "3rem",
      paddingTop: "1rem",
      width: "100%",
    },
  },
  appPanels: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "unset",
    },
  },
  appPanelTop: {
    "@media (orientation: portrait)": {
      padding: "1rem 0 !important",
    },
    color: COLORS.sand.dark,
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    padding: "1rem 2rem !important",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0 !important",
      width: "100%",
    },
  },
  appProjectBalanceAmount: {
    "&.over": {
      color: COLORS.sand.dark,
    },
    color: COLORS.forest.medium,
    display: "inline",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  appProjectBalanceTitle: {
    display: "inline",
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
  appWrapper: {
    "@media (orientation: portrait)": {
      height: "1024px",
      maxHeight: "1024px",
      maxWidth: "768px",
      minHeight: "1024px",
      minWidth: "768px",
      width: "768px",
    },
    background: COLORS.white,
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.35)",
    height: "768px",
    margin: "0 auto",
    maxHeight: "768px",
    maxWidth: "1024px",
    minHeight: "768px",
    minWidth: "1024px",
    position: "relative",
    width: "1024px",
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
      height: "100%",
      maxHeight: "unset",
      maxWidth: "unset",
      minHeight: "unset",
      minWidth: "unset",
      width: "100%",
    },
  },
  appWrapperContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    justifyContent: "center",
    width: "100vw",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "unset !important",
      width: "unset !important",
    },
  },
  backdrop: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    zIndex: theme.zIndex.drawer + 1001,
  },
  backdropLinearProgress: {
    backgroundColor: COLORS.gray[40],
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(0.5),
    width: "200px",
  },
  backdropStatusMessage: {
    color: "white",
    margin: theme.spacing(2),
  },
  backgroundSandLight: {
    background: COLORS.sand.light,
  },
  backgroundTransparent: {
    background: "transparent !important",
  },
  borderedInput: {
    border: `1px solid ${COLORS.gray[20]}`,
    padding: "1rem",
  },
  calculator: {
    "&::after": {
      content: '""',
      display: "block",
      paddingBottom: "125%",
    },
    position: "relative",
    width: "100%",
  },
  calculatorButton: {
    "&.small": {
      fontSize: "1.25rem",
    },
    "&:active": {
      background: `linear-gradient(180deg, ${COLORS.gray[10]} 0%, ${COLORS.gray[5]} 100%) !important`,
      boxShadow: `inset 0 1px 5px rgba(0, 0, 0, 0.22)`,
    },
    "&:hover": {
      background: `linear-gradient(0deg, ${COLORS.gray[5]} 0%, ${COLORS.white} 100%)`,
    },
    background: `linear-gradient(0deg, ${COLORS.gray[5]} 0%, ${COLORS.white} 100%)`,
    borderRadius: "0.5rem",
    bottom: "0",
    boxShadow: `0 1px 2px rgba(0, 0, 0, 0.35)`,
    fontSize: "2rem",
    height: "calc(100% - 0.5rem)",
    left: "0",
    margin: "0.25rem",
    position: "absolute",
    right: "0",
    top: "0",
    transition: "none !important",
    width: "calc(100% - 0.5rem)",
  },
  calculatorButtonContainer: {
    "&::after": {
      content: '""',
      display: "block",
      paddingBottom: "100%",
    },
    position: "absolute",
    width: "25%",
  },
  calculatorButtonContainerDoubleTall: {
    "&::after": {
      content: '""',
      display: "block",
      paddingBottom: "200% !important",
    },
    position: "absolute",
    width: "25%",
  },
  calculatorButtonContainerDoubleWide: {
    "&::after": {
      content: '""',
      display: "block",
      paddingBottom: "50%",
    },
    position: "absolute",
    width: "50% !important",
  },
  calculatorDisplay: {
    backgroundColor: COLORS.white,
    borderRadius: "0.5rem",
    boxShadow: `inset 0 1px 4px rgba(0, 0, 0, 0.35)`,
    fontSize: "3rem",
    margin: "0.25rem",
    padding: "1rem 1rem 0.75rem",
    textAlign: "right",
    width: "calc(100% - 0.5rem)",
  },
  calculatorGoButton: {
    fontSize: "1.75rem",
    padding: "1rem !important",
  },
  clearfix: {
    clear: "both",
  },
  col1: {
    left: "0",
  },
  col2: {
    left: "25%",
  },
  col3: {
    left: "50%",
  },
  col4: {
    left: "75%",
  },
  colorBlack: {
    color: `${COLORS.black} !important`,
  },
  colorForest: {
    "&.dark": {
      color: COLORS.forest.dark,
    },
    "&.light": {
      color: COLORS.forest.light,
    },
    color: COLORS.forest.medium,
  },
  colorSand: {
    "&.dark": {
      color: COLORS.sand.dark,
    },
    "&.light": {
      color: COLORS.sand.light,
    },
    color: COLORS.sand.medium,
  },
  contentCenter: {
    margin: "0 auto",
    textAlign: "center",
  },
  cover: {
    backgroundColor: "transparent",
    bottom: "0",
    left: "0",
    position: "absolute",
    right: "0",
    top: "0",
  },
  defaultContainer: {
    margin: "0 auto !important",
    padding: "2rem 1rem 3rem !important",
    position: "relative",
    width: "100%",
  },
  donorCard: {
    "&.selected": {
      backgroundColor: COLORS.sand.light,
      boxShadow: `inset 0 0px 6px 1px rgba(0, 0, 0, 0.5)`,
    },
    "&:hover": {
      backgroundColor: COLORS.sand.light,
      boxShadow: `0 0px 6px 1px rgba(0, 0, 0, 0.5)`,
    },
    borderRadius: "0.5rem",
    cursor: "pointer",
    margin: "0.25rem 0.25rem",
  },
  donorCardContent: {
    padding: "1rem !important",
  },
  donorListContainer: {
    flexWrap: "wrap",
    gridAutoFlow: "row",
    marginLeft: "0.25rem",
    overflow: "scroll",
    padding: "1rem 0.25rem",
    width: "calc(100% - 0.5rem)",
  },
  donorListDialog: {
    backgroundColor: COLORS.forest.medium,
    color: COLORS.sand.medium,
  },
  errorContainer: {
    "&.outlined": {
      border: `1px solid rgba(112, 38, 41, 0.18)`,
      boxShadow: `0 1px 3px rgba(112, 38, 41, 0.12)`,
    },
    backgroundColor: COLORS.utility.danger.light,
    borderRadius: "0.5rem",
    color: COLORS.utility.danger.dark,
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    padding: "1rem 1rem 0.875rem 1rem !important",
  },
  flex1: {
    flex: "1",
  },
  flexAlignItemsCenter: {
    alignItems: "center",
  },
  flexDirectionColumn: {
    display: "flex",
    flexDirection: "column !important",
    flexWrap: "wrap",
  },
  flexDirectionRow: {
    display: "flex",
    flexDirection: "row !important",
  },
  flexDisplay: {
    display: "flex",
  },
  flexFullWidth: {
    maxWidth: "100%",
    minWidth: "75%",
  },
  flexGrow0: {
    flexGrow: "0 !important",
  },
  flexGrow1: {
    flexGrow: "1 !important",
  },
  flexJustifyContentCenter: {
    justifyContent: "center !important",
  },
  flexJustifyContentEnd: {
    justifyContent: "flex-end !important",
  },
  flexJustifyContentSpaceBetween: {
    justifyContent: "space-between !important",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  formButton: {
    "& > .MuiButton-label": {
      "& > .MuiSvgIcon-root": {
        margin: "0.5rem !important",
      },
    },
    margin: "0 auto",
    padding: "0.625rem 1.5rem 0.5625rem !important",
    textTransform: "inherit",
    width: "auto",
  },
  formButtonContainer: {
    "& > .MuiButton-root": {
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
    "&.left": {
      alignItems: "left",
      justifyContent: "left",
      textAlign: "left",
    },
    "&.right": {
      alignItems: "left",
      justifyContent: "left",
      textAlign: "left",
    },
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "1rem auto",
    textAlign: "center",
    width: "100%",
  },
  formButtonContainerColumnMobile: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  formContainer: {
    margin: "0 auto",
    maxWidth: "100%",
    minWidth: "100%",
    padding: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      minWidth: "unset",
      width: "100%",
    },
  },
  formControl: {
    "& > textarea": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      color: `${COLORS.gray[50]}`,
      fontFamily: "inherit",
      fontSize: "1rem",
      lineHeight: 1.1875,
      outlineColor: COLORS.forest.medium,
      padding: theme.spacing(1.5),
    },
    margin: "0.5rem 0 !important",
    width: "100%",
  },
  formControlContainer: {
    margin: "0 auto 0 !important",
    width: "100%",
  },
  formGridContainer: {
    width: "100%",
  },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    maxWidth: "100%",
    width: "100%",
  },
  globalShadow: {
    boxShadow: "0 0 0.25rem rgba(0, 0, 0, 0.25)",
  },
  gridVerticalAlignCenter: {
    alignItems: "center",
  },
  hide: {
    display: "none !important",
  },
  infoContainer: {
    "&.outlined": {
      border: `1px solid rgba(41, 83, 122, 0.18)`,
      boxShadow: `0 1px 3px rgba(41, 83, 122, 0.12)`,
    },
    backgroundColor: COLORS.utility.info.light,
    borderRadius: "0.5rem",
    color: COLORS.utility.info.dark,
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    padding: "1rem 1rem 0.875rem 1rem !important",
  },
  infoIcon: {
    color: COLORS.gray[25],
    height: "1.1875rem",
    width: "1.1875rem",
  },
  infoIconButton: {
    background: "transparent !important",
  },
  listDisplayDialogButton: {
    margin: "1rem 0 !important",
    padding: "1rem !important",
    textAlign: "center",
  },
  m0: {
    margin: "0 !important",
  },
  m1: {
    margin: "1rem !important",
  },
  m2: {
    margin: "2rem !important",
  },
  m3: {
    margin: "3rem !important",
  },
  m4: {
    margin: "4rem !important",
  },
  m5: {
    margin: "5rem !important",
  },
  m6: {
    margin: "6rem !important",
  },
  mb0: {
    marginBottom: "0 !important",
  },
  mb1: {
    marginBottom: "1rem !important",
  },
  mb2: {
    marginBottom: "2rem !important",
  },
  mb3: {
    marginBottom: "3rem !important",
  },
  mb4: {
    marginBottom: "4rem !important",
  },
  mb5: {
    marginBottom: "5rem !important",
  },
  mb6: {
    marginBottom: "6rem !important",
  },
  menuButton: {
    margin: theme.spacing(1),
  },
  ml0: {
    marginLeft: "0 !important",
  },
  ml1: {
    marginLeft: "1rem !important",
  },
  ml2: {
    marginLeft: "2rem !important",
  },
  ml3: {
    marginLeft: "3rem !important",
  },
  ml4: {
    marginLeft: "4rem !important",
  },
  ml5: {
    marginLeft: "5rem !important",
  },
  ml6: {
    marginLeft: "6rem !important",
  },
  mr0: {
    marginRight: "0 !important",
  },
  mr05: {
    marginRight: "0.5rem !important",
  },
  mr1: {
    marginRight: "1rem !important",
  },
  mr2: {
    marginRight: "2rem !important",
  },
  mr3: {
    marginRight: "3rem !important",
  },
  mr4: {
    marginRight: "4rem !important",
  },
  mr5: {
    marginRight: "5rem !important",
  },
  mr6: {
    marginRight: "6rem !important",
  },
  "mt-075": {
    marginTop: "-0.75rem !important",
  },
  mt0: {
    marginTop: "0 !important",
  },
  mt05: {
    marginTop: "0.5rem !important",
  },
  mt075: {
    marginTop: "0.75rem !important",
  },
  mt1: {
    marginTop: "1rem !important",
  },
  mt2: {
    marginTop: "2rem !important",
  },
  mt3: {
    marginTop: "3rem !important",
  },
  mt4: {
    marginTop: "4rem !important",
  },
  mt5: {
    marginTop: "5rem !important",
  },
  mt6: {
    marginTop: "6rem !important",
  },
  noWrap: {
    flexWrap: "nowrap !important",
  },
  p0: {
    padding: "0 !important",
  },
  p05: {
    padding: "0.5rem !important",
  },
  p1: {
    padding: "1rem !important",
  },
  p2: {
    padding: "2rem !important",
  },
  p3: {
    padding: "3rem !important",
  },
  p4: {
    padding: "4rem !important",
  },
  p5: {
    padding: "5rem !important",
  },
  p6: {
    padding: "6rem !important",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    paddingBottom: "0.25rem !important",
  },
  pb0: {
    paddingBottom: "0 !important",
  },
  pb1: {
    paddingBottom: "1rem !important",
  },
  pb2: {
    paddingBottom: "2rem !important",
  },
  pb3: {
    paddingBottom: "3rem !important",
  },
  pb4: {
    paddingBottom: "4rem !important",
  },
  pb5: {
    paddingBottom: "5rem !important",
  },
  pb6: {
    paddingBottom: "6rem !important",
  },
  pl0: {
    paddingLeft: "0 !important",
  },
  pl05: {
    paddingLeft: "0.5rem !important",
  },
  pl1: {
    paddingLeft: "1rem !important",
  },
  pl2: {
    paddingLeft: "2rem !important",
  },
  pl3: {
    paddingLeft: "3rem !important",
  },
  pl4: {
    paddingLeft: "4rem !important",
  },
  pl5: {
    paddingLeft: "5rem !important",
  },
  pl6: {
    paddingLeft: "6rem !important",
  },
  pl15: {
    paddingLeft: "1.5rem !important",
  },
  pointer: {
    cursor: "pointer !important",
  },
  positionAbsoluteTopRight1: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
  },
  positionRelative: {
    position: "relative",
  },
  pr0: {
    paddingRight: "0 !important",
  },
  pr05: {
    paddingRight: "0.5rem !important",
  },
  pr075: {
    paddingRight: "0.75 !important",
  },
  pr1: {
    paddingRight: "1rem !important",
  },
  pr15: {
    paddingRight: "1.5rem !important",
  },
  projectCard: {
    "&.selected": {
      backgroundColor: COLORS.sand.light,
      boxShadow: `inset 0 0px 6px 1px rgba(0, 0, 0, 0.5)`,
    },
    borderRadius: "0.5rem",
    margin: "0.25rem 0.25rem",
  },
  projectCardAmount: {
    fontSize: "0.625rem",
    lineHeight: "1rem",
  },
  projectCardContent: {
    padding: "1rem !important",
  },
  projectCardLocation: {
    fontSize: "0.625rem",
    lineHeight: "1rem",
  },
  projectListContainer: {
    flexWrap: "wrap",
    gridAutoFlow: "row",
    marginLeft: "0.25rem",
    overflow: "scroll",
    padding: "1rem 0.25rem",
    width: "calc(100% - 0.5rem)",
  },
  projectListDialog: {
    backgroundColor: COLORS.forest.medium,
    color: COLORS.sand.medium,
  },
  pt0: {
    paddingTop: "0 !important",
  },
  pt05: {
    paddingTop: "0.5rem !important",
  },
  pt075: {
    paddingTop: "0.75 !important",
  },
  pt1: {
    paddingTop: "1rem !important",
  },
  pt2: {
    paddingTop: "2rem !important",
  },
  pt3: {
    paddingTop: "3rem !important",
  },
  pt4: {
    paddingTop: "4rem !important",
  },
  pt5: {
    paddingTop: "5rem !important",
  },
  pt6: {
    paddingTop: "6rem !important",
  },
  reportTreeList: {
    "& .MuiTreeItem-group": {
      marginLeft: "0 !important",
    },
    "& .MuiTreeItem-iconContainer": {
      "& .MuiSvgIcon-root": {
        height: "2.5rem",
        width: "2rem",
      },
      width: "2.5rem",
    },
    "& .MuiTreeItem-label": {
      fontSize: "1rem",
      padding: "0.25rem !important",
    },
    "& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
      {
        background: "none !important",
      },
    textAlign: "left",
  },
  reportTreeListDonorText: {
    fontSize: "1rem",
  },
  reportTreeListItem: {
    borderBottom: `1px solid ${COLORS.gray[15]}`,
    borderCollapse: "collapse",
  },
  reportTreeListItemIcon: {
    "&.green": {
      color: `${COLORS.utility.success.medium} !important`,
    },
    "&.red": {
      color: `${COLORS.utility.danger.medium} !important`,
    },
    color: COLORS.gray[25],
    height: "1.5rem !important",
    width: "auto",
  },
  reportTreeListItemIconContainer: {},
  reportTreeListItemLevel2: {
    "& .MuiTreeItem-iconContainer": {
      width: "0rem !important",
    },
    borderCollapse: "collapse",
    borderTop: `1px dotted ${COLORS.gray[15]}`,
    marginLeft: "3.5rem !important",
    paddingLeft: "0 !important",
    paddingTop: "1px",
  },
  reportTreeListProjectText: {
    fontSize: "1rem",
  },
  reportTreeListSubArrowIcon: {
    color: COLORS.gray[25],
    height: "1.125rem !important",
    marginRight: "0.25rem !important",
    position: "relative",
    top: "0.0625rem",
    width: "1.125rem !important",
  },
  root: {
    flexGrow: 1,
  },
  row1: {
    top: "0",
  },
  row2: {
    top: "20%",
  },
  row3: {
    top: "40%",
  },
  row4: {
    top: "60%",
  },
  row5: {
    top: "80%",
  },
  successContainer: {
    backgroundColor: COLORS.utility.success.light,
    borderRadius: "0.5rem",
    color: COLORS.utility.success.dark,
    padding: "1rem 1rem 0.875rem 1rem !important",
  },
  textBold: {
    fontWeight: "bold",
  },
  textCenter: {
    textAlign: "center",
  },
  textFirstLetterBigAndBold: {
    "&::first-letter": {
      fontSize: "larger",
      fontWeight: "900",
    },
  },
  textLeft: {
    textAlign: "left",
  },
  textRed: {
    color: COLORS.utility.danger.medium,
  },
  textRight: {
    textAlign: "right",
  },
  textTruncate: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  w0: {
    width: "0 !important",
  },
  websiteDonorTicker: {
    backgroundColor: "rgba(233, 233, 237, 0.85)",
    display: "flex",
    fontFamily: WEBSITE_FONT_FAMILY,
    height: "calc(179 / 1152 * 100%)",
    width: "100%",
  },
  websiteDonorTickerCard: {
    "&.blank": {
      borderColor: "transparent !important",
    },
    alignItems: "center",
    backgroundColor: "transparent !important",
    borderLeft: "1px solid rgba(255, 255, 255, 0.57)",
    borderRadius: "0 !important",
    borderRight: "1px solid rgba(0, 0, 0, 0.17)",
    boxShadow: "none !important",
    display: "flex",
    fontFamily: WEBSITE_FONT_FAMILY,
    height: "100%",
    justifyContent: "left",
    width: "100%",
  },
  websiteDonorTickerCardContent: {
    // alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: "0.5em 1em 0.5em 1.25em !important",
    width: "100%",
  },
  websiteDonorTickerCardDonorName: {
    color: COLORS.violet.medium,
    // display: "inline-flex",
    fontFamily: WEBSITE_FONT_FAMILY,
    fontSize: "1.25em",
    fontWeight: "600",
    lineHeight: "1.25",
    marginBottom: "0.125em",
    marginRight: "0.25em",
    width: "auto",
  },
  websiteDonorTickerCardPledgeAmount: {
    color: COLORS.gray.websiteDonorPledgeAmount,
    // display: "inline-flex",
    fontFamily: WEBSITE_FONT_FAMILY,
    fontSize: "1.75em",
    fontWeight: "400",
    lineHeight: "1",
    // width: "auto",
  },
  websiteDonorTickerList: {
    display: "flex",
    // Reverse for more accurate flow with ordering of newest/oldest pledges in
    // the donor ticker list to prevent auto-show for newly-added items.
    flexDirection: "row-reverse",
    height: "100%",
    margin: "0 !important",
    overflow: "hidden",
    padding: "0 !important",
    width: "100% !important",
  },
  websiteDonorTickerListItem: {
    "&.selected": {
      backgroundColor: "rgba(255, 255, 0, 0.4) !important",
    },
    display: "flex",
    height: "100%",
    justifyContent: "center",
    listStyleType: "none !important",
    maxWidth: "20% !important",
    minWidth: "20% !important",
    width: "20% !important",
  },
  websiteGoal: {
    "&:before": {
      borderBottom: `1.5em transparent solid`,
      borderLeft: `1.5em ${COLORS.violet.medium} solid`,
      borderRight: `1.5em transparent solid`,
      content: '""',
      height: "0",
      left: "-0.875em",
      position: "absolute",
      top: "calc(50% - 1.25em)",
      transform: "rotate(-45deg)",
      width: "0",
    },
    alignItems: "center",
    backgroundColor: COLORS.violet.medium,
    color: COLORS.white,
    display: "flex",
    flexShrink: 0,
    fontFamily: WEBSITE_FONT_FAMILY,
    height: "calc(179 / 1152 * 100%) !important",
    justifyContent: "center",
    padding: "0.5em 1em",
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  websiteGoalContent: {
    display: "block",
    fontFamily: WEBSITE_FONT_FAMILY,
    fontSize: "2.125em",
    fontWeight: "bold",
  },
  websiteInstructions: {
    alignItems: "center",
    backgroundColor: COLORS.black,
    color: COLORS.white,
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    justifyContent: "space-between",
    padding: "0 1.5em 1em 1.5em",
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  websiteInstructionsText: {
    fontSize: "1.1875em",
    fontWeight: "600",
    marginBottom: "0.25em",
  },
  websiteInstructionsTextPhoneNumber: {
    fontSize: "2em",
    fontWeight: "600",
    marginBottom: "0.5em",
  },
  websiteLeftPanel: {
    backgroundColor: "transparent",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    fontFamily: WEBSITE_FONT_FAMILY,
    height: "100%",
    maxHeight: "1152px",
    maxWidth: "2704px",
    width: "calc(2704 / 3456 * 100%)",
  },
  websiteLinearProgress: {
    "& .MuiLinearProgress-bar1Determinate": {
      backgroundColor: COLORS.honoluluBlue.medium,
      height: "100% !important",
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "transparent",
      height: "100% !important",
    },
    bottom: "0",
    height: "100% !important",
    left: "0",
    position: "absolute",
    right: "0",
    top: "0",
  },
  websiteMilestoneImage: {
    backgroundColor: "transparent",
    flexGrow: "1",
    fontFamily: WEBSITE_FONT_FAMILY,
    justifyContent: "center",
    width: "100%",
  },
  websiteMobilePhoneImage: {
    height: "auto",
    margin: "0 auto",
    width: "calc(460 / 460 * 100%)",
  },
  websiteProgressBar: {
    "& .MuiLinearProgress-bar1Determinate": {
      backgroundColor: COLORS.honoluluBlue.medium,
      height: "100% !important",
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "transparent",
      height: "100% !important",
    },
    backgroundColor: "rgba(65, 63, 81, 0.8)",
    fontFamily: WEBSITE_FONT_FAMILY,
    height: "calc(179 / 1152 * 100%) !important",
    position: "relative",
    width: "100%",
  },
  websiteProgressBarLabel: {
    color: COLORS.white,
    fontFamily: WEBSITE_FONT_FAMILY,
    fontSize: "2.25em",
  },
  websiteProgressBarLabelContainer: {
    margin: "0.5875em 0 0 0.75em",
    position: "relative",
  },
  websiteProgressMilestoneContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    position: "absolute",
  },
  websiteProgressMilestoneLabel: {
    bottom: "0",
    color: COLORS.white,
    fontSize: "0.6875em",
    fontWeight: "600",
    padding: "0 0.75em 0.5em 0 !important",
    position: "absolute",
    right: "0",
    textAlign: "right",
  },
  websiteProgressMilestoneLabelContainer: {
    position: "relative",
    textAlign: "right",
    width: "100%",
  },
  websiteProgressMilestoneLineBorder: {
    "&:after": {
      borderBottom: `14px ${COLORS.white} solid`,
      borderLeft: "10px transparent solid",
      borderRight: "10px transparent solid",
      bottom: "0",
      content: '""',
      height: "0",
      position: "absolute",
      right: "calc(0 - 10px)",
      width: "0",
    },
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  websiteQrCodeImage: {
    height: "auto",
    margin: "0 auto",
    width: "calc(350 / 460 * 100%)",
  },
  websiteRightPanel: {
    alignItems: "center",
    backgroundColor: COLORS.black,
    display: "flex",
    flexDirection: "column",
    fontFamily: WEBSITE_FONT_FAMILY,
    height: "100%",
    justifyContent: "center",
    maxHeight: "1152px",
    maxWidth: "752px",
    width: "calc(752 / 3456 * 100%)",
  },
  websiteWrapper: {
    aspectRatio: "3 / 1",
    backgroundColor: "gray",
    fontFamily: WEBSITE_FONT_FAMILY,
    fontSize: "1vw !important",
    fontWeight: "400",
    lineHeight: "1",
    margin: "0 auto",
    maxHeight: "1152px",
    maxWidth: "3456px",
    padding: "0 !important",
    width: "100%",
  },
  whiteContainer: {
    "&.outlined": {
      border: `1px solid rgba(0, 0, 0, 0.18)`,
      boxShadow: `0 1px 3px rgba(0, 0, 0, 0.12)`,
    },
    backgroundColor: COLORS.white,
    borderRadius: "0.5rem",
    color: COLORS.utility.info.dark,
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    padding: "1rem 1rem 0.875rem 1rem !important",
  },
}));
