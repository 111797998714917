/**
 * @module MessengerInternationalApiMcupAuctionAppProjects
 */
import { apiClient } from 'api/core';
import {
  MI_API_BASE_URL,
  MI_API_REQUEST_OPTIONS,
} from 'api/messengerinternational/constants';

/**
 * Function to retrieve a list of projects.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getProjects() {
  return apiClient.get({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/projects`,
  });
}

/**
 * Function to create project with the specified data.
 *
 * @deprecated Removed from the project requirements.
 *
 * @param {object} params - The function parameters.
 * @param {number} params.cost - The project cost.
 * @param {string} params.country - The project country.
 * @param {string} params.language - The project language.
 * @param {string} params.name - The project name.
 * @param {number} params.projectId - The project id.
 * @param {number} params.quantity - The quantity.
 * @param {string} params.region - The project region.
 * @param {string} params.type - The project type.
 *
 * @throws {Error} - Throws an error if `cost` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `country` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `language` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `name` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `projectId` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `quantity` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `region` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `type` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function createProject({
  cost,
  country,
  language,
  name,
  projectId,
  quantity,
  region,
  type,
}) {
  if (!cost || typeof cost !== 'number') {
    throw new Error('Sorry, there was a problem validating the cost provided.');
  }
  if (!country || typeof country !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the country provided.',
    );
  }
  if (!language || typeof language !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the language provided.',
    );
  }
  if (!name || typeof name !== 'string') {
    throw new Error('Sorry, there was a problem validating the name provided.');
  }
  if (!projectId || typeof projectId !== 'number') {
    throw new Error(
      'Sorry, there was a problem validating the project id provided.',
    );
  }
  if (!quantity || typeof quantity !== 'number') {
    throw new Error(
      'Sorry, there was a problem validating the quantity provided.',
    );
  }
  if (!region || typeof region !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the region provided.',
    );
  }
  if (!type || typeof type !== 'string') {
    throw new Error('Sorry, there was a problem validating the type provided.');
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      cost,
      country,
      language,
      name,
      project_id: projectId,
      quantity,
      region,
      type,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/projects`,
  });
}

export * as donor from './donor';
export * as project from './project';
