/**
 * @module PageNotFoundView
 */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { useLocale } from 'context/locale';
import { useStyles } from 'utils/styles';

/**
 * Represents the Page Not Found (404) view shown when the requested route/page is not found.
 *
 * @returns {React.ReactElement} - The PageNotFoundView component.
 */
export function PageNotFoundView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { strings } = useLocale();

  /**
   * Handler function for Home button click.
   */
  function handleHomeClick() {
    navigate('/', { replace: true });
  }

  /**
   * Single-run convenience effect to ensure the page loads at the top.
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {strings ? (
        <Grid
          className={[
            classes.defaultContainer,
            classes.fullWidth,
            classes.textCenter,
          ].join(' ')}
          container={true}
        >
          <Box className={classes.fullWidth} mb={2}>
            <div
              dangerouslySetInnerHTML={{
                __html: strings.pages.not_found.title,
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: strings.pages.not_found.content_html,
              }}
            ></div>
            <Typography className={[classes.mb2, classes.mt2].join(' ')}>
              <Button
                color="primary"
                onClick={handleHomeClick}
                startIcon={<HomeIcon />}
                title={strings.link_titles.return_to_home}
                variant="contained"
              >
                {strings.labels.home}
              </Button>
            </Typography>
          </Box>
        </Grid>
      ) : null}
    </>
  );
}
