/**
 * @module NewDonorFormDialog
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import validator from 'validator';
import { LoadingButton } from 'components/ui/buttons';
import { useLocale } from 'context/locale';
import { STATUS_TYPES } from 'utils/constants';
import { useStyles } from 'utils/styles';

/**
 * Represents the New Donor dialog form component and controls.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.apiStatus] - The API status, to help determine the state of the sign-in and loading button.
 * @param {Error} [props.error] - The Error object associated with the form.
 * @param {Function} [props.onCancelClick] - The function to fire on Cancel button click.
 * @param {Function} [props.onClose] - The function to fire on dialog close.
 * @param {Function} [props.onSubmit] - The function to call for Submit form click.
 * @param {boolean} [props.open] - Boolean flag for whether or not dialog is open.
 *
 * @returns {React.ReactElement} - The NewDonorFormDialog component.
 */
export function NewDonorFormDialog({
  apiStatus,
  error,
  onCancelClick,
  onClose,
  onSubmit,
  open,
}) {
  const classes = useStyles();
  const { strings } = useLocale();
  const defaultNewDonorFormData = {
    first_name: {
      hasBeenTouched: false,
      isError: null,
      value: '',
    },
    last_name: {
      hasBeenTouched: false,
      isError: null,
      value: '',
    },
  };
  const [formData, setFormData] = React.useState(defaultNewDonorFormData);
  const [isEnabled, setIsEnabled] = React.useState(
    !formData?.first_name?.isError || !formData?.last_name?.isError,
  );

  /**
   * Handler function for form input elements blur events.
   *
   * @param {Event} event - The Event object associated with the blur event.
   */
  function handleFormInputBlur(event) {
    const { id: inputId, value: inputValue } = event.target;
    const inputHasBeenTouched =
      formData[inputId].hasBeenTouched ||
      (!formData[inputId].hasBeenTouched && formData[inputId].value !== '');
    let isError = false;
    if (inputId === 'first_name' || inputId === 'last_name') {
      isError = validator.isEmpty(inputValue);
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [inputId]: {
          hasBeenTouched: inputHasBeenTouched,
          isError,
          value: formData[inputId]?.value || '',
        },
      };
    });
  }

  /**
   * Handler function for form input elements change events.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleFormInputChange(event) {
    const { id: inputId, value: inputValue } = event.target;
    let isError = false;
    if (inputId === 'first_name' || inputId === 'last_name') {
      isError = validator.isEmpty(inputValue);
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [inputId]: {
          hasBeenTouched: false, // Set explicitly to avoid error state while user typing.
          isError: isError && formData[inputId]?.hasBeenTouched,
          value: inputValue,
        },
      };
    });
  }

  /**
   * Handler function for Submit form click.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleSubmit(event) {
    event.preventDefault();
    onSubmit({
      first_name: formData.first_name.value,
      last_name: formData.last_name.value,
    });
    setFormData(defaultNewDonorFormData);
  }

  /**
   * Convenience effect to auto-set `isEnabled` when `formData` changes.
   */
  React.useEffect(() => {
    const firstOrLastHasValue =
      formData?.first_name?.value !== '' || formData?.last_name?.value !== '';
    setIsEnabled(firstOrLastHasValue);
  }, [formData]);

  return (
    <>
      {strings ? (
        <>
          <Dialog
            aria-labelledby="confirmation-dialog-title"
            id="dialog_confirm"
            maxWidth="sm"
            onClose={onClose}
            open={open}
            scroll="paper"
          >
            <DialogTitle id="new-donor-form-dialog-title">
              {strings.dialogs.titles.new_donor}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent
                className={[
                  classes.formContainer,
                  classes.pb0,
                  classes.pl15,
                  classes.pr15,
                  classes.pt0,
                ].join(' ')}
                dividers={false}
              >
                {/* Error Container */}
                {error ? (
                  <Box className={classes.errorContainer} mb={2}>
                    <Box className={classes.mr05}>
                      <ErrorIcon />
                    </Box>
                    <Box>
                      <Typography>{error.message}</Typography>
                    </Box>
                  </Box>
                ) : null}

                {/* Last Name Input */}
                <FormControl className={classes.formControl}>
                  <TextField
                    error={
                      (formData?.last_name?.isError &&
                        formData?.last_name?.hasBeenTouched) ||
                      false
                    }
                    helperText={
                      formData?.last_name?.isError &&
                      formData?.last_name?.hasBeenTouched
                        ? strings.forms.helper_text.last_name
                        : ''
                    }
                    id="last_name"
                    label={strings.labels.last_name}
                    onBlur={handleFormInputBlur}
                    onChange={handleFormInputChange}
                    placeholder={strings.placeholders.last_name}
                    type="text"
                    value={formData?.last_name?.value || ''}
                    variant="outlined"
                  />
                </FormControl>

                {/* First Name Input */}
                <FormControl className={classes.formControl}>
                  <TextField
                    error={
                      (formData?.first_name?.isError &&
                        formData?.first_name?.hasBeenTouched) ||
                      false
                    }
                    helperText={
                      formData?.first_name?.isError &&
                      formData?.first_name?.hasBeenTouched
                        ? strings.forms.helper_text.first_name
                        : ''
                    }
                    id="first_name"
                    label={strings.labels.first_name}
                    onBlur={handleFormInputBlur}
                    onChange={handleFormInputChange}
                    placeholder={strings.placeholders.first_name}
                    type="text"
                    value={formData?.first_name?.value || ''}
                    variant="outlined"
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  id="btn_confirmation_dialog_cancel"
                  onClick={onCancelClick}
                  variant="outlined"
                >
                  {strings.labels.cancel}
                </Button>
                <LoadingButton
                  className={classes.formButton}
                  color="secondary"
                  disabled={!isEnabled || apiStatus === STATUS_TYPES.PENDING}
                  id="btn_submit"
                  isLoading={apiStatus === STATUS_TYPES.PENDING}
                  type="submit"
                  variant="contained"
                >
                  {strings.labels.add}
                </LoadingButton>
              </DialogActions>
            </form>
          </Dialog>
        </>
      ) : null}
    </>
  );
}

NewDonorFormDialog.propTypes = {
  apiStatus: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
    name: PropTypes.string,
  }),
  onCancelClick: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  referrer: PropTypes.string,
};

NewDonorFormDialog.defaultProps = {
  apiStatus: STATUS_TYPES.IDLE,
  error: null,
  onCancelClick: () => {},
  onClose: () => {},
  onSubmit: () => {},
  open: false,
  referrer: '',
};
