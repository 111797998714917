/**
 * @module FaithStoryAppApiConstants
 */
const requestHeaders = {
  Accept: '*/*',
  'Content-Type': 'application/json',
  'Mi-Authorization': process.env.REACT_APP_MI_API_AUTH_TOKEN,
  'Mi-Environment': process.env.REACT_APP_API_ENV,
  'Mi-Referer': process.env.REACT_APP_MI_API_REFERER,
};

export const MI_API_BASE_URL = 'https://api.messengerinternational.org/v1';
export const MI_API_REQUEST_HEADERS = requestHeaders;
export const MI_API_REQUEST_HEADER_TOKEN = 'Mi-Token';
export const MI_API_REQUEST_OPTIONS = {
  headers: {
    ...requestHeaders,
  },
};
