/**
 * @module MessengerInternationalApiMcupAuctionAppDonors
 */
import { apiClient } from 'api/core';
import {
  MI_API_BASE_URL,
  MI_API_REQUEST_OPTIONS,
} from 'api/messengerinternational/constants';

/**
 * Function to retrieve a list of donors.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getDonors() {
  return apiClient.get({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/donors`,
  });
}

/**
 * Function to create donor with the specified first and last name.
 *
 * @param {object} params - The function parameters.
 * @param {string} params.firstName - The user first name.
 * @param {string} params.lastName - The user last name.
 *
 * @throws {Error} - Throws an error if `firstName` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `lastName` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function createDonor({ firstName, lastName }) {
  if (!firstName || typeof firstName !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the first name provided.',
    );
  }
  if (!lastName || typeof lastName !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the last name provided.',
    );
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      first_name: firstName,
      last_name: lastName,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/donors`,
  });
}

export * as donor from './donor';
