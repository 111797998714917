/**
 * @module MessengerInternationalApiMcupAuctionAppPledges
 */
import { apiClient } from 'api/core';
import {
  MI_API_BASE_URL,
  MI_API_REQUEST_OPTIONS,
} from 'api/messengerinternational/constants';

/**
 * Function to create pledge with the specified data.
 *
 * @param {object} params - The function parameters.
 * @param {string} params.deviceName - The device name.
 * @param {string} params.donorDisplayName - The donor display name.
 * @param {number|string} params.donorID - The donor id.
 * @param {number} params.pledgeAmount - The pledge amount.
 * @param {number} params.pledgeID - The pledge id.
 * @param {number} params.projectNumber - The project number.
 *
 * @throws {Error} - Throws an error if `deviceName` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `donorDisplayName` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `donorId` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `pledgeAmount` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `projectNumber` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function createPledge({
  deviceName,
  donorDisplayName,
  donorID,
  pledgeAmount,
  pledgeID,
  projectNumber,
}) {
  if (!pledgeAmount || !parseInt(pledgeAmount, 10)) {
    throw new Error(
      'Sorry, there was a problem validating the pledge amount provided.',
    );
  }
  if (!deviceName || typeof deviceName !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the device name provided.',
    );
  }
  if (!donorDisplayName || typeof donorDisplayName !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the donor display name provided.',
    );
  }
  if (
    !donorID ||
    (typeof donorID !== 'string' && typeof donorID !== 'number')
  ) {
    throw new Error(
      'Sorry, there was a problem validating the donor id provided.',
    );
  }
  if (!projectNumber || typeof projectNumber !== 'number') {
    throw new Error(
      'Sorry, there was a problem validating the project number provided.',
    );
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      amount: parseInt(pledgeAmount, 10),
      device_name: deviceName,
      donor_display_name: donorDisplayName,
      donor_id: donorID,
      local_pledge_id: pledgeID,
      project_number: projectNumber,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/pledges/create`,
  });
}

/**
 * Function to create an SMS pledge with the specified data.
 *
 * @param {object} params - The function parameters.
 * @param {string} params.deviceName - The device name.
 * @param {string} params.keyword - The keyword for the pledge.
 * @param {string} params.originalMessage - The original message from the request.
 * @param {number|string} params.phoneNumber - The phone number making the request.
 * @param {number} params.pledgeAmount - The pledge amount.
 *
 * @throws {Error} - Throws an error if `deviceName` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `keyword` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `originalMessage` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `phoneNumber` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `pledgeAmount` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function createSmsPledge({
  deviceName,
  keyword,
  originalMessage,
  phoneNumber,
  pledgeAmount,
}) {
  if (!pledgeAmount || typeof pledgeAmount !== 'number') {
    throw new Error(
      'Sorry, there was a problem validating the pledge amount provided.',
    );
  }
  if (!deviceName || typeof deviceName !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the device name provided.',
    );
  }
  if (!keyword || typeof keyword !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the keyword provided.',
    );
  }
  if (!originalMessage || typeof originalMessage !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the original message provided.',
    );
  }
  if (
    !phoneNumber ||
    (typeof phoneNumber !== 'number' && typeof phoneNumber !== 'string')
  ) {
    throw new Error(
      'Sorry, there was a problem validating the phone number provided.',
    );
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      amount: parseInt(pledgeAmount, 10),
      device_name: deviceName,
      keyword,
      original_message: originalMessage,
      phone_number: phoneNumber,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/pledges/create/sms`,
  });
}

export * as pledge from './pledge';
export * as project from './project';
