/**
 * @module ResetView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { ConfirmationDialog } from 'components/ui/dialogs';
import { useLocale } from 'context/locale';
import { clearMiLocalStorageItems } from 'utils/helpers';
import { useStyles } from 'utils/styles';

/**
 * Represents the Reset view shown to in-the-know users.
 *
 * @returns {React.ReactElement} - The ResetView component.
 */
export function ResetView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { strings } = useLocale();

  const [dialogData, setDialogData] = React.useState({
    cancelLabel: strings?.labels?.cancel || 'Cancel',
    confirmLabel: strings?.labels?.delete || 'Delete',
    message:
      strings?.messages?.user_data?.delete_data_message ||
      'Are you sure you want to delete this data?',
    messageSuccess:
      strings?.messages?.user_data?.delete_data_message ||
      'Data successfully deleted.',
    open: false,
    tertiaryLabel: null,
    type: null,
  });

  /**
   * Handler function for button click.
   */
  async function handleButtonClick() {
    switch (dialogData.type) {
      case 'clear_local_storage':
        clearMiLocalStorageItems(true);
        break;
      case 'delete_user':
        clearMiLocalStorageItems();
        break;
      case 'delete_user_data':
        clearMiLocalStorageItems(true);
        break;
      default:
        break;
    }
    setDialogData((prevData) => {
      return {
        ...prevData,
        open: false,
      };
    });
    navigate('/');
  }

  /**
   * Single-run convenience effect to ensure the page loads at the top.
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {strings ? (
        <Grid
          className={[
            classes.adminWrapper,
            classes.defaultContainer,
            classes.textCenter,
          ].join(' ')}
          container={true}
        >
          <Box className={classes.fullWidth} mb={2}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: strings.pages.reset.title,
              }}
              variant="h1"
            />
            <Typography
              dangerouslySetInnerHTML={{
                __html: strings.pages.reset.instructions,
              }}
              variant="body1"
            />
            <Divider className={[classes.mb2, classes.mt2].join(' ')} />
            {Object.values(strings.pages.reset.sections).map((section) => {
              return (
                <Box className={classes.mb2} key={`${section.type}`}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: section.title,
                    }}
                    variant="h2"
                  />
                  <Typography
                    className={classes.mb1}
                    dangerouslySetInnerHTML={{
                      __html: section.instructions,
                    }}
                    variant="body1"
                  />
                  <Button
                    className={classes.formButton}
                    color="primary"
                    onClick={() => {
                      setDialogData((prevData) => {
                        return {
                          ...prevData,
                          cancelLabel: section.dialog_button_cancel,
                          confirmLabel: section.dialog_button_confirm,
                          message: section.dialog_message,
                          messageSuccess: section.dialog_message_success,
                          open: true,
                          tertiaryLabel: null,
                          type: section.type,
                        };
                      });
                    }}
                    variant="contained"
                  >
                    {section.button_label}
                  </Button>
                </Box>
              );
            })}
          </Box>
          <ConfirmationDialog
            cancelLabel={dialogData.cancelLabel}
            confirmLabel={dialogData.confirmLabel}
            message={dialogData.message}
            onCancelClick={() => {
              setDialogData((prevData) => {
                return {
                  ...prevData,
                  open: false,
                };
              });
            }}
            onClose={() => {
              setDialogData((prevData) => {
                return {
                  ...prevData,
                  open: false,
                };
              });
            }}
            onConfirmClick={() => {
              handleButtonClick();
            }}
            onTertiaryClick={() => {
              setDialogData((prevData) => {
                return {
                  ...prevData,
                  open: false,
                };
              });
              navigate('/');
            }}
            open={dialogData.open}
            tertiaryLabel={dialogData.tertiaryLabel}
          />
        </Grid>
      ) : null}
    </>
  );
}

ResetView.propTypes = {
  onBackdropUpdate: PropTypes.func,
  onError: PropTypes.func,
  user: PropTypes.object,
};

ResetView.defaultProps = {
  onBackdropUpdate: () => {},
  onError: () => {},
  user: null,
};
