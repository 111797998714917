/**
 * @module AdminView
 */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import {
  AppBar,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Alert, TreeItem, TreeView } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ErrorIcon from '@material-ui/icons/Error';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import validator from 'validator';
import {
  donors,
  pledges,
  projects,
} from 'api/messengerinternational/mcup-auction-app';
import { MessengerLogo } from 'components/ui/images';
import {
  ConfirmationDialog,
  DonorListDialog,
  ProjectListDialog,
} from 'components/ui/dialogs';
import { AppMenu } from 'components/ui/menus';
import { useLocale } from 'context/locale';
import { COLORS, LOCAL_STORAGE_KEYS, STATUS_TYPES } from 'utils/constants';
import {
  apiResponseComplete,
  formatNumberForDisplay,
  formatUSD,
  generatePledgeId,
  getArrayObjectByAttribute,
  getLocalStorageItem,
  useLocalStorageState,
} from 'utils/helpers';
import { useStyles } from 'utils/styles';
import { LoadingButton } from 'components/ui/buttons';
import {
  ArrowDropDown,
  ArrowRight,
  AttachMoney,
  Flag,
  FlagOutlined,
  MonetizationOn,
  SubdirectoryArrowRight,
} from '@material-ui/icons';
import { SignInView } from '../common/sign-in';

/**
 * Represents the Auction Website Admin view.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.donorList - The list of projects.
 * @param {Function} [props.onBackdropUpdate] - Function to call to update the backdrop properties state.
 * @param {Function} [props.onFetchAppData] - Handler function for data fetch request.
 * @param {Function} [props.onNewDonorSubmit] - Handler function for New Donor form submit.
 * @param {Function} [props.onResetApp] - Handler function for app reset request.
 * @param {Function} props.onSignOut - Handler function for user sign out.
 * @param {Function} [props.onUploadAndSyncData] - Handler function for data fetch request.
 * @param {Function} [props.onUserAuthenticated] - The handler function for user authentication success.
 * @param {Array} props.projectList - The list of projects.
 * @param {User} [props.user] - The User data object.
 *
 * @returns {React.ReactElement} - The AdminView view component.
 */
// eslint-disable-next-line no-unused-vars
export function AdminView({
  donorList,
  onBackdropUpdate,
  onFetchAppData,
  // eslint-disable-next-line no-unused-vars
  onNewDonorSubmit,
  onResetApp,
  onUploadAndSyncData,
  onSignOut,
  onUserAuthenticated,
  projectList,
  user,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { strings } = useLocale();
  const [apiStatus, setApiStatus] = React.useState(STATUS_TYPES.IDLE);
  const [activeView, setActiveView] = React.useState(0);
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const defaultDonations = {};
  const defaultFormData = {
    amount: {
      hasBeenTouched: false,
      isError: null,
      value: '',
    },
  };
  const defaultGenericDialogData = {
    message: '',
    open: false,
    title: '',
  };
  const defaultSnackbarData = {
    message: '',
    open: false,
    severity: 'info',
  };

  const appBarRef = React.useRef(null);

  // Array of donations objects. Each donation should have project number and
  // donation amount.
  const [allDonations, setAllDonations] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.appDonations,
    getLocalStorageItem(LOCAL_STORAGE_KEYS.appDonations) || defaultDonations,
  );
  // Array of project pledges with ProjectsByDonor object shape.
  const [allProjectsByDonor, setAllProjectsByDonor] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.siteProjectsByDonor,
    {},
  );
  const [formData, setFormData] = React.useState(defaultFormData);
  const [formError, setFormError] = React.useState(null);
  const [genericDialogData, setGenericDialogData] = React.useState(
    defaultGenericDialogData,
  );
  const [isDonorListDialogOpen, setIsDonorListDialogOpen] =
    React.useState(false);
  const [isProjectListDialogOpen, setIsProjectListDialogOpen] =
    React.useState(false);
  const [selectedDonor, setSelectedDonor] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.siteSelectedDonor,
    null,
  );
  const [selectedProject, setSelectedProject] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.siteSelectedProject,
    null,
  );
  const [snackbarData, setSnackbarData] = React.useState(defaultSnackbarData);

  /**
   * Handler function for form input elements blur events.
   *
   * @param {Event} event - The Event object associated with the blur event.
   */
  function handleFormInputBlur(event) {
    const { id: inputId, value: inputValue } = event.target;
    const inputHasBeenTouched =
      formData[inputId].hasBeenTouched ||
      (!formData[inputId].hasBeenTouched && formData[inputId].value !== '');
    let isError = false;
    if (inputId === 'amount') {
      isError = validator.isEmpty(inputValue);
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [inputId]: {
          hasBeenTouched: inputHasBeenTouched,
          isError,
          value: formData[inputId]?.value || '',
        },
      };
    });
  }

  /**
   * Handler function for form input elements change events.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleFormInputChange(event) {
    const { id: inputId, value: inputValue } = event.target;
    let isError = false;
    if (inputId === 'amount') {
      isError = validator.isEmpty(inputValue);
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [inputId]: {
          hasBeenTouched: false, // Set explicitly to avoid error state while user typing.
          isError: isError && formData[inputId]?.hasBeenTouched,
          value: inputValue,
        },
      };
    });
  }

  /**
   * Handler function for tree list item click event.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleTreeListItemLabelClick(event) {
    // If the event target closest parent (specifically classed) with class
    // of `listItemLabelGrid` also has class of `buttons`, prevent default.
    // Otherwise, let the auto-expand functionality happen as normal.
    if (
      event.target.closest('.listItemLabelGrid').classList.contains('buttons')
    ) {
      event.preventDefault();
    }
  }

  /**
   * Convenience function to parse projects by donors and create an array of
   * ProjectsByDonor objects, keyed with attribute of donorID. Once parsed, the
   * allProjectsByDonor state is set with the results.
   *
   * @param {Array} dataArray - The array of project pledges for all donors.
   */
  function parseProjectsByDonorData(dataArray) {
    const donorsObject = {};
    dataArray.forEach((item) => {
      if (!donorsObject[item.donorID]) {
        donorsObject[item.donorID] = [];
      }
      const { donorDisplayName } =
        getArrayObjectByAttribute({
          array: donorList,
          attribute: 'donorID',
          attributeValue: item.donorID,
        }) || '';
      donorsObject[item.donorID].push({
        ...item,
        donorDisplayName,
      });
    });
    setAllProjectsByDonor(donorsObject);
  }

  /**
   * Convenience function for projects by donor data retrieval.
   *
   * @returns {Array|boolean} - React State array.
   */
  async function fetchProjectsByDonorData() {
    onBackdropUpdate({
      open: true,
      statusMessage: strings?.status_messages?.fetching_data || '',
      value: 0,
    });
    setApiStatus(STATUS_TYPES.PENDING);
    try {
      const projectsByDonorResponse = await projects.donor.getProjects();
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      if (apiResponseComplete(projectsByDonorResponse, 'array')) {
        setApiStatus(STATUS_TYPES.RESOLVED);
        parseProjectsByDonorData(projectsByDonorResponse.data);
      } else {
        const projectsByDonorError = new Error(projectsByDonorResponse.message);
        setApiStatus(STATUS_TYPES.ERROR);
        setFormError(projectsByDonorError);
      }
      return null;
    } catch (error) {
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.fetching_data || '',
        value: 0,
      });
      setApiStatus(STATUS_TYPES.ERROR);
      setFormError(error);
      Sentry.captureException(error);
      return null;
    }
  }

  /**
   * Convenience function to calculate and generate total pledge amount for the specified set of donor pledges.
   *
   * @param {Array} donorPledges - Array of ProjectsByDonor objects.
   *
   * @returns {number} The total pledge amount for the specified donor pledges.
   */
  function generateTotalPledgeAmountForDonor(donorPledges) {
    let total = 0;
    donorPledges.forEach((pledge) => {
      total += parseInt(pledge.pledgeAmount, 10);
    });
    return total;
  }

  /**
   * Handler function for tab view change event.
   *
   * @param {Event} event - The Event associated with the change.
   * @param {number} index - The tab index selected.
   */
  function handleActiveViewChange(event, index) {
    setActiveView(index);
  }

  /**
   * Handler function for Donor Select click.
   */
  function handleDonorSelectClick() {
    setIsDonorListDialogOpen(true);
  }

  /**
   * Convenience function for handling generic dialog cancel click.
   */
  function handleGenericDialogCancelClick() {
    setGenericDialogData(() => {
      return defaultGenericDialogData;
    });
  }

  /**
   * Convenience function for handling generic dialog cancel click.
   */
  function handleGenericDialogConfirmClick() {
    setGenericDialogData(() => {
      return defaultGenericDialogData;
    });
  }

  /**
   * Handler function for flagging a donor for review.
   *
   * @param {number|string} donorId - The donor id.
   */
  async function handleFlagDonorForReview(donorId) {
    handleGenericDialogCancelClick();
    onBackdropUpdate({
      open: true,
      statusMessage: strings?.status_messages?.fetching_data || '',
      value: 0,
    });
    setFormError(null);
    setApiStatus(STATUS_TYPES.PENDING);
    try {
      const apiResponse = await donors.donor.markForReview({ donorId });
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      if (apiResponseComplete(apiResponse, 'array')) {
        setApiStatus(STATUS_TYPES.RESOLVED);
        fetchProjectsByDonorData();
      } else {
        const apiError = new Error(apiResponse.message);
        setApiStatus(STATUS_TYPES.ERROR);
        setFormError(apiError);
      }
      return null;
    } catch (error) {
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.fetching_data || '',
        value: 0,
      });
      setApiStatus(STATUS_TYPES.ERROR);
      setFormError(error);
      Sentry.captureException(error);
      return null;
    }
  }

  /**
   * Handler function for flagging a pledge for review.
   *
   * @param {number|string} pledgeId - The pledge id.
   */
  async function handleFlagPledgeForReview(pledgeId) {
    handleGenericDialogCancelClick();
    onBackdropUpdate({
      open: true,
      statusMessage: strings?.status_messages?.fetching_data || '',
      value: 0,
    });
    setFormError(null);
    setApiStatus(STATUS_TYPES.PENDING);
    try {
      const apiResponse = await pledges.pledge.markForReview({ pledgeId });
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      if (apiResponseComplete(apiResponse, 'array')) {
        setApiStatus(STATUS_TYPES.RESOLVED);
        fetchProjectsByDonorData();
      } else {
        const apiError = new Error(apiResponse.message);
        setApiStatus(STATUS_TYPES.ERROR);
        setFormError(apiError);
      }
      return null;
    } catch (error) {
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.fetching_data || '',
        value: 0,
      });
      setApiStatus(STATUS_TYPES.ERROR);
      setFormError(error);
      Sentry.captureException(error);
      return null;
    }
  }

  /**
   * Handler function for marking a donor as paid.
   *
   * @param {number|string} donorId - The donor id.
   */
  async function handleMarkDonorAsPaid(donorId) {
    handleGenericDialogCancelClick();
    onBackdropUpdate({
      open: true,
      statusMessage: strings?.status_messages?.fetching_data || '',
      value: 0,
    });
    setFormError(null);
    setApiStatus(STATUS_TYPES.PENDING);
    try {
      const apiResponse = await donors.donor.markAsPaid({ donorId });
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      if (apiResponseComplete(apiResponse, 'array')) {
        setApiStatus(STATUS_TYPES.RESOLVED);
        fetchProjectsByDonorData();
      } else {
        const apiError = new Error(apiResponse.message);
        setApiStatus(STATUS_TYPES.ERROR);
        setFormError(apiError);
      }
      return null;
    } catch (error) {
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.fetching_data || '',
        value: 0,
      });
      setApiStatus(STATUS_TYPES.ERROR);
      setFormError(error);
      Sentry.captureException(error);
      return null;
    }
  }

  /**
   * Handler function for marking a pledge as paid.
   *
   * @param {number|string} pledgeId - The pledge id.
   */
  async function handleMarkPledgeAsPaid(pledgeId) {
    handleGenericDialogCancelClick();
    onBackdropUpdate({
      open: true,
      statusMessage: strings?.status_messages?.fetching_data || '',
      value: 0,
    });
    setFormError(null);
    setApiStatus(STATUS_TYPES.PENDING);
    try {
      const apiResponse = await pledges.pledge.markAsPaid({ pledgeId });
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      if (apiResponseComplete(apiResponse, 'array')) {
        setApiStatus(STATUS_TYPES.RESOLVED);
        fetchProjectsByDonorData();
      } else {
        const apiError = new Error(apiResponse.message);
        setApiStatus(STATUS_TYPES.ERROR);
        setFormError(apiError);
      }
      return null;
    } catch (error) {
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.fetching_data || '',
        value: 0,
      });
      setApiStatus(STATUS_TYPES.ERROR);
      setFormError(error);
      Sentry.captureException(error);
      return null;
    }
  }

  /**
   * Handler function for Project Select click.
   */
  function handleProjectSelectClick() {
    setIsProjectListDialogOpen(true);
  }

  /**
   * Handler function for Snackbar close.
   */
  function handleSnackbarClose() {
    setSnackbarData(defaultSnackbarData);
  }

  /**
   * Convenience accumulator function to add values of donations reduce call.
   *
   * @param {Function} accumulator - The accumulator function.
   * @param {object} value - The Donation object.
   *
   * @returns {number} - The accumulated value.
   */
  function addDonation(accumulator, value) {
    return accumulator + (Number(value?.Amount) || 0);
  }

  // Convenience variables to keep track of current donations amount and the
  // balance of the total selected project amount minus the current amount of
  // donations for the project. This is all done by adding up the donations for
  // the selected project by iterating over the state for `allDonations` and
  // getting all donations for the selected project number. This value is then
  // subtracted from the total amount of the selected project to calculate the
  // outstanding balance remaining. For example, donations of $10,000 for a
  // project with the amount of $18,700 would result in balance of $8,700.
  const currentDonationsAmountForSelectedProject = Object.values(allDonations)
    .map((donation) => {
      return donation.projectNumber === selectedProject?.projectNumber
        ? donation
        : null;
    })
    .reduce(addDonation, 0);

  const balance = selectedProject
    ? formatNumberForDisplay(
        (
          selectedProject.projectCost - currentDonationsAmountForSelectedProject
        ).toString(),
      )
    : '$0';

  let maxDonationAmount = balance.replace(/[^0-9.]/g, '') || 1000000;
  if (
    maxDonationAmount &&
    String(maxDonationAmount).split('.').length > 1 &&
    String(maxDonationAmount).split('.')[1].length === 1
  ) {
    maxDonationAmount = `${String(maxDonationAmount)}0`;
  }

  /**
   * Handler function for donation submit button click.
   *
   * @returns {Array|boolean} - React State array.
   */
  async function handleSubmitDonation() {
    if (!selectedProject) {
      return setGenericDialogData({
        message: strings.dialogs.messages.no_project_selected,
        open: true,
        title: strings.dialogs.titles.no_project_selected,
      });
    }
    if (!selectedDonor) {
      return setGenericDialogData({
        message: strings.dialogs.messages.no_donor_selected,
        open: true,
        title: strings.dialogs.titles.no_donor_selected,
      });
    }
    if (Number(maxDonationAmount) <= 0) {
      return setGenericDialogData({
        message: strings.dialogs.messages.project_fully_funded,
        open: true,
        title: strings.dialogs.titles.project_fully_funded,
      });
    }
    if (Number(maxDonationAmount) < Number(formData.amount.value)) {
      return setGenericDialogData({
        message: strings.dialogs.messages.donation_amount_more_than_remaining,
        open: true,
        title: strings.dialogs.titles.donation_amount_more_than_remaining,
      });
    }

    onBackdropUpdate({
      open: true,
      statusMessage: strings?.status_messages?.submitting || '',
      value: 0,
    });
    setFormError(null);
    setApiStatus(STATUS_TYPES.PENDING);
    try {
      const createPledgeResponse = await pledges.createPledge({
        deviceName: strings.devices.manual,
        donorDisplayName: selectedDonor.donorDisplayName,
        donorID: selectedDonor.donorID,
        pledgeAmount: formData.amount.value,
        projectNumber: selectedProject.projectNumber,
      });
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      if (apiResponseComplete(createPledgeResponse, 'array')) {
        setApiStatus(STATUS_TYPES.RESOLVED);
        const newPledgeID = generatePledgeId(formData.amount.value);
        const storedDonations = getLocalStorageItem(
          LOCAL_STORAGE_KEYS.appDonations,
        );

        setAllDonations(() => {
          return {
            ...storedDonations,
            [newPledgeID]: {
              donorDisplayName: selectedDonor.donorDisplayName,
              donorID: selectedDonor.donorID,
              isSynced: false,
              localDonorID: selectedDonor.localDonorID,
              localPledgeID: newPledgeID,
              pledgeAmount: formData.amount.value,
              pledgeID: newPledgeID,
              projectNumber: selectedProject.projectNumber,
            },
          };
        });
        setFormData(defaultFormData);
        setSelectedDonor(null);
        setSnackbarData({
          message: strings.messages.success.app_donation_saved,
          open: true,
          severity: 'success',
        });
        onUploadAndSyncData({ hideBackdrop: true, silentFail: true });
      } else {
        const createPledgeError = new Error(createPledgeResponse.message);
        setApiStatus(STATUS_TYPES.ERROR);
        setFormError(createPledgeError);
      }
      return null;
    } catch (error) {
      onBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.submitting || '',
        value: 0,
      });
      setApiStatus(STATUS_TYPES.ERROR);
      setFormError(error);
      Sentry.captureException(error);
      return null;
    }
  }

  /**
   * Handler function for mark donor as paid click event.
   *
   * @param {number|string} donorId - The donor id of the donor to mark as paid.
   * @param {boolean} paidStatus - Boolean flag of the status for which to set the donor.
   */
  function onMarkDonorAsPaidClick(donorId, paidStatus) {
    const dialogData = {
      cancelLabel: paidStatus ? strings.labels.cancel : null,
      confirmLabel: paidStatus ? strings.labels.yes : strings.labels.ok,
      message: !paidStatus
        ? strings.dialogs.messages.donor_marked_as_paid
        : strings.dialogs.messages.mark_donor_as_paid,
      onCancelClick: handleGenericDialogCancelClick,
      onConfirmClick: paidStatus
        ? () => {
            handleMarkDonorAsPaid(donorId);
          }
        : handleGenericDialogConfirmClick,
      open: true,
      title: paidStatus ? strings.dialogs.titles.mark_donor_as_paid : null,
    };
    setGenericDialogData(dialogData);
  }

  /**
   * Handler function for mark pledge as paid click event.
   *
   * @param {number|string} pledgeId - The pledge id of the pledge to mark as paid.
   * @param {boolean} paidStatus - Boolean flag of the status for which to set the pledge.
   */
  function onMarkPledgeAsPaidClick(pledgeId, paidStatus) {
    const dialogData = {
      cancelLabel: paidStatus ? strings.labels.cancel : null,
      confirmLabel: paidStatus ? strings.labels.yes : strings.labels.ok,
      message: !paidStatus
        ? strings.dialogs.messages.pledge_marked_as_paid
        : strings.dialogs.messages.mark_pledge_as_paid,
      onCancelClick: handleGenericDialogCancelClick,
      onConfirmClick: paidStatus
        ? () => {
            handleMarkPledgeAsPaid(pledgeId);
          }
        : handleGenericDialogConfirmClick,
      open: true,
      title: paidStatus ? strings.dialogs.titles.mark_pledge_as_paid : null,
    };
    setGenericDialogData(dialogData);
  }

  /**
   * Handler function for report donor for review click event.
   *
   * @param {number|string} donorId - The donor id of the donor to mark for review.
   * @param {boolean} reportStatus - Boolean flag of the status for which to set the donor.
   */
  function onReportDonorClick(donorId, reportStatus) {
    const dialogData = {
      cancelLabel: reportStatus ? strings.labels.cancel : null,
      confirmLabel: reportStatus ? strings.labels.yes : strings.labels.ok,
      message: !reportStatus
        ? strings.dialogs.messages.donor_flagged_for_review
        : strings.dialogs.messages.flag_donor_for_review,
      onCancelClick: handleGenericDialogCancelClick,
      onConfirmClick: reportStatus
        ? () => {
            handleFlagDonorForReview(donorId);
          }
        : handleGenericDialogConfirmClick,
      open: true,
      title: reportStatus ? strings.dialogs.titles.flag_donor_for_review : null,
    };
    setGenericDialogData(dialogData);
  }

  /**
   * Handler function for report pledge for review click event.
   *
   * @param {number|string} pledgeId - The pledge id of the pledge to mark for review.
   * @param {boolean} reportStatus - Boolean flag of the status for which to set the pledge.
   */
  function onReportPledgeClick(pledgeId, reportStatus) {
    const dialogData = {
      cancelLabel: reportStatus ? strings.labels.cancel : null,
      confirmLabel: reportStatus ? strings.labels.yes : strings.labels.ok,
      message: !reportStatus
        ? strings.dialogs.messages.pledge_flagged_for_review
        : strings.dialogs.messages.flag_pledge_for_review,
      onCancelClick: handleGenericDialogCancelClick,
      onConfirmClick: reportStatus
        ? () => {
            handleFlagPledgeForReview(pledgeId);
          }
        : handleGenericDialogConfirmClick,
      open: true,
      title: reportStatus
        ? strings.dialogs.titles.flag_pledge_for_review
        : null,
    };
    setGenericDialogData(dialogData);
  }

  /**
   * Single-run convenience effect to ensure there is donor and project list
   * data, and to fetch projects by donors data as well.
   */
  React.useEffect(() => {
    onFetchAppData({
      callback: () => {
        fetchProjectsByDonorData();
      },
      hideBackdrop: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!donorList || !projectList ? (
        <></>
      ) : (
        <>
          {strings ? (
            <>
              {!user ? (
                <SignInView
                  onBackdropUpdate={onBackdropUpdate}
                  onUserAuthenticated={onUserAuthenticated}
                  referrer={window.location.pathname}
                />
              ) : (
                <>
                  <Grid className={classes.adminWrapper}>
                    {/* App Bar */}
                    <AppBar
                      className={classes.appBarContainer}
                      position="static"
                      ref={appBarRef}
                    >
                      <Toolbar
                        className={classes.appBarToolbar}
                        colors="inherit"
                        disableGutters={true}
                      >
                        <div className={classes.appBarContent}>
                          <MessengerLogo
                            className={classes.appBarLogo}
                            color={COLORS.white}
                          />
                        </div>
                        <AppMenu
                          className={classes.appMenuAdmin}
                          donorList={donorList}
                          onResetApp={onResetApp}
                          onSignOut={onSignOut}
                        />
                      </Toolbar>
                    </AppBar>

                    {/* Main Container */}

                    {activeView === 0 ? (
                      <Grid
                        className={[
                          classes.adminContainer,
                          classes.p2,
                          classes.pb3,
                        ].join(' ')}
                        container={true}
                      >
                        {/* Data Entry View */}
                        {/* Project Select */}
                        <Grid
                          className={[
                            classes.contentCenter,
                            classes.fullWidth,
                          ].join(' ')}
                          item={true}
                        >
                          <Button
                            className={[
                              classes.flexFullWidth,
                              classes.listDisplayDialogButton,
                              classes.mt0,
                            ].join(' ')}
                            color="primary"
                            endIcon={<ArrowDropDownIcon />}
                            onClick={handleProjectSelectClick}
                            variant="contained"
                          >
                            {selectedProject && selectedProject.projectNumber
                              ? `${strings.labels.project} ${selectedProject.projectNumber}: ${selectedProject.projectName} - ${selectedProject.projectRegion}`
                              : `${strings.labels.select_project}`}
                          </Button>
                        </Grid>

                        {/* Donor Select */}
                        <Grid
                          className={[
                            classes.contentCenter,
                            classes.fullWidth,
                          ].join(' ')}
                          item={true}
                        >
                          <Button
                            className={[
                              classes.flexFullWidth,
                              classes.listDisplayDialogButton,
                            ].join(' ')}
                            color="primary"
                            endIcon={<ArrowDropDownIcon />}
                            onClick={handleDonorSelectClick}
                            variant="contained"
                          >
                            {selectedDonor && selectedDonor.donorID
                              ? `${selectedDonor.donorDisplayName}`
                              : `${strings.labels.select_donor}`}
                          </Button>
                        </Grid>
                        <Grid
                          className={[
                            classes.contentCenter,
                            classes.flexFullWidth,
                          ].join(' ')}
                        >
                          <Box className={classes.flexFullWidth}>
                            {/* Error Container */}
                            {formError ? (
                              <Box className={classes.errorContainer} mb={2}>
                                <Box className={classes.mr05}>
                                  <ErrorIcon />
                                </Box>
                                <Box>
                                  <Typography>{formError.message}</Typography>
                                </Box>
                              </Box>
                            ) : null}

                            {/* Amount Input */}
                            <FormControl
                              className={[
                                classes.formControl,
                                classes.flexFullWidth,
                              ].join(' ')}
                            >
                              <TextField
                                autoComplete="off"
                                className={[classes.flexFullWidth].join(' ')}
                                error={
                                  (formData?.amount?.isError &&
                                    formData?.amount?.hasBeenTouched) ||
                                  false
                                }
                                helperText={
                                  formData?.amount?.isError &&
                                  formData?.amount?.hasBeenTouched
                                    ? strings.forms.helper_text.amount
                                    : ''
                                }
                                id="amount"
                                inputMode="numeric"
                                inputProps={{
                                  pattern: '[0-9]*',
                                }}
                                label={strings.labels.amount}
                                onBlur={handleFormInputBlur}
                                onChange={handleFormInputChange}
                                placeholder={strings.placeholders.amount}
                                required={true}
                                type="number"
                                value={formData?.amount?.value}
                                variant="outlined"
                              />
                            </FormControl>
                          </Box>
                          <Box className={classes.flexFullWidth}>
                            <LoadingButton
                              className={[
                                classes.adminSubmitButton,
                                classes.mb2,
                                classes.mt1,
                              ].join(' ')}
                              color="secondary"
                              disabled={Boolean(
                                formData?.amount?.isError ||
                                  !selectedDonor ||
                                  !selectedProject,
                              )}
                              endIcon={<PlayArrowIcon />}
                              isLoading={apiStatus === STATUS_TYPES.PENDING}
                              onClick={handleSubmitDonation}
                              variant="contained"
                            >
                              {strings.labels.submit}
                            </LoadingButton>
                            <Snackbar
                              autoHideDuration={3000}
                              onClose={handleSnackbarClose}
                              open={Boolean(snackbarData.open)}
                            >
                              <Alert
                                className={classes.globalShadow}
                                onClose={handleSnackbarClose}
                                severity={snackbarData.severity}
                              >
                                {snackbarData.message}
                              </Alert>
                            </Snackbar>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        className={[
                          classes.adminContainer,
                          classes.p05,
                          classes.pb3,
                        ].join(' ')}
                        container={true}
                      >
                        {/* Report View */}
                        <Grid
                          className={[
                            classes.contentCenter,
                            classes.fullWidth,
                          ].join(' ')}
                          item={true}
                        >
                          <TreeView
                            className={classes.reportTreeList}
                            defaultCollapseIcon={<ArrowDropDown />}
                            defaultExpandIcon={<ArrowRight />}
                            multiSelect={true}
                          >
                            {Object.entries(allProjectsByDonor).map(
                              (donorEntry) => {
                                const [donorId, donorPledges] = donorEntry;
                                return (
                                  <TreeItem
                                    className={classes.reportTreeListItem}
                                    key={`${donorId}-${donorPledges[0].pledgeId}-${donorPledges[0].projectNumber}`}
                                    label={
                                      <Grid
                                        className={classes.alignItemsCenter}
                                        container={true}
                                      >
                                        <Grid
                                          className={[
                                            classes.flexGrow1,
                                            'listItemLabelGrid',
                                            'label',
                                          ].join(' ')}
                                          item={true}
                                        >
                                          <Typography
                                            className={
                                              classes.reportTreeListDonorText
                                            }
                                          >
                                            {donorPledges && donorPledges[0]
                                              ? donorPledges[0].donorDisplayName
                                              : ''}
                                          </Typography>
                                          <Typography variant="caption">
                                            {`${formatUSD(
                                              generateTotalPledgeAmountForDonor(
                                                donorPledges,
                                              ),
                                            )}`}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          className={[
                                            classes.flexGrow0,
                                            'listItemLabelGrid',
                                            'buttons',
                                          ].join(' ')}
                                          item={true}
                                        >
                                          <Grid
                                            className={[
                                              classes.alignItemsBaseline,
                                              classes.flexJustifyContentEnd,
                                              classes.noWrap,
                                            ].join(' ')}
                                            container={true}
                                          >
                                            <Grid
                                              className={
                                                classes.reportTreeListItemIconContainer
                                              }
                                              item={true}
                                            >
                                              {parseInt(
                                                donorPledges[0].donorFlagged,
                                                10,
                                              ) ? (
                                                <IconButton
                                                  className={[classes.p05].join(
                                                    ' ',
                                                  )}
                                                  onClick={() => {
                                                    onReportDonorClick(
                                                      donorPledges[0].donorID,
                                                      false,
                                                    );
                                                  }}
                                                >
                                                  <Flag
                                                    className={[
                                                      classes.reportTreeListItemIcon,
                                                      'red',
                                                    ].join(' ')}
                                                  />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  className={classes.p05}
                                                  onClick={() => {
                                                    onReportDonorClick(
                                                      donorPledges[0].donorID,
                                                      true,
                                                    );
                                                  }}
                                                >
                                                  <FlagOutlined
                                                    className={[
                                                      classes.reportTreeListItemIcon,
                                                    ].join(' ')}
                                                  />
                                                </IconButton>
                                              )}
                                            </Grid>
                                            <Grid
                                              className={
                                                classes.reportTreeListItemIconContainer
                                              }
                                              item={true}
                                            >
                                              {parseInt(
                                                donorPledges[0].donorPaid,
                                                10,
                                              ) ? (
                                                <IconButton
                                                  className={classes.p05}
                                                  onClick={() => {
                                                    onMarkDonorAsPaidClick(
                                                      donorPledges[0].donorID,
                                                      false,
                                                    );
                                                  }}
                                                >
                                                  <MonetizationOn
                                                    className={[
                                                      classes.reportTreeListItemIcon,
                                                      'green',
                                                    ].join(' ')}
                                                  />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  className={classes.p05}
                                                  onClick={() => {
                                                    onMarkDonorAsPaidClick(
                                                      donorPledges[0].donorID,
                                                      true,
                                                    );
                                                  }}
                                                >
                                                  <AttachMoney
                                                    className={[
                                                      classes.reportTreeListItemIcon,
                                                    ].join(' ')}
                                                  />
                                                </IconButton>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    }
                                    nodeId={`${donorId}-${donorPledges[0].pledgeId}-${donorPledges[0].projectNumber}`}
                                    onLabelClick={handleTreeListItemLabelClick}
                                  >
                                    {donorPledges.map((pledge) => (
                                      <TreeItem
                                        className={
                                          classes.reportTreeListItemLevel2
                                        }
                                        key={pledge.pledgeID}
                                        label={
                                          <>
                                            <Grid
                                              className={[
                                                classes.alignItemsCenter,
                                                classes.flexJustifyContentSpaceBetween,
                                                classes.noWrap,
                                              ].join(' ')}
                                              container={true}
                                            >
                                              <Grid
                                                className={[
                                                  classes.flexGrow1,
                                                  'listItemLabelGrid',
                                                  'label',
                                                ].join(' ')}
                                                item={true}
                                              >
                                                <Grid
                                                  className={[
                                                    classes.alignItemsEnd,
                                                  ].join(' ')}
                                                  container={true}
                                                >
                                                  <Grid item={true}>
                                                    <SubdirectoryArrowRight
                                                      className={[
                                                        classes.hide,
                                                        classes.reportTreeListSubArrowIcon,
                                                      ].join(' ')}
                                                    />
                                                  </Grid>
                                                  <Grid item={true}>
                                                    <Typography
                                                      className={[
                                                        classes.reportTreeListProjectText,
                                                      ].join(' ')}
                                                    >
                                                      {pledge.projectName}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                      {`${formatUSD(
                                                        pledge.pledgeAmount,
                                                      )}`}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                className={[
                                                  classes.flexGrow0,
                                                  'listItemLabelGrid',
                                                  'buttons',
                                                ].join(' ')}
                                                item={true}
                                              >
                                                <Grid
                                                  className={[
                                                    classes.alignItemsBaseline,
                                                    classes.flexJustifyContentEnd,
                                                    classes.noWrap,
                                                  ].join(' ')}
                                                  container={true}
                                                >
                                                  <Grid
                                                    className={
                                                      classes.reportTreeListItemIconContainer
                                                    }
                                                    item={true}
                                                  >
                                                    {parseInt(
                                                      pledge.isFlagged,
                                                      10,
                                                    ) ? (
                                                      <IconButton
                                                        className={classes.p05}
                                                        onClick={() => {
                                                          onReportPledgeClick(
                                                            pledge.pledgeID,
                                                            false,
                                                          );
                                                        }}
                                                      >
                                                        <Flag
                                                          className={[
                                                            classes.reportTreeListItemIcon,
                                                            'red',
                                                          ].join(' ')}
                                                        />
                                                      </IconButton>
                                                    ) : (
                                                      <IconButton
                                                        className={classes.p05}
                                                        onClick={() => {
                                                          onReportPledgeClick(
                                                            pledge.pledgeID,
                                                            true,
                                                          );
                                                        }}
                                                      >
                                                        <FlagOutlined
                                                          className={[
                                                            classes.reportTreeListItemIcon,
                                                          ].join(' ')}
                                                        />
                                                      </IconButton>
                                                    )}
                                                  </Grid>
                                                  <Grid
                                                    className={
                                                      classes.reportTreeListItemIconContainer
                                                    }
                                                    item={true}
                                                  >
                                                    {parseInt(
                                                      pledge.isPaid,
                                                      10,
                                                    ) ? (
                                                      <IconButton
                                                        className={classes.p05}
                                                        onClick={() => {
                                                          onMarkPledgeAsPaidClick(
                                                            pledge.pledgeID,
                                                            false,
                                                          );
                                                        }}
                                                      >
                                                        <MonetizationOn
                                                          className={[
                                                            classes.reportTreeListItemIcon,
                                                            'green',
                                                          ].join(' ')}
                                                        />
                                                      </IconButton>
                                                    ) : (
                                                      <IconButton
                                                        className={classes.p05}
                                                        onClick={() => {
                                                          onMarkPledgeAsPaidClick(
                                                            pledge.pledgeID,
                                                            true,
                                                          );
                                                        }}
                                                      >
                                                        <AttachMoney
                                                          className={[
                                                            classes.reportTreeListItemIcon,
                                                          ].join(' ')}
                                                        />
                                                      </IconButton>
                                                    )}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </>
                                        }
                                        nodeId="2"
                                      />
                                    ))}
                                  </TreeItem>
                                );
                              },
                            )}
                          </TreeView>
                        </Grid>
                      </Grid>
                    )}

                    {/* Donor List dialog. */}
                    <DonorListDialog
                      closeOnSelect={true}
                      data={donorList}
                      fullScreen={isSmall}
                      onClose={() => {
                        setIsDonorListDialogOpen(false);
                      }}
                      onDonorSelect={setSelectedDonor}
                      open={Boolean(isDonorListDialogOpen)}
                      selectedDonor={selectedDonor}
                    />

                    {/* Project List dialog. */}
                    <ProjectListDialog
                      closeOnSelect={true}
                      data={projectList}
                      fullScreen={isSmall}
                      onClose={() => {
                        setIsProjectListDialogOpen(false);
                      }}
                      onProjectSelect={setSelectedProject}
                      open={Boolean(isProjectListDialogOpen)}
                      selectedProject={selectedProject}
                    />

                    {/* Generic confirmation dialog. */}
                    <ConfirmationDialog
                      cancelLabel={genericDialogData.cancelLabel || null}
                      confirmLabel={
                        genericDialogData.confirmLabel || strings.labels.ok
                      }
                      message={genericDialogData.message}
                      onCancelClick={
                        genericDialogData.onCancelClick ||
                        handleGenericDialogCancelClick
                      }
                      onClose={
                        genericDialogData.onClose ||
                        handleGenericDialogCancelClick
                      }
                      onConfirmClick={
                        genericDialogData.onConfirmClick ||
                        handleGenericDialogConfirmClick
                      }
                      open={Boolean(genericDialogData.open)}
                      title={genericDialogData.title}
                    />

                    {/* Tab Bar */}
                    <AppBar className={classes.adminAppBar}>
                      <Tabs
                        TabIndicatorProps={{
                          style: { background: 'transparent' },
                        }}
                        className={classes.adminAppBarTabs}
                        onChange={handleActiveViewChange}
                        value={activeView}
                        variant="fullWidth"
                      >
                        <Tab
                          aria-controls="admin-view-panel-0"
                          className={classes.adminAppBarTab}
                          id="admin-view-tab-0"
                          label={strings.labels.admin_view_data_entry}
                        />
                        <Tab
                          aria-controls="admin-view-panel-1"
                          className={classes.adminAppBarTab}
                          id="admin-view-tab-1"
                          label={strings.labels.admin_view_report}
                        />
                      </Tabs>
                    </AppBar>
                  </Grid>
                </>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
}

AdminView.propTypes = {
  donorList: PropTypes.array,
  onBackdropUpdate: PropTypes.func,
  onFetchAppData: PropTypes.func,
  onNewDonorSubmit: PropTypes.func,
  onResetApp: PropTypes.func,
  onSignOut: PropTypes.func,
  onUploadAndSyncData: PropTypes.func,
  onUserAuthenticated: PropTypes.func,
  projectList: PropTypes.array,
  user: PropTypes.object,
};

AdminView.defaultProps = {
  onBackdropUpdate: () => {},
  onFetchAppData: () => {},
  onNewDonorSubmit: () => {},
  onResetApp: () => {},
  onSignOut: () => {},
  onUploadAndSyncData: () => {},
  onUserAuthenticated: () => {},
  user: null,
};
