/**
 * @module MessengerInternationalApiMcupAuctionAppPledgesPledge
 */
import { apiClient } from 'api/core';
import {
  MI_API_BASE_URL,
  MI_API_REQUEST_OPTIONS,
} from 'api/messengerinternational/constants';

/**
 * Function to mark the specified pledge as paid.
 *
 * @param {object} params - The function parameters.
 * @param {number|string} params.pledgeId - The pledge id.
 *
 * @throws {Error} - Throws an error if `pledgeId` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function markAsPaid({ pledgeId }) {
  if (
    !pledgeId ||
    (typeof pledgeId !== 'string' && typeof pledgeId !== 'number')
  ) {
    throw new Error(
      'Sorry, there was a problem validating the pledge id provided.',
    );
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      pledge_id: pledgeId,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/pledges/pledge/paid`,
  });
}

/**
 * Function to mark the specified pledge for review.
 *
 * @param {object} params - The function parameters.
 * @param {number|string} params.pledgeId - The pledge id.
 *
 * @throws {Error} - Throws an error if `pledgeId` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function markForReview({ pledgeId }) {
  if (
    !pledgeId ||
    (typeof pledgeId !== 'string' && typeof pledgeId !== 'number')
  ) {
    throw new Error(
      'Sorry, there was a problem validating the pledge id provided.',
    );
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      pledge_id: pledgeId,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/pledges/pledge/review`,
  });
}
