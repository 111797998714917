/**
 * @module Constants
 */
import packageJson from '../../package.json';

export const { version: APP_VERSION } = packageJson;

export const IS_APP = process.env.REACT_APP_IS_APP === '1';
export const ROUTER_BASENAME = process.env.REACT_APP_ROUTER_BASE_NAME || '';

export const API_PING_STATUS_EXPIRY_TTL = 5 * 60 * 1000; // 5 minutes
export const USER_STATUS_EXPIRY_TTL = 7 * 24 * 60 * 60 * 1000; // 1 day

export const WEBSITE_FONT_FAMILY = `'Sequel Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`;

export const COLORS = {
  a: {
    link: '#1BA9D5',
    visited: '#1BA9D5',
  },
  black: '#111212',
  blue: {
    dark: '#060ad0',
    light: '#c3c4fd',
    medium: '#5E61FB',
  },
  forest: {
    dark: '#374842',
    light: '#b6c9c2',
    medium: '#415850',
  },
  gray: {
    5: '#f4f5f5',
    10: '#eaebeb',
    15: '#d0d3d3',
    20: '#8f9696',
    25: '#6a7171',
    30: '#484c4c',
    35: '#404545',
    40: '#2f3131',
    45: '#202222',
    50: '#111212',
    background: '#f4f5f5',
    muted: '#777a7b',
    websiteDonorPledgeAmount: '#4c4c51',
  },
  honoluluBlue: {
    dark: '#23bcfe',
    light: '#23bcfe',
    medium: '#23bcfe',
  },
  sand: {
    dark: '#b0844f',
    light: '#f3efe6',
    medium: '#ebe1d5',
  },
  utility: {
    caution: {
      dark: '#975c11',
      light: '#fef5eb',
      medium: '#f19c33',
    },
    danger: {
      dark: '#7a2629',
      light: '#faeceb',
      medium: '#f23b22',
    },
    info: {
      dark: '#29537a',
      light: '#e7f2fd',
      medium: '#007cc3',
    },
    success: {
      dark: '#346148',
      light: '#eef6f0',
      medium: '#4aae67',
    },
  },
  violet: {
    dark: '#7152ff',
    light: '#7152ff',
    medium: '#7152ff',
  },
  white: '#ffffff',
};

export const MI_TITLE_APP = 'Messenger International Auction App';
export const MI_TITLE_WEBSITE = 'Messenger International Auction Website';

export const EMPTY_PLEDGES = [
  {
    donorDisplayName: '',
    donorID: '0',
    keyword: '',
    phoneNumber: '',
    pledgeAmount: '0',
    pledgeID: -5,
    projectName: '',
    projectNumber: 164,
  },
  {
    donorDisplayName: '',
    donorID: '0',
    keyword: '',
    phoneNumber: '',
    pledgeAmount: '0',
    pledgeID: -4,
    projectName: '',
    projectNumber: 164,
  },
  {
    donorDisplayName: '',
    donorID: '0',
    keyword: '',
    phoneNumber: '',
    pledgeAmount: '0',
    pledgeID: -3,
    projectName: '',
    projectNumber: 164,
  },
  {
    donorDisplayName: '',
    donorID: '0',
    keyword: '',
    phoneNumber: '',
    pledgeAmount: '0',
    pledgeID: -2,
    projectName: '',
    projectNumber: 164,
  },
  {
    donorDisplayName: '',
    donorID: '0',
    keyword: '',
    phoneNumber: '',
    pledgeAmount: '0',
    pledgeID: -1,
    projectName: '',
    projectNumber: 164,
  },
];

export const LOCAL_STORAGE_KEYS = {
  appDonations: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::APP::DONATIONS`,
  appDonorsList: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::APP::DONOR_LIST`,
  appDonorsListRefs: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::APP::DONOR_LIST_REFS`,
  appNewDonorsList: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::APP::DONOR_LIST::NEW`,
  appProjectsList: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::APP::PROJECT_LIST`,
  appProjectsListRefs: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::APP::PROJECT_LIST_REFS`,
  appSelectedDonor: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::APP::SELECTED_DONOR`,
  appSelectedProject: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::APP::SELECTED_PROJECT`,
  donorPledgesForProject: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::DONOR_PLEDGES_FOR_PROJECT`,
  donorsList: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::DONOR_LIST`,
  lastFiveDonorsAndPledges: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::LAST_FIVE_DONORS_AND_PLEDGES`,
  pledgesForProject: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::PLEDGES_FOR_PROJECT`,
  pledgesForProjectsList: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::PLEDGES_FOR_PROJECTS_LIST`,
  projectsList: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::PROJECT_LIST`,
  siteDonorsList: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::SITE::DONOR_LIST`,
  siteDonorsListRefs: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::SITE::DONOR_LIST_REFS`,
  siteProjectsByDonor: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::SITE::PROJECTS_BY_DONOR`,
  siteProjectsList: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::SITE::PROJECT_LIST`,
  siteProjectsListRefs: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::SITE::PROJECT_LIST_REFS`,
  siteSelectedDonor: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::SITE::SELECTED_DONOR`,
  siteSelectedProject: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::SITE::SELECTED_PROJECT`,
  user: `MI::AUCTION::${process.env.REACT_APP_API_ENV.toUpperCase()}::USER`,
};

export const MODULE_FORM_DATA_DEFAULT = {
  input1: {
    hasBeenTouched: false,
    isError: null,
    value: '',
  },
};

export const QUERY_PARAMS = {
  referrer: 'referrer',
  token: 'token',
};

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const STATUS_TYPES = {
  ERROR: 'error',
  IDLE: 'idle',
  INFO: 'info',
  PENDING: 'pending',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const TODAY = new Date();
