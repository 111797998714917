/**
 * @module AuctionApp
 */
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { STATUS_TYPES } from 'utils/constants';
import { ResetView } from 'views/common/reset';
import { HomeView } from './home';
import { PageNotFoundView } from '../common/page-not-found';

/**
 * Represents the Auction App view.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.apiStatus] - The API status, to help determine the state of API calls.
 * @param {string} [props.className] - Additional classes to be applied to the AuctionApp.
 * @param {Array} props.donorList - The list of donors.
 * @param {Function} [props.onBackdropUpdate] - Function to call to update the main app backdrop properties state.
 * @param {Function} [props.onCloseAuction] - Handler function for auction close request.
 * @param {Function} [props.onError] - Function to call to update the main app error state.
 * @param {Function} [props.onFetchAppData] - Handler function for data fetch request.
 * @param {Function} [props.onNewDonorSubmit] - Handler function for New Donor form submit.
 * @param {Function} [props.onResetApp] - Handler function for app reset request.
 * @param {Function} props.onSignOut - Handler function for user sign out.
 * @param {Function} [props.onUploadAndSyncData] - Handler function for data fetch request.
 * @param {Function} [props.onUserAuthenticated] - The handler function to set the user data to state.
 * @param {Array} props.projectList - The list of projects.
 * @param {User} [props.user] - The User data object.
 *
 * @returns {React.ReactElement} - The AuctionApp view component.
 */
export function AuctionApp({
  apiStatus,
  className,
  donorList,
  onBackdropUpdate,
  onCloseAuction,
  onError,
  onFetchAppData,
  onNewDonorSubmit,
  onResetApp,
  onSignOut,
  onUploadAndSyncData,
  onUserAuthenticated,
  projectList,
  user,
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...props} className={className}>
      <Routes>
        <Route element={<PageNotFoundView user={user} />} path="/404" />

        <Route
          element={
            <HomeView
              apiStatus={apiStatus}
              donorList={donorList}
              onBackdropUpdate={onBackdropUpdate}
              onCloseAuction={onCloseAuction}
              onFetchAppData={onFetchAppData}
              onNewDonorSubmit={onNewDonorSubmit}
              onResetApp={onResetApp}
              onSignOut={onSignOut}
              onUploadAndSyncData={onUploadAndSyncData}
              onUserAuthenticated={onUserAuthenticated}
              projectList={projectList}
              user={user}
            />
          }
          path="/"
        />

        <Route element={<ResetView />} path="/reset" />

        {/* Default Not Found View */}
        <Route element={<PageNotFoundView user={user} />} path="*" />
      </Routes>
    </Box>
  );
}

AuctionApp.propTypes = {
  apiStatus: PropTypes.string,
  className: PropTypes.string,
  donorList: PropTypes.array,
  onBackdropUpdate: PropTypes.func,
  onCloseAuction: PropTypes.func,
  onError: PropTypes.func,
  onFetchAppData: PropTypes.func,
  onNewDonorSubmit: PropTypes.func,
  onResetApp: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onUploadAndSyncData: PropTypes.func,
  onUserAuthenticated: PropTypes.func,
  projectList: PropTypes.array,
  user: PropTypes.shape({
    expiry: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.string,
    username: PropTypes.string,
  }),
};

AuctionApp.defaultProps = {
  apiStatus: STATUS_TYPES.IDLE,
  className: '',
  onBackdropUpdate: () => {},
  onCloseAuction: () => {},
  onError: () => {},
  onFetchAppData: () => {},
  onNewDonorSubmit: () => {},
  onResetApp: () => {},
  onUploadAndSyncData: () => {},
  onUserAuthenticated: () => {},
  user: null,
};
