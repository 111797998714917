/**
 * @module CalculatorButton
 */
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useStyles } from 'utils/styles';

/**
 * Represents a Button used on a calculator keypad.
 *
 * @param {object} props - The component's props object.
 * @param {React.ReactElement} props.children - The button text.
 * @param {string} props.className - Additional classes to be applied to the button.
 * @param {boolean} [props.isSmallText] - Boolean flag for whether or not the label text should be small.
 * @param {Function} [props.onClick] - Function to call for card button click.
 * @param {number|string} props.value - The value attributed to the button.
 *
 * @returns {React.ReactElement} - The Loading Button component.
 */
export function CalculatorButton({ children, isSmallText, onClick, ...props }) {
  const classes = useStyles();

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={
        isSmallText
          ? [classes.calculatorButton, 'small'].join(' ')
          : classes.calculatorButton
      }
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

CalculatorButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isSmallText: PropTypes.bool,
  onClick: PropTypes.func,
};

CalculatorButton.defaultProps = {
  className: '',
  isSmallText: false,
  onClick: null,
};
