/**
 * @module LoadingButton
 */
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress } from '@material-ui/core';

/**
 * Represents a Button with label and circular progress overlay, displayed when in "loading" state.
 *
 * @param {object} props - The component's props object.
 * @param {React.ReactElement} props.children - The button text.
 * @param {string} props.className - Additional classes to be applied to the button.
 * @param {boolean} props.isLoading - Controls showing the loading spinner.
 *
 * @returns {React.ReactElement} - The Loading Button component.
 */
export function LoadingButton({ children, className, isLoading, ...props }) {
  return (
    <Box position="relative">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Button {...props} className={className}>
        {children}
        {isLoading ? (
          <Box
            alignContent="center"
            color="text.primary"
            display="flex"
            position="absolute"
          >
            <CircularProgress color="inherit" size="1rem" />
          </Box>
        ) : null}
      </Button>
    </Box>
  );
}

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

LoadingButton.defaultProps = {
  className: '',
  isLoading: false,
};
