/**
 * @module MessengerLogo
 */
import PropTypes from 'prop-types';

/**
 * Represents the Messenger International Logo.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.color] - Optional color value to be applied.
 * @param {string} [props.style] - Additional styles to be applied.
 *
 * @returns {React.ReactComponent} - The FaithStoryLogo component.
 */
export function MessengerLogo({ color, style }) {
  return (
    <>
      <svg
        fill="none"
        height="47"
        role="img"
        style={style}
        viewBox="0 0 172 47"
        width="172"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.4582 0.672852L22.1167 4.97472C22.5877 4.93414 23.0792 4.91385 23.5502 4.91385C34.0149 4.91385 42.5137 13.335 42.5137 23.7041C42.5137 31.273 38.0083 37.7866 31.496 40.7695L25.3728 46.8368C37.4554 45.9237 46.978 35.8995 46.978 23.7041C46.9985 11.874 38.0288 2.09328 26.4582 0.672852Z"
          fill={color}
        />
        <path
          d="M25.0044 42.4537C24.5334 42.4942 24.0419 42.5145 23.5709 42.5145C13.1062 42.5145 4.60746 34.0934 4.60746 23.7243C4.60746 16.1554 9.11281 9.64175 15.6251 6.65884L21.7483 0.571289C9.66574 1.48442 0.143066 11.5086 0.143066 23.704C0.143066 35.5544 9.09233 45.3351 20.6629 46.7352L25.0044 42.4537Z"
          fill={color}
        />
        <path
          d="M28.2606 14.8564L23.571 23.1761L18.8813 14.8564H14.7036V32.1451H18.8813V22.1818L22.7518 29.0607H24.3696L28.2606 22.1818V32.1451H32.4383V14.8564H28.2606Z"
          fill={color}
        />
        <path
          d="M72.2489 24.9221H68.6447V13.4369H68.5627L64.9175 24.9221H62.1529L58.5076 13.4369H58.4257V24.9221H54.9238V8.97266H60.5555L63.5659 19.3012H63.6478L66.6582 8.97266H72.2694V24.9221H72.2489Z"
          fill={color}
        />
        <path
          d="M85.6829 21.3706C85.2529 23.0345 83.5941 25.2057 79.6622 25.2057C75.8326 25.2057 73.4775 22.8316 73.4775 19.0167C73.4775 15.1815 75.7712 12.7871 79.6417 12.7871C83.5327 12.7871 86.072 15.1815 85.7853 19.7269H77.1433C77.1842 21.5938 78.1467 22.8113 79.6622 22.8113C81.2185 22.8113 81.8124 21.8779 82.0172 21.1271L85.6829 21.3706ZM77.1842 17.576H82.1196C82.0377 16.1961 81.1571 15.1207 79.6417 15.1207C78.1467 15.141 77.2866 16.1961 77.1842 17.576Z"
          fill={color}
        />
        <path
          d="M94.2432 16.6834C94.0385 15.6282 93.1988 15.0397 92.1134 15.0397C91.0895 15.0397 90.3523 15.4659 90.3523 16.2775C90.3523 17.0486 91.1305 17.2718 92.3387 17.4747L93.5879 17.6574C95.7792 18.0226 97.9294 18.6517 97.9294 21.3505C97.9294 24.0087 95.472 25.1857 92.2158 25.1857C89.0416 25.1857 86.7685 23.8261 86.3589 21.452L89.7174 21.0461C89.9632 22.3245 90.8642 22.9333 92.2363 22.9333C93.465 22.9333 94.2637 22.4057 94.2637 21.5128C94.2637 20.7823 93.4446 20.4576 92.1544 20.2344L90.7823 20.0112C88.8163 19.7474 86.7685 19.0575 86.7685 16.4804C86.7685 14.086 89.2874 12.8076 92.093 12.8076C94.96 12.8076 97.1512 14.1063 97.5608 16.3181L94.2432 16.6834Z"
          fill={color}
        />
        <path
          d="M106.223 16.6834C106.018 15.6282 105.179 15.0397 104.093 15.0397C103.069 15.0397 102.332 15.4659 102.332 16.2775C102.332 17.0486 103.11 17.2718 104.319 17.4747L105.568 17.6574C107.759 18.0226 109.909 18.6517 109.909 21.3505C109.909 24.0087 107.452 25.1857 104.196 25.1857C101.022 25.1857 98.7484 23.8261 98.3389 21.452L101.697 21.0461C101.943 22.3245 102.844 22.9333 104.216 22.9333C105.445 22.9333 106.244 22.4057 106.244 21.5128C106.244 20.7823 105.425 20.4576 104.134 20.2344L102.742 20.0518C100.796 19.7474 98.728 19.0575 98.728 16.4804C98.728 14.086 101.247 12.8076 104.052 12.8076C106.92 12.8076 109.111 14.1063 109.52 16.3181L106.223 16.6834Z"
          fill={color}
        />
        <path
          d="M122.873 21.3706C122.443 23.0345 120.784 25.2057 116.852 25.2057C113.023 25.2057 110.667 22.8316 110.667 19.0167C110.667 15.1815 112.961 12.7871 116.832 12.7871C120.723 12.7871 123.262 15.1815 122.975 19.7269H114.333C114.374 21.5938 115.337 22.8113 116.852 22.8113C118.408 22.8113 119.002 21.8779 119.207 21.1271L122.873 21.3706ZM114.395 17.576H119.33C119.248 16.1961 118.368 15.1207 116.852 15.1207C115.337 15.141 114.477 16.1961 114.395 17.576Z"
          fill={color}
        />
        <path
          d="M127.726 14.6745C128.484 13.6193 129.692 12.8076 131.535 12.8076C133.89 12.8076 135.733 14.1469 135.733 17.1704V24.9219H132.149V18.0226C132.149 16.2775 131.412 15.6485 130.122 15.6485C128.893 15.6485 127.787 16.5616 127.787 18.5299V24.9219H124.204V13.0714H127.644V14.6542L127.726 14.6745Z"
          fill={color}
        />
        <path
          d="M149.086 24.0087C149.086 27.9859 146.383 29.3252 142.963 29.3252C140.034 29.3252 137.659 28.2091 136.901 25.3886L140.464 25.0639C140.833 26.2611 141.652 26.7684 142.963 26.7684C144.478 26.7684 145.502 25.9973 145.502 23.9276V22.7912H145.42C144.724 23.7855 143.556 24.3131 141.877 24.3131C139.01 24.3131 136.839 22.1216 136.839 18.5502C136.839 14.9991 139.01 12.8076 141.959 12.8076C143.434 12.8076 144.806 13.254 145.563 14.5324H145.645V13.0917H149.086V24.0087ZM145.686 18.5502C145.686 16.6428 144.662 15.4659 143.085 15.4659C141.509 15.4659 140.485 16.6428 140.485 18.5502C140.485 20.4982 141.509 21.6954 143.085 21.6954C144.662 21.6954 145.686 20.4779 145.686 18.5502Z"
          fill={color}
        />
        <path
          d="M162.376 21.3706C161.946 23.0345 160.287 25.2057 156.356 25.2057C152.526 25.2057 150.171 22.8316 150.171 19.0167C150.171 15.1815 152.465 12.7871 156.335 12.7871C160.226 12.7871 162.765 15.1815 162.479 19.7269H153.837C153.878 21.5938 154.84 22.8113 156.356 22.8113C157.912 22.8113 158.506 21.8779 158.711 21.1271L162.376 21.3706ZM153.878 17.576H158.813C158.731 16.1961 157.85 15.1207 156.335 15.1207C154.84 15.141 153.959 16.1961 153.878 17.576Z"
          fill={color}
        />
        <path
          d="M167.127 14.918H167.209C167.865 13.6396 169.052 12.8076 170.834 12.8076C171.1 12.8076 171.367 12.8482 171.551 12.8888V16.1761C171.264 16.1355 170.752 16.0949 170.261 16.0949C168.54 16.0949 167.25 16.8051 167.25 19.1184V24.9219H163.667V13.0714H167.107L167.127 14.918Z"
          fill={color}
        />
        <path
          d="M54.9648 31.9834H55.784V38.4971H54.9648V31.9834Z"
          fill={color}
        />
        <path
          d="M65.1433 38.4983L61.5799 33.1615H61.5594V38.5186H60.7812V32.0049H61.7028L65.2252 37.2808H65.2456V32.0049H66.0238V38.5186H65.1433V38.4983Z"
          fill={color}
        />
        <path
          d="M73.7647 32.6936V38.4971H72.9456V32.6936H70.7134V31.9834H75.9969V32.6936H73.7647Z"
          fill={color}
        />
        <path
          d="M84.9661 34.8243V35.5142H81.5052V37.7869H85.1094V38.4971H80.6655V31.9834H85.1094V32.6733H81.5052V34.8243H84.9661Z"
          fill={color}
        />
        <path
          d="M92.4413 35.8591H90.762V38.4971H89.9429V31.9834H92.8918C94.3049 31.9834 95.0831 32.7139 95.0831 33.9111C95.0831 34.9663 94.4687 35.6562 93.3833 35.8186L95.1445 38.4971H94.1615L92.4413 35.8591ZM92.769 35.1692C93.711 35.1692 94.223 34.7431 94.223 33.9314C94.223 33.1197 93.711 32.6936 92.769 32.6936H90.7416V35.1692H92.769Z"
          fill={color}
        />
        <path
          d="M104.237 38.4971L100.674 33.14H100.653V38.4971H99.875V31.9834H100.797L104.319 37.2593H104.339V31.9834H105.118V38.4971H104.237Z"
          fill={color}
        />
        <path
          d="M111.322 36.6099L110.585 38.4971H109.684L112.305 31.9834H113.329L115.951 38.4971H115.05L114.312 36.6099H111.322ZM112.838 32.7545H112.817L111.609 35.8997H114.087L112.838 32.7545Z"
          fill={color}
        />
        <path
          d="M122.34 32.6936V38.4971H121.521V32.6936H119.289V31.9834H124.573V32.6936H122.34Z"
          fill={color}
        />
        <path
          d="M129.262 31.9834H130.102V38.4971H129.262V31.9834Z"
          fill={color}
        />
        <path
          d="M141.017 35.251C141.017 37.3614 139.768 38.6398 137.986 38.6398C136.205 38.6398 134.956 37.3614 134.956 35.251C134.956 33.1407 136.205 31.8623 137.986 31.8623C139.768 31.8623 141.017 33.1407 141.017 35.251ZM140.157 35.251C140.157 33.5465 139.277 32.5522 137.966 32.5522C136.655 32.5522 135.775 33.5465 135.775 35.251C135.775 36.9556 136.655 37.9499 137.966 37.9499C139.277 37.9499 140.157 36.9556 140.157 35.251Z"
          fill={color}
        />
        <path
          d="M150.212 38.4971L146.649 33.14H146.628V38.4971H145.85V31.9834H146.772L150.294 37.2593H150.314V31.9834H151.093V38.4971H150.212Z"
          fill={color}
        />
        <path
          d="M157.298 36.6099L156.561 38.4971H155.66L158.281 31.9834H159.305L161.926 38.4971H161.025L160.288 36.6099H157.298ZM158.813 32.7545H158.793L157.585 35.8997H160.063L158.813 32.7545Z"
          fill={color}
        />
        <path
          d="M166.514 31.9834H167.333V37.7869H170.958V38.4971H166.493V31.9834H166.514Z"
          fill={color}
        />
      </svg>
    </>
  );
}

MessengerLogo.propTypes = {
  color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

MessengerLogo.defaultProps = {
  color: '#FFFFFF',
  style: { position: 'relative' },
};
