/**
 * @module DonorListDialog
 */
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useLocale } from 'context/locale';
import { useStyles } from 'utils/styles';

/**
 * Represents the Donor List Dialog view.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.closeOnSelect] - Optional boolean flag to determine whether or not to auto-close dialog when an item is selected.
 * @param {Array} props.data - The array of data objects used to populate the dialog list content.
 * @param {boolean} [props.fullScreen] - Optional boolean flag to set full screen property (Default: true).
 * @param {Function} props.onClose - The function to fire on dialog close.
 * @param {Function} props.onDonorSelect - The function to fire when a donor is selected.
 * @param {boolean} [props.open] - Boolean flag for whether or not dialog is open.
 * @param {boolean} [props.selectedDonor] - The optional selected donor attribute.
 *
 * @returns {React.ReactElement} - The DonorListDialog component.
 */
export function DonorListDialog({
  closeOnSelect,
  data,
  fullScreen,
  onClose,
  onDonorSelect,
  open,
  selectedDonor,
}) {
  const classes = useStyles();
  const { strings } = useLocale();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      {strings && data ? (
        <>
          <Dialog
            PaperProps={{ className: classes.donorListDialog }}
            aria-labelledby="confirmation-dialog-title"
            className={classes.donorListDialog}
            fullScreen={fullScreen}
            id="dialog_confirm"
            onClose={onClose}
            open={open}
            scroll="paper"
          >
            <Box className={classes.positionAbsoluteTopRight1}>
              <IconButton
                onClick={() => {
                  onClose();
                }}
              >
                <ClearIcon className={classes.appMenuCloseIcon} />
              </IconButton>
            </Box>
            <DialogTitle id="confirmation-dialog-title">
              {strings.dialogs.titles.donor_list}
            </DialogTitle>
            <DialogContent dividers={false}>
              <Box>
                <ImageList
                  className={classes.donorListContainer}
                  cols={isSmall ? 1 : 3}
                  rowHeight="auto"
                >
                  {data.map((donor, index) => {
                    return (
                      <ImageListItem
                        cols={1}
                        key={`${String(donor.donorDisplayName || '')
                          .toLowerCase()
                          .replace(/[^a-z]/gi, '')}-${index}`}
                      >
                        <Card
                          className={
                            selectedDonor &&
                            parseInt(selectedDonor.donorID, 10) ===
                              parseInt(donor.donorID, 10)
                              ? [classes.donorCard, 'selected'].join(' ')
                              : classes.donorCard
                          }
                          onClick={() => {
                            onDonorSelect(donor);
                            if (closeOnSelect) {
                              onClose();
                            }
                          }}
                        >
                          <CardContent
                            className={[
                              classes.donorCardContent,
                              classes.textLeft,
                            ].join(' ')}
                          >
                            <Box>
                              <Typography
                                className={[
                                  classes.colorSand,
                                  classes.textFirstLetterBigAndBold,
                                  classes.textTruncate,
                                  'dark',
                                ].join(' ')}
                                variant="body1"
                              >
                                {donor.donorDisplayName}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              </Box>
            </DialogContent>
            <DialogActions className={classes.contentCenter}>
              <Button
                color="primary"
                id="btn_donor_list_dialog_confirm"
                onClick={onClose}
                variant="contained"
              >
                {strings.labels.ok}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
}

DonorListDialog.propTypes = {
  closeOnSelect: PropTypes.bool,
  data: PropTypes.array,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  onDonorSelect: PropTypes.func,
  open: PropTypes.bool,
  selectedDonor: PropTypes.object,
};

DonorListDialog.defaultProps = {
  closeOnSelect: false,
  fullScreen: true,
  open: false,
  selectedDonorId: null,
};
