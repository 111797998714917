/**
 * @module MessengerInternationalApiMcupAuctionAppUser
 */
import { apiClient } from 'api/core';
import {
  MI_API_BASE_URL,
  MI_API_REQUEST_OPTIONS,
} from 'api/messengerinternational/constants';

/**
 * Function to log in user with the specified username and password combination.
 *
 * @param {object} params - The function parameters.
 * @param {string} params.password - The user password.
 * @param {string} params.username - The username.
 *
 * @throws {Error} - Throws an error if `password` is not provided or is invalid type.
 * @throws {Error} - Throws an error if `username` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function loginUser({ password, username }) {
  if (!username || typeof username !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the username provided.',
    );
  }
  if (!password || typeof password !== 'string') {
    throw new Error(
      'Sorry, there was a problem validating the password provided.',
    );
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      password,
      username,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/user/login`,
  });
}
