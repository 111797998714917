/**
 * @module AppIndex
 */
import React from 'react';
import * as ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { LocaleProvider } from 'context/locale';
import { ROUTER_BASENAME } from 'utils/constants';
import './assets/fonts/Sequel-Sans-woff/OGJ-Type-Design-Sequel-Sans-Bold-Head.woff';
import './assets/fonts/Sequel-Sans-woff/OGJ-Type-Design-Sequel-Sans-Bold-Obl-Head.woff';
import './assets/fonts/Sequel-Sans-woff/OGJ-Type-Design-Sequel-Sans-Book-Head.woff';
import './assets/fonts/Sequel-Sans-woff/OGJ-Type-Design-Sequel-Sans-Book-Obl-Head.woff';
import './assets/fonts/Sequel-Sans-woff/OGJ-Type-Design-Sequel-Sans-Light-Body.woff';
import './assets/fonts/Sequel-Sans-woff/OGJ-Type-Design-Sequel-Sans-Light-Obl-Body.woff';
import './index.css';
import App from './App';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocaleProvider>
        <BrowserRouter basename={ROUTER_BASENAME}>
          <App />
        </BrowserRouter>
      </LocaleProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
