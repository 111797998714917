/**
 * @module HomeView
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BackspaceIcon from '@material-ui/icons/Backspace';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { ConfirmationDialog, NewDonorFormDialog } from 'components/ui/dialogs';
import { CalculatorButton } from 'components/ui/buttons';
import { AppMenu } from 'components/ui/menus';
import { useLocale } from 'context/locale';
import { LOCAL_STORAGE_KEYS, STATUS_TYPES } from 'utils/constants';
import {
  formatNumberForDisplay,
  generatePledgeId,
  getLocalStorageItem,
  useLocalStorageState,
} from 'utils/helpers';
import { useStyles } from 'utils/styles';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { SignInView } from '../common/sign-in';

/**
 * Represents the Auction App Home view.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.apiStatus] - The API status, to help determine the state of API calls.
 * @param {Array} props.donorList - The list of donors.
 * @param {Function} [props.onBackdropUpdate] - Function to call to update the backdrop properties state.
 * @param {Function} [props.onCloseAuction] - Handler function for auction close request.
 * @param {Function} [props.onFetchAppData] - Handler function for data fetch request.
 * @param {Function} [props.onNewDonorSubmit] - Handler function for New Donor form submit.
 * @param {Function} [props.onResetApp] - Handler function for app reset request.
 * @param {Function} props.onSignOut - Handler function for user sign out.
 * @param {Function} [props.onUploadAndSyncData] - Handler function for data fetch request.
 * @param {Function} [props.onUserAuthenticated] - The handler function for user authentication success.
 * @param {Array} props.projectList - The list of projects.
 * @param {User} [props.user] - The User data object.
 *
 * @returns {React.ReactElement} - The HomeView view component.
 */
export function HomeView({
  apiStatus,
  donorList,
  onBackdropUpdate,
  onCloseAuction,
  onFetchAppData,
  onNewDonorSubmit,
  onResetApp,
  onSignOut,
  onUploadAndSyncData,
  onUserAuthenticated,
  projectList,
  user,
}) {
  /**
   * Convenience function to ensure there is donor and project list data.
   */
  React.useEffect(() => {
    onFetchAppData({ hideBackdrop: true, silentFail: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const { strings } = useLocale();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isPortraitMode = useMediaQuery('@media (orientation: portrait)');

  const defaultDonations = {};
  const defaultGenericDialogData = {
    message: '',
    open: false,
    title: '',
  };
  const defaultSnackbarData = {
    message: '',
    open: false,
    severity: 'info',
  };

  // Array of donations objects. Each donation should have project number and
  // donation amount.
  const [allDonations, setAllDonations] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.appDonations,
    getLocalStorageItem(LOCAL_STORAGE_KEYS.appDonations) || defaultDonations,
  );
  const [activeFilterListItem, setActiveFilterListItem] = React.useState(0);
  const [donationAmount, setDonationAmount] = React.useState('0');
  const [genericDialogData, setGenericDialogData] = React.useState(
    defaultGenericDialogData,
  );
  const [isNewDonorDialogOpen, setIsNewDonorDialogOpen] = React.useState(false);
  const [selectedDonor, setSelectedDonor] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.appSelectedDonor,
    null,
  );
  const [selectedProject, setSelectedProject] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.appSelectedProject,
    null,
  );
  const [snackbarData, setSnackbarData] = React.useState(defaultSnackbarData);

  const [filteredDonorList, setFilteredDonorList] = React.useState(donorList);

  // State to hold donor refs for auto-scroll into view for selected donor.
  const [donorRefs] = React.useState(
    Object.values(donorList || {}).reduce((acc, value) => {
      acc[value.donorID] = React.createRef();
      return acc;
    }, {}),
  );

  /**
   * Handler function for New Donor button click.
   */
  function handleAddDonorClick() {
    setIsNewDonorDialogOpen(true);
  }

  /**
   * Handler function for Donor list item click event.
   *
   * @param {Event} event - The Event object associated with the list item click.
   * @param {object} donor - The donor data object.
   */
  function handleDonorListItemClick(event, donor) {
    setSelectedDonor(donor);
  }

  /**
   * Handler function for Snackbar close.
   */
  function handleSnackbarClose() {
    setSnackbarData(defaultSnackbarData);
  }

  /**
   * Convenience Effect to scroll to selected Donor when the selection is
   * changed.
   */
  React.useEffect(() => {
    if (selectedDonor && donorRefs[selectedDonor.donorID]?.current) {
      donorRefs[selectedDonor.donorID].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [donorRefs, selectedDonor]);

  // Convenience variable for the formatted value of the donation amount.
  const formattedValue = formatNumberForDisplay(donationAmount);

  /**
   * Convenience accumulator function to add values of donations reduce call.
   *
   * @param {Function} accumulator - The accumulator function.
   * @param {object} value - The Donation object.
   *
   * @returns {number} - The accumulated value.
   */
  function addDonation(accumulator, value) {
    return accumulator + (parseInt(value?.pledgeAmount, 10) || 0);
  }

  // Convenience variables to keep track of current donations amount and the
  // balance of the total selected project amount minus the current amount of
  // donations for the project. This is all done by adding up the donations for
  // the selected project by iterating over the state for `allDonations` and
  // getting all donations for the selected project number. This value is then
  // subtracted from the total amount of the selected project to calculate the
  // outstanding balance remaining. For example, donations of $10,000 for a
  // project with the amount of $18,700 would result in balance of $8,700.
  const currentDonationsAmountForSelectedProject = Object.values(allDonations)
    .map((donation) => {
      return donation.projectNumber === selectedProject?.projectNumber
        ? donation
        : null;
    })
    .reduce(addDonation, 0);

  const balance = selectedProject
    ? formatNumberForDisplay(
        (
          parseInt(selectedProject.projectCost, 10) -
          parseInt(currentDonationsAmountForSelectedProject, 10)
        ).toString(),
      )
    : '$0';

  const balanceAsInt = balance.replace(/[^0-9.]/g, '');
  const defaultMaxDonation = selectedProject
    ? selectedProject.projectCost
    : 1000000;
  let maxDonationAmount =
    balanceAsInt > 0 &&
    balanceAsInt <= parseInt(selectedProject.projectCost, 10)
      ? balanceAsInt
      : defaultMaxDonation;
  if (
    maxDonationAmount &&
    String(maxDonationAmount).split('.').length > 1 &&
    String(maxDonationAmount).split('.')[1].length === 1
  ) {
    maxDonationAmount = `${String(maxDonationAmount)}0`;
  }

  /**
   * Handler function for Calculator Button click.
   *
   * @param {Event} event - The Event associated with the button click.
   */
  function handleCalculatorButtonClick(event) {
    const button = event.currentTarget;
    const amountSplit = donationAmount.split('.');
    let updatedAmount = donationAmount;
    switch (String(button.value.toString()).toLowerCase()) {
      case '0':
        if (
          updatedAmount !== '0' &&
          (amountSplit.length === 1 ||
            (amountSplit.length > 1 && amountSplit[1].length < 2))
        ) {
          updatedAmount = `${donationAmount}0`;
        }
        break;
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        if (updatedAmount === '0') {
          updatedAmount = button.value.toString();
        } else if (
          amountSplit.length === 1 ||
          (amountSplit.length > 1 && amountSplit[1].length < 2)
        ) {
          updatedAmount = `${updatedAmount}${button.value.toString()}`;
        }
        break;
      case '.':
        if (amountSplit.length === 1) {
          updatedAmount = `${updatedAmount}.`;
        }
        break;
      case '-':
        if (updatedAmount.indexOf('-') > -1) {
          updatedAmount = updatedAmount.replace('-', '');
        } else {
          updatedAmount = `-${updatedAmount}`;
        }
        break;
      case String(strings.labels.clear).toLowerCase():
        setDonationAmount(0);
        updatedAmount = '0';
        break;
      case String(strings.labels.delete).toLowerCase():
        if (updatedAmount.length === 1) {
          updatedAmount = '0';
        } else if (updatedAmount.length > 1) {
          updatedAmount = updatedAmount.slice(0, -1);
        }
        break;
      case String(strings.labels.half).toLowerCase():
        updatedAmount = String(Number(maxDonationAmount) / 2).toString();
        if (
          updatedAmount.split('.').length > 1 &&
          updatedAmount.split('.')[1].length === 1
        ) {
          updatedAmount = `${updatedAmount}0`;
        }
        break;
      case String(strings.labels.remainder).toLowerCase():
        updatedAmount = String(Number(maxDonationAmount));
        break;
      default:
        break;
    }
    // Per client request, over-funding of a project is acceptable. Leaving this
    // functionality commented out for the future in case of change.
    /* if (parseInt(updatedAmount, 10) <= maxDonationAmount || !selectedProject) {
      setDonationAmount(updatedAmount);
    } */
    setDonationAmount(updatedAmount);
  }

  /**
   * Handler function for donation submit button click.
   *
   * @returns {Array|boolean} - React State array.
   */
  function handleSubmitDonation() {
    if (!selectedProject) {
      return setGenericDialogData({
        message: strings.dialogs.messages.no_project_selected,
        open: true,
        title: strings.dialogs.titles.no_project_selected,
      });
    }
    if (!selectedDonor) {
      return setGenericDialogData({
        message: strings.dialogs.messages.no_donor_selected,
        open: true,
        title: strings.dialogs.titles.no_donor_selected,
      });
    }
    // Per client request, over-funding of a project is acceptable. Leaving this
    // functionality commented out for the future in case of change.
    /* if (Number(maxDonationAmount) <= 0) {
      return setGenericDialogData({
        message: strings.dialogs.messages.project_fully_funded,
        open: true,
        title: strings.dialogs.titles.project_fully_funded,
      });
    }
    if (Number(maxDonationAmount) < Number(donationAmount)) {
      return setGenericDialogData({
        message: strings.dialogs.messages.donation_amount_more_than_remaining,
        open: true,
        title: strings.dialogs.titles.donation_amount_more_than_remaining,
      });
    } */
    // Save donation to state by adding a new item to the array.
    // After updating state/local storage, set snackbar data state.
    const newPledgeID = generatePledgeId(donationAmount);
    const storedDonations = getLocalStorageItem(
      LOCAL_STORAGE_KEYS.appDonations,
    );
    setAllDonations(() => {
      return {
        ...storedDonations,
        [newPledgeID]: {
          donorDisplayName: selectedDonor.donorDisplayName,
          donorID: selectedDonor.donorID,
          isSynced: false,
          localDonorID: selectedDonor.localDonorID,
          localPledgeID: newPledgeID,
          pledgeAmount: donationAmount,
          pledgeID: newPledgeID,
          projectNumber: selectedProject.projectNumber,
        },
      };
    });
    setDonationAmount('0');
    setSnackbarData({
      message: strings.messages.success.app_donation_saved,
      open: true,
      severity: 'success',
    });
    return false;
  }

  /**
   * Convenience effect to upload and sync data when allDonations state changes.
   */
  React.useEffect(() => {
    onUploadAndSyncData({ hideBackdrop: true, silentFail: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDonations]);

  /**
   * Convenience function to retrieve the project index of the specified project.
   *
   * @param {object} project - The Project object.
   *
   * @returns {number} The project index in the projectList array.
   */
  function findProjectIndex(project) {
    return projectList.findIndex((p) => {
      return p.projectNumber === project.projectNumber;
    });
  }

  /**
   * Represents a left navigation arrow for the Horizontal Scrolling Menu.
   *
   * @returns {React.ReactElement} The LeftArrow component.
   */
  function LeftArrow() {
    const selectedProjectIndex = findProjectIndex(selectedProject);
    return (
      <IconButton
        aria-label="back"
        className={[classes.appPanelProjectScrollerArrowButton, 'left'].join(
          ' ',
        )}
        disabled={selectedProjectIndex === 0}
        onClick={() => {
          setSelectedProject(projectList[selectedProjectIndex - 1]);
        }}
      >
        <ArrowLeft className={classes.appPanelProjectScrollerArrow} />
      </IconButton>
    );
  }

  /**
   * Represents a left navigation arrow for the Horizontal Scrolling Menu.
   *
   * @returns {React.ReactElement} The LeftArrow component.
   */
  function RightArrow() {
    const selectedProjectIndex = findProjectIndex(selectedProject);
    return (
      <IconButton
        aria-label="forward"
        className={[classes.appPanelProjectScrollerArrowButton, 'right'].join(
          ' ',
        )}
        disabled={selectedProjectIndex === projectList.length - 1}
        onClick={() => {
          setSelectedProject(projectList[selectedProjectIndex + 1]);
        }}
      >
        <ArrowRight className={classes.appPanelProjectScrollerArrow} />
      </IconButton>
    );
  }

  /**
   * Represents the controller for displaying the currently selected project
   * and corresponding navigation arrows.
   *
   * @returns {React.ReactElement} The ProjectSelectControl component.
   */
  function ProjectSelectControl() {
    return (
      <Grid className={classes.appPanelBottom}>
        <LeftArrow />
        <Grid className={classes.appPanelProjectSelectLabel}>
          <Typography className={classes.appPanelProjectSelectLabelText}>
            {'Project: '}
            {selectedProject?.projectNumber || ''}
          </Typography>
        </Grid>
        <RightArrow />
      </Grid>
    );
  }

  /**
   * Represents the on-screen calculator keypad.
   *
   * @returns {React.ReactElement} The CalculatorKeypad component.
   */
  function CalculatorKeypad() {
    return (
      <Box className={[classes.calculator].join(' ')}>
        {/* Row 1 */}
        {/* 7 (Seven) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col1,
            classes.row1,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={7}>
            7
          </CalculatorButton>
        </Box>
        {/* 8 (Eight) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col2,
            classes.row1,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={8}>
            8
          </CalculatorButton>
        </Box>
        {/* 9 (Nine) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col3,
            classes.row1,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={9}>
            9
          </CalculatorButton>
        </Box>
        {/* Delete */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col4,
            classes.row1,
          ].join(' ')}
        >
          <CalculatorButton
            onClick={handleCalculatorButtonClick}
            value={strings.labels.delete}
          >
            <BackspaceIcon />
          </CalculatorButton>
        </Box>

        {/* Row 2 */}
        {/* 4 (Four) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col1,
            classes.row2,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={4}>
            4
          </CalculatorButton>
        </Box>
        {/* 5 (Five) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col2,
            classes.row2,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={5}>
            5
          </CalculatorButton>
        </Box>
        {/* 6 (Six) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col3,
            classes.row2,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={6}>
            6
          </CalculatorButton>
        </Box>
        {/* - (Minus / Negative) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col4,
            classes.row2,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value="-">
            -
          </CalculatorButton>
        </Box>

        {/* Row 3 */}
        {/* 1 (One) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col1,
            classes.row3,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={1}>
            1
          </CalculatorButton>
        </Box>
        {/* 2 (Two) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col2,
            classes.row3,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={2}>
            2
          </CalculatorButton>
        </Box>
        {/* 3 (Three) */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col3,
            classes.row3,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={3}>
            3
          </CalculatorButton>
        </Box>
        {/* Decimal */}
        <Box
          className={[
            classes.calculatorButtonContainerDoubleTall,
            classes.col4,
            classes.row3,
          ].join(' ')}
        >
          <CalculatorButton
            isSmallText={true}
            onClick={handleCalculatorButtonClick}
            value={strings.labels.clear}
          >
            {strings.labels.clear}
          </CalculatorButton>
        </Box>

        {/* Row 4 */}
        {/* 0 (Zero) */}
        <Box
          className={[
            classes.calculatorButtonContainerDoubleWide,
            classes.col1,
            classes.row4,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value={0}>
            0
          </CalculatorButton>
        </Box>
        {/* Decimal */}
        <Box
          className={[
            classes.calculatorButtonContainer,
            classes.col3,
            classes.row4,
          ].join(' ')}
        >
          <CalculatorButton onClick={handleCalculatorButtonClick} value=".">
            .
          </CalculatorButton>
        </Box>

        {/* Row 5 */}
        {/* Half */}
        <Box
          className={[
            classes.calculatorButtonContainerDoubleWide,
            classes.col1,
            classes.row5,
          ].join(' ')}
        >
          <CalculatorButton
            isSmallText={true}
            onClick={handleCalculatorButtonClick}
            value={strings.labels.half}
          >
            {strings.labels.half}
          </CalculatorButton>
        </Box>
        {/* Remainder */}
        <Box
          className={[
            classes.calculatorButtonContainerDoubleWide,
            classes.col3,
            classes.row5,
          ].join(' ')}
        >
          <CalculatorButton
            isSmallText={true}
            onClick={handleCalculatorButtonClick}
            value={strings.labels.remainder}
          >
            {strings.labels.remainder}
          </CalculatorButton>
        </Box>
      </Box>
    );
  }

  /**
   * Represents the filter chips used for the Donor list.
   *
   * @returns {React.ReactElement} The DonorListFilterChips component.
   */
  function DonorListFilterChips() {
    return (
      <Grid className={classes.appPanelFilterLineup} container={true}>
        <Chip
          className={activeFilterListItem === 0 ? 'active' : ''}
          color={activeFilterListItem === 0 ? 'primary' : 'secondary'}
          label="All"
          onClick={() => {
            setActiveFilterListItem(0);
          }}
        />
        <Chip
          className={activeFilterListItem === 1 ? 'active' : ''}
          color={activeFilterListItem === 1 ? 'primary' : 'secondary'}
          label="A-E"
          onClick={() => {
            setActiveFilterListItem(1);
          }}
        />
        <Chip
          className={activeFilterListItem === 2 ? 'active' : ''}
          color={activeFilterListItem === 2 ? 'primary' : 'secondary'}
          label="F-K"
          onClick={() => {
            setActiveFilterListItem(2);
          }}
        />
        <Chip
          className={activeFilterListItem === 3 ? 'active' : ''}
          color={activeFilterListItem === 3 ? 'primary' : 'secondary'}
          label="L-R"
          onClick={() => {
            setActiveFilterListItem(3);
          }}
        />
        <Chip
          className={activeFilterListItem === 4 ? 'active' : ''}
          color={activeFilterListItem === 4 ? 'primary' : 'secondary'}
          label="S-Z"
          onClick={() => {
            setActiveFilterListItem(4);
          }}
        />
      </Grid>
    );
  }

  /**
   * Convenience effect to set the filtered Donor list based on specified letter
   * ranges of the corresponding filter list item selected.
   */
  React.useEffect(() => {
    const letterRanges = {
      1: ['a', 'b', 'c', 'd', 'e'],
      2: ['f', 'g', 'h', 'i', 'j', 'k'],
      3: ['l', 'm', 'n', 'o', 'p', 'q', 'r'],
      4: ['s', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
    };
    setFilteredDonorList(() => {
      switch (activeFilterListItem) {
        case 1:
        case 2:
        case 3:
        case 4:
          return donorList.filter((donor) => {
            return letterRanges[activeFilterListItem].includes(
              donor.donorDisplayName.charAt(0).toLowerCase(),
            );
          });
        default:
          return donorList;
      }
    });
  }, [activeFilterListItem, donorList, setFilteredDonorList]);

  /**
   * Convenience effect to reset donation amount when changing selected project.
   */
  React.useEffect(() => {
    setDonationAmount('0');
  }, [selectedProject]);

  /**
   * Convenience effect to set default selected project once project list loads.
   */
  React.useEffect(() => {
    if (!selectedProject && projectList?.length) {
      setSelectedProject(projectList[0]);
    }
  }, [projectList, selectedProject, setSelectedProject]);

  return (
    <>
      {strings &&
      donorList &&
      donorList.length &&
      projectList &&
      projectList.length &&
      selectedProject ? (
        <>
          {!user ? (
            <SignInView
              onBackdropUpdate={onBackdropUpdate}
              onUserAuthenticated={onUserAuthenticated}
            />
          ) : (
            <>
              <Grid className={classes.appWrapper}>
                <Grid className={classes.appContainer} container={true}>
                  <Grid className={classes.appPanels}>
                    {/* Left Panel */}
                    <Grid className={classes.appPanelLeft} item={true}>
                      <DonorListFilterChips />
                      <List
                        className={[classes.appDonorList, classes.flex1].join(
                          ' ',
                        )}
                        key="donor-list"
                      >
                        {filteredDonorList.map((donor, index) => {
                          const isSelected = Boolean(
                            selectedDonor &&
                              selectedDonor.donorID === donor.donorID,
                          );
                          return (
                            <ListItem
                              // eslint-disable-next-line jsx-a11y/no-autofocus
                              autoFocus={true}
                              className={classes.appDonorListItem}
                              key={`donor-${donor.donorID}-${index}`}
                              onClick={(event) =>
                                handleDonorListItemClick(event, donor)
                              }
                              ref={donorRefs[donor.donorID]}
                              selected={isSelected}
                            >
                              <ListItemText
                                classes={{
                                  primary: isSelected
                                    ? [
                                        classes.appDonorListItemText,
                                        classes.textTruncate,
                                        'Mui-selected',
                                      ].join(' ')
                                    : [
                                        classes.appDonorListItemText,
                                        classes.textTruncate,
                                      ].join(' '),
                                }}
                                primary={donor.donorDisplayName}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                      <Button
                        className={[
                          classes.appFormButton,
                          classes.contentCenter,
                          classes.mt1,
                        ].join(' ')}
                        color="secondary"
                        onClick={handleAddDonorClick}
                        startIcon={<AddCircleIcon />}
                        variant="contained"
                      >
                        {strings.labels.new_donor}
                      </Button>
                    </Grid>

                    {/* Right Panel */}
                    <Grid className={classes.appPanelRightOuterWrapper}>
                      {/* Top Panel */}
                      <Grid className={classes.appPanelTop} item={true}>
                        {isSmall || isPortraitMode ? (
                          <ProjectSelectControl />
                        ) : null}
                        <Grid className={classes.appPanelProjectSelectLabel}>
                          <Typography
                            className={[
                              classes.appPanelProjectName,
                              classes.textTruncate,
                            ].join(' ')}
                          >
                            {selectedProject
                              ? `${selectedProject.projectName} (${selectedProject.projectRegion})`
                              : `${strings.labels.select_project}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Main Right Container */}
                      <Grid className={classes.appPanelRightContainer}>
                        {/* Calculator Panel */}
                        <Grid className={classes.appPanelCenter} item={true}>
                          <Box
                            className={
                              formattedValue.indexOf('-') === -1
                                ? [classes.calculatorDisplay].join(' ')
                                : [
                                    classes.calculatorDisplay,
                                    classes.textRed,
                                  ].join(' ')
                            }
                          >
                            {formattedValue}
                          </Box>
                          <Box className={[classes.fullWidth].join(' ')}>
                            <CalculatorKeypad />
                          </Box>
                        </Grid>

                        {/* Project Donation Info and Action Buttons */}
                        <Grid className={classes.appPanelRight} item={true}>
                          <Grid
                            className={[
                              classes.flexAlignItemsCenter,
                              classes.flexDisplay,
                              classes.flexJustifyContentCenter,
                            ].join(' ')}
                          >
                            <Grid className={classes.pr05} item={true}>
                              <Typography
                                className={classes.appProjectBalanceTitle}
                              >
                                {strings.labels.balance}
                                {':'}
                              </Typography>
                            </Grid>
                            <Grid item={true}>
                              <Typography
                                className={
                                  balance.indexOf('-') >= 0
                                    ? [
                                        classes.appProjectBalanceAmount,
                                        'over',
                                      ].join(' ')
                                    : classes.appProjectBalanceAmount
                                }
                              >
                                {balance.replace(/-/gi, '')}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Button
                            className={[
                              classes.calculatorGoButton,
                              classes.fullWidth,
                              classes.mb2,
                              classes.mt1,
                            ].join(' ')}
                            color="secondary"
                            disabled={Boolean(
                              !selectedProject ||
                                !selectedDonor ||
                                !parseInt(donationAmount, 10),
                            )}
                            endIcon={<PlayArrowIcon />}
                            onClick={handleSubmitDonation}
                            variant="contained"
                          >
                            {strings.labels.go}
                          </Button>
                          <Snackbar
                            anchorOrigin={{
                              horizontal: 'center',
                              vertical: 'top',
                            }}
                            autoHideDuration={3000}
                            onClose={handleSnackbarClose}
                            open={Boolean(snackbarData.open)}
                          >
                            <Alert
                              className={classes.globalShadow}
                              onClose={handleSnackbarClose}
                              severity={snackbarData.severity}
                            >
                              {snackbarData.message}
                            </Alert>
                          </Snackbar>
                        </Grid>
                      </Grid>
                      {!isSmall && !isPortraitMode ? (
                        <ProjectSelectControl />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>

                {/* App Menu */}
                <AppMenu
                  className={classes.appMenu}
                  donorList={donorList}
                  onCloseAuction={onCloseAuction}
                  onFetchAppData={onFetchAppData}
                  onResetApp={onResetApp}
                  onSignOut={onSignOut}
                  onUploadAndSyncData={onUploadAndSyncData}
                />

                {/* New Donor form dialog. */}
                <NewDonorFormDialog
                  apiStatus={apiStatus}
                  onCancelClick={() => {
                    setIsNewDonorDialogOpen(false);
                  }}
                  onClose={() => {
                    setIsNewDonorDialogOpen(false);
                  }}
                  onSubmit={(data) => {
                    setIsNewDonorDialogOpen(false);
                    onNewDonorSubmit(data);
                  }}
                  open={Boolean(isNewDonorDialogOpen)}
                />

                {/* Generic confirmation dialog. */}
                <ConfirmationDialog
                  confirmLabel={strings.labels.ok}
                  message={genericDialogData.message}
                  onCancelClick={() => {
                    setGenericDialogData(() => {
                      return defaultGenericDialogData;
                    });
                  }}
                  onClose={() => {
                    setGenericDialogData(() => {
                      return defaultGenericDialogData;
                    });
                  }}
                  onConfirmClick={() => {
                    setGenericDialogData(() => {
                      return defaultGenericDialogData;
                    });
                  }}
                  open={Boolean(genericDialogData.open)}
                  title={genericDialogData.title}
                />
              </Grid>
            </>
          )}
        </>
      ) : null}
    </>
  );
}

HomeView.propTypes = {
  apiStatus: PropTypes.string,
  donorList: PropTypes.array,
  onBackdropUpdate: PropTypes.func,
  onCloseAuction: PropTypes.func,
  onFetchAppData: PropTypes.func,
  onNewDonorSubmit: PropTypes.func,
  onResetApp: PropTypes.func,
  onSignOut: PropTypes.func,
  onUploadAndSyncData: PropTypes.func,
  onUserAuthenticated: PropTypes.func,
  projectList: PropTypes.array,
  user: PropTypes.object,
};

HomeView.defaultProps = {
  apiStatus: STATUS_TYPES.IDLE,
  onBackdropUpdate: () => {},
  onCloseAuction: () => {},
  onFetchAppData: () => {},
  onNewDonorSubmit: () => {},
  onResetApp: () => {},
  onSignOut: () => {},
  onUploadAndSyncData: () => {},
  onUserAuthenticated: () => {},
  user: null,
};
