/**
 * @module MessengerInternationalApiMcupAuctionAppDonorsDonor
 */
import { apiClient } from 'api/core';
import {
  MI_API_BASE_URL,
  MI_API_REQUEST_OPTIONS,
} from 'api/messengerinternational/constants';

/**
 * Function to mark the specified donor as paid.
 *
 * @param {object} params - The function parameters.
 * @param {number|string} params.donorId - The donor id.
 *
 * @throws {Error} - Throws an error if `donorId` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function markAsPaid({ donorId }) {
  if (
    !donorId ||
    (typeof donorId !== 'string' && typeof donorId !== 'number')
  ) {
    throw new Error(
      'Sorry, there was a problem validating the donor id provided.',
    );
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      donor_id: donorId,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/donors/donor/paid`,
  });
}

/**
 * Function to mark the specified donor for review.
 *
 * @param {object} params - The function parameters.
 * @param {number|string} params.donorId - The donor id.
 *
 * @throws {Error} - Throws an error if `donorId` is not provided or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function markForReview({ donorId }) {
  if (
    !donorId ||
    (typeof donorId !== 'string' && typeof donorId !== 'number')
  ) {
    throw new Error(
      'Sorry, there was a problem validating the donor id provided.',
    );
  }

  return apiClient.post({
    options: {
      ...MI_API_REQUEST_OPTIONS,
    },
    params: {
      donor_id: donorId,
    },
    url: `${MI_API_BASE_URL}/mcup-auction-app/donors/donor/review`,
  });
}
