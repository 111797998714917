/**
 * @module App
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  CssBaseline,
  LinearProgress,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import * as mCupAuctionAppApi from "api/messengerinternational/mcup-auction-app";
import { ConfirmationDialog } from "components/ui/dialogs";
import { useLocale } from "context/locale";
import { AuctionApp } from "views/app";
import { AuctionWebsite } from "views/website";
import {
  APP_VERSION,
  EMPTY_PLEDGES,
  IS_APP,
  LOCAL_STORAGE_KEYS,
  SENTRY_DSN,
  STATUS_TYPES,
  USER_STATUS_EXPIRY_TTL,
} from "utils/constants";
import {
  apiResponseComplete,
  clearMiLocalStorageItems,
  generateLocalDonorId,
  getArrayObjectByAttribute,
  getLocalStorageItem,
  useLocalStorageState,
} from "utils/helpers";
import { theme, useStyles } from "utils/styles";

/**
 * The App view component.
 *
 * @returns {React.ReactElement} - The App view component.
 */
export default function App() {
  const [apiStatus, setApiStatus] = React.useState(STATUS_TYPES.IDLE);
  const [backdropProps, setBackdropProps] = React.useState({
    open: false,
    statusMessage: "Fetching data...",
    value: 0,
  });
  const defaultGenericDialogData = {
    message: "",
    open: false,
    title: "",
  };
  // Array of donations objects. Each donation should have project number and
  // donation amount.
  const [, setAllDonations] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.appDonations,
    getLocalStorageItem(LOCAL_STORAGE_KEYS.appDonations) || {}
  );
  const [donorList, setDonorList] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.donorsList,
    []
  );
  // Object of arrays of pledges for projects, with attributes of project
  // number for each project array of pledges.
  const [pledgesForProjectsList, setPledgesForProjectsList] =
    useLocalStorageState(LOCAL_STORAGE_KEYS.pledgesForProjectsList, {});
  const [projectList, setProjectList] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.projectsList,
    []
  );
  const [error, setError] = React.useState(null);
  const [genericDialogData, setGenericDialogData] = React.useState(
    defaultGenericDialogData
  );
  const [isSignOutDialogOpen, setIsSignOutDialogOpen] = React.useState(false);
  const [user, setUser] = useLocalStorageState(LOCAL_STORAGE_KEYS.user, null);

  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { strings } = useLocale();

  /**
   * Handler function to update backdrop props and state.
   *
   * @param {object} props - The backdrop props to use for updating state.
   * @param {boolean} [props.open] - Boolean flag for whether or not the backdrop should be open.
   * @param {string} [props.statusMessage] - String message value used as a message for the backdrop.
   * @param {number|string} [props.value] - Value to display as the value of retrieval.
   */
  function handleBackdropUpdate({
    open = false,
    statusMessage = "",
    value = 0,
  }) {
    setBackdropProps({
      open,
      statusMessage,
      value,
    });
  }

  /**
   * Handler function for error events.
   *
   * @param {Error} errorObject - The Error object to set as error state data.
   */
  function handleError(errorObject) {
    setError(errorObject);
  }

  /**
   * Handler function for user sign out.
   */
  function handleResetApp() {
    clearMiLocalStorageItems();
    window.location.reload();
  }

  /**
   * Handler function for user sign out.
   */
  function handleSignOut() {
    setIsSignOutDialogOpen(true);
  }

  /**
   * Handler function to set User state data after successful authentication.
   *
   * @param {User} userData - The authenticated User data object.
   */
  function handleUserAuthenticated(userData) {
    if (!user) {
      setUser(userData);
    }
  }

  /**
   * Convenience function to log out user.
   */
  async function logOutUser() {
    setUser(null);
    clearMiLocalStorageItems();
    navigate("/");
  }

  /**
   * Function to fetch app data and populate to local storage.
   *
   * @param {object} [options] - Additional options object.
   * @param {Function} [options.callback] - An optional callback function to invoke when data retrieval is complete.
   * @param {boolean} [options.hideBackdrop] - Boolean flag for whether or not to hide backdrop.
   * @param {boolean} [options.silentFail] - Boolean flag for whether or not to allow silent failure or give UI feedback.
   */
  async function fetchAppData(
    { callback, hideBackdrop, silentFail } = {
      callback: null,
      hideBackdrop: false,
      silentFail: false,
    }
  ) {
    if (!hideBackdrop) {
      handleBackdropUpdate({
        open: true,
        statusMessage: strings?.status_messages?.fetching_data || "",
        value: 0,
      });
    }
    setApiStatus(STATUS_TYPES.PENDING);
    try {
      const donorListResponse = await mCupAuctionAppApi.donors.getDonors();
      const projectListResponse =
        await mCupAuctionAppApi.projects.getProjects();
      if (
        apiResponseComplete(donorListResponse, "array") &&
        apiResponseComplete(projectListResponse, "array")
      ) {
        // Make sure there is donor and project list data.
        // Order donors for alphabetization.
        if (donorListResponse.data && projectListResponse.data) {
          const allDonors = [];
          donorListResponse.data.forEach((donor) => {
            const newDonorObject = { ...donor };
            newDonorObject.localDonorID = generateLocalDonorId(donor);
            allDonors.push(newDonorObject);
          });
          setDonorList(
            allDonors.sort((a, b) =>
              a.donorDisplayName > b.donorDisplayName ? 1 : -1
            )
          );
          setProjectList(projectListResponse.data);
          setApiStatus(STATUS_TYPES.RESOLVED);
          if (callback) {
            callback();
          }
        } else {
          setApiStatus(STATUS_TYPES.ERROR);
          if (!silentFail) {
            if (donorListResponse.errors && donorListResponse.errors[0]) {
              handleError(donorListResponse.errors[0]);
            } else if (
              projectListResponse.errors &&
              projectListResponse.errors[0]
            ) {
              handleError(projectListResponse.errors[0]);
            }
          }
          if (callback) {
            callback();
          }
        }
      } else {
        const fetchError = new Error(
          donorListResponse.message || projectListResponse.message
        );
        setApiStatus(STATUS_TYPES.ERROR);
        if (!silentFail) {
          handleError(fetchError);
        }
        if (callback) {
          callback();
        }
      }
      if (!hideBackdrop) {
        handleBackdropUpdate({
          open: false,
          statusMessage: strings?.status_messages?.fetching_data || "",
          value: 0,
        });
      }
    } catch (dataFetchError) {
      if (!hideBackdrop) {
        handleBackdropUpdate({
          open: false,
          statusMessage: strings?.status_messages?.fetching_data || "",
          value: 0,
        });
      }
      setApiStatus(STATUS_TYPES.ERROR);
      Sentry.captureException(dataFetchError);
      if (!silentFail) {
        handleError(dataFetchError);
      }
      if (callback) {
        callback();
      }
    }
  }

  /**
   * Function to fetch app data and populate to local storage.
   *
   * @param {object} [options] - Additional options object.
   * @param {Function} [options.callback] - An optional callback function to invoke when data retrieval is complete.
   * @param {boolean} [options.hideBackdrop] - Boolean flag for whether or not to hide backdrop.
   * @param {boolean} [options.projectNumber] - The project number.
   * @param {boolean} [options.silentFail] - Boolean flag for whether or not to allow silent failure or give UI feedback.
   */
  async function fetchPledgesForProject(
    { callback, hideBackdrop, projectNumber, silentFail } = {
      callback: null,
      hideBackdrop: false,
      projectNumber: null,
      silentFail: false,
    }
  ) {
    if (
      !projectNumber ||
      (typeof projectNumber !== "number" && typeof projectNumber !== "string")
    ) {
      if (callback) {
        callback({
          message:
            strings?.messages?.errors?.pledges_for_project ||
            "Sorry, pledges for project could not be fetched. Please provide a valid project number and try again.",
          success: false,
        });
      }
    } else {
      if (!hideBackdrop) {
        handleBackdropUpdate({
          open: true,
          statusMessage: strings?.status_messages?.fetching_data || "",
          value: 0,
        });
      }
      setApiStatus(STATUS_TYPES.PENDING);
      // setPledgesForProjectsList
      try {
        const pledgesForProjectResponse =
          await mCupAuctionAppApi.pledges.project.getPledgesByProject({
            projectNumber,
          });
        if (apiResponseComplete(pledgesForProjectResponse, "array")) {
          // Make sure there is pledges for projects list data.
          if (
            pledgesForProjectResponse.data &&
            pledgesForProjectResponse.data
          ) {
            // Always start with empty pledges to ensure lead-in time and full
            // board of data.
            const newData = {
              [projectNumber]: [
                ...EMPTY_PLEDGES,
                ...pledgesForProjectResponse.data.sort((a, b) =>
                  a.pledgeID < b.pledgeID ? -1 : 1
                ),
              ],
            };
            setPledgesForProjectsList((prevList) => {
              return {
                ...prevList,
                [projectNumber]: [
                  ...EMPTY_PLEDGES,
                  ...pledgesForProjectResponse.data.sort((a, b) =>
                    a.pledgeID < b.pledgeID ? -1 : 1
                  ),
                ],
              };
            });
            setApiStatus(STATUS_TYPES.RESOLVED);
            if (callback) {
              callback(newData);
            }
          } else {
            setApiStatus(STATUS_TYPES.ERROR);
            if (!silentFail) {
              if (
                pledgesForProjectResponse.errors &&
                pledgesForProjectResponse.errors[0]
              ) {
                handleError(pledgesForProjectResponse.errors[0]);
              }
            }
            if (callback) {
              callback();
            }
          }
        } else {
          const fetchError = new Error(pledgesForProjectResponse.message);
          setApiStatus(STATUS_TYPES.ERROR);
          if (!silentFail) {
            handleError(fetchError);
          }
          if (callback) {
            callback();
          }
        }
        if (!hideBackdrop) {
          handleBackdropUpdate({
            open: false,
            statusMessage: strings?.status_messages?.fetching_data || "",
            value: 0,
          });
        }
      } catch (dataFetchError) {
        if (!hideBackdrop) {
          handleBackdropUpdate({
            open: false,
            statusMessage: strings?.status_messages?.fetching_data || "",
            value: 0,
          });
        }
        setApiStatus(STATUS_TYPES.ERROR);
        Sentry.captureException(dataFetchError);
        if (!silentFail) {
          handleError(dataFetchError);
        }
        if (callback) {
          callback();
        }
      }
    }
  }

  /**
   * Handler function to upload and sync pledge data.
   *
   * @param {object} [options] - The optional function parameters.
   * @param {Function} [options.callback] - Optional callback function to invoke upon completion.
   * @param {boolean} [options.hideBackdrop] - Boolean flag for whether or not to hide backdrop.
   * @param {boolean} [options.silentFail] - Boolean flag for whether or not to allow silent failure or give UI feedback.
   */
  function uploadAndSyncPledges(
    { callback, hideBackdrop, silentFail } = {
      callback: null,
      hideBackdrop: false,
      silentFail: false,
    }
  ) {
    const storedPledges = getLocalStorageItem(LOCAL_STORAGE_KEYS.appDonations);
    const storedDonors = getLocalStorageItem(LOCAL_STORAGE_KEYS.donorsList);
    const pledgesToSubmit = [];
    Object.values(storedPledges).forEach((pledge) => {
      const newPledgeItem = { ...pledge };
      const arrayObject = getArrayObjectByAttribute({
        array: storedDonors,
        attribute: "localDonorID",
        attributeValue: pledge.localDonorID,
      });
      if (arrayObject) {
        newPledgeItem.donorID = arrayObject.donorID || null;
        pledgesToSubmit.push(newPledgeItem);
      }
      setAllDonations((prevDonations) => {
        return {
          ...prevDonations,
          [newPledgeItem.pledgeID]: {
            ...newPledgeItem,
          },
        };
      });
    });
    const filteredPledges = pledgesToSubmit.filter(
      (pledge) => !pledge.isSynced
    );

    if (!filteredPledges || !filteredPledges.length) {
      // If no pledges left to sync, resolve API status. Otherwise, proceed.
      setApiStatus(STATUS_TYPES.RESOLVED);
      handleBackdropUpdate({
        open: false,
        statusMessage: strings?.status_messages?.saving_data || "",
        value: 0,
      });
      if (callback) {
        callback({
          message:
            strings?.messages?.success?.upload_sync_data ||
            "All data successfully uploaded and synced.",
          success: true,
        });
      }
    } else {
      const promisesData = [];
      filteredPledges.forEach((newPledgeEntry) => {
        promisesData.push(newPledgeEntry);
      });
      if (!hideBackdrop) {
        handleBackdropUpdate({
          open: true,
          statusMessage: strings?.status_messages?.saving_data || "",
          value: 0,
        });
      }
      setApiStatus(STATUS_TYPES.PENDING);
      Promise.allSettled(
        promisesData.map((data) =>
          mCupAuctionAppApi.pledges.createPledge({
            ...data,
            deviceName: "manual",
          })
        )
      )
        // Do not assume all promises are fulfilled. Just because the code in the
        // `then` block is reached, one or more of the Promises may have a status
        // that is `rejected`, in which case there is a need to show the user a
        // message that an issue came up saving the entries. To do this, iterate
        // over the responses and ensure the status is not `rejected` and that
        // the response is complete via the helper method `apiResponseComplete`.
        .then((responses) => {
          let allFulfilled = true;
          // Create new blank donations array that will be populated as each of
          // the promises is fulfilled and its result parsed.
          responses.forEach((response) => {
            const storedDonations = getLocalStorageItem(
              LOCAL_STORAGE_KEYS.appDonations
            );
            const promiseError =
              response.status.toLowerCase() === "rejected" ||
              (response.value.errors && response.value.errors.length > 0);
            if (promiseError) {
              allFulfilled = false;
            } else {
              const localPledgeID =
                response.value.data && response.value.data.length
                  ? response.value.data[0].localPledgeID
                  : "";
              // Set synced status to true on stored pledge.
              // eslint-disable-next-line no-restricted-syntax
              for (const [storedPledgeID, storedPledge] of Object.entries(
                storedDonations
              )) {
                // Do not think comparing stored pledge id and pledge id is
                // necessary, as they do not seem to jive.
                if (storedPledgeID === localPledgeID) {
                  // eslint-disable-next-line no-loop-func
                  setAllDonations((prevDonations) => {
                    return {
                      ...prevDonations,
                      [storedPledge.pledgeID]: {
                        ...storedPledge,
                        isSynced: true,
                      },
                    };
                  });
                }
              }
            }
          });
          if (allFulfilled) {
            setApiStatus(STATUS_TYPES.RESOLVED);
            handleBackdropUpdate({
              open: false,
              statusMessage: strings?.status_messages?.saving_data || "",
              value: 0,
            });
            fetchAppData({
              callback: () => {
                if (callback) {
                  callback({
                    message:
                      strings?.messages?.success?.upload_sync_data ||
                      "All data successfully uploaded and synced.",
                    success: true,
                  });
                }
                if (!silentFail) {
                  setGenericDialogData({
                    message:
                      strings?.messages?.success?.upload_sync_data ||
                      "All data successfully uploaded and synced.",
                    open: true,
                    value: 0,
                  });
                }
              },
              hideBackdrop,
              silentFail,
            });
          } else {
            setApiStatus(STATUS_TYPES.ERROR);
            handleBackdropUpdate({
              open: false,
              statusMessage: strings?.status_messages?.saving_data || "",
              value: 0,
            });
            if (!silentFail) {
              handleError(
                new Error(
                  strings?.messages?.errors?.upload_sync_data_pledges ||
                    "Sorry, but there was an error uploading and syncing pledges. Please try again."
                )
              );
            }
            fetchAppData({ callback, hideBackdrop, silentFail });
          }
        })
        .catch((apiError) => {
          handleBackdropUpdate({
            open: false,
            statusMessage: strings?.status_messages?.saving_data || "",
            value: 0,
          });
          fetchAppData({ callback, hideBackdrop, silentFail });
          setApiStatus(STATUS_TYPES.ERROR);
          if (!silentFail) {
            Sentry.captureException(apiError);
            handleError(apiError);
          }
        });
    }
  }

  /**
   * Handler function to upload and sync app data.
   *
   * @param {object} [options] - The optional function parameters.
   * @param {Function} [options.callback] - Optional callback function to invoke upon completion.
   * @param {boolean} [options.hideBackdrop] - Boolean flag for whether or not to hide backdrop.
   * @param {boolean} [options.silentFail] - Boolean flag for whether or not to allow silent failure or give UI feedback.
   */
  function handleUploadAndSyncData(
    { callback, hideBackdrop, silentFail } = {
      callback: null,
      hideBackdrop: false,
      silentFail: false,
    }
  ) {
    const filteredDonors = donorList
      ? donorList.filter((donor) => donor.donorID === null)
      : [];
    // Get stored pledges/donations. Later, create a new array and iterate over
    // stored values, pushing items to new array with `donorID` from the master
    // list of donors now that all donors have a legit `donorID` value.
    const storedPledges = getLocalStorageItem(LOCAL_STORAGE_KEYS.appDonations);
    const filteredPledges = Object.values(storedPledges).filter(
      (pledge) => !pledge.isSynced
    );

    if (
      (!filteredDonors || !filteredDonors.length) &&
      (!filteredPledges || !filteredPledges.length)
    ) {
      if (callback) {
        callback({
          message:
            strings?.dialogs?.messages?.upload_sync_no_data ||
            "The app data is already fully up to date, and there is nothing needing to upload and sync.",
          success: true,
        });
      } else if (!silentFail) {
        setGenericDialogData({
          message:
            strings?.dialogs?.messages?.upload_sync_no_data ||
            "The app data is already fully up to date, and there is nothing needing to upload and sync.",
          open: true,
          title: "",
        });
      }
    } else if (!filteredDonors || !filteredDonors.length) {
      // If no donors to sync, proceed with pledges.
      uploadAndSyncPledges({ callback, hideBackdrop, silentFail });
    } else {
      const promisesData = [];
      filteredDonors.forEach((newDonorEntry) => {
        promisesData.push(newDonorEntry);
      });
      if (!hideBackdrop) {
        handleBackdropUpdate({
          open: true,
          statusMessage: strings?.status_messages?.saving_data || "",
          value: 0,
        });
      }
      setApiStatus(STATUS_TYPES.PENDING);
      Promise.allSettled(
        promisesData.map((data) => mCupAuctionAppApi.donors.createDonor(data))
      )
        // Do not assume all promises are fulfilled. Just because the code in the
        // `then` block is reached, one or more of the Promises may have a status
        // that is `rejected`, in which case there is a need to show the user a
        // message that an issue came up saving the entries. To do this, iterate
        // over the responses and ensure the status is not `rejected` and that
        // the response is complete via the helper method `apiResponseComplete`.
        .then((responses) => {
          let allFulfilled = true;
          responses.forEach((response) => {
            if (
              response.status.toLowerCase() === "rejected" ||
              !apiResponseComplete(response.value, "array")
            ) {
              allFulfilled = false;
            }
          });
          if (allFulfilled) {
            setApiStatus(STATUS_TYPES.RESOLVED);
            handleBackdropUpdate({
              open: false,
              statusMessage: strings?.status_messages?.saving_data || "",
              value: 0,
            });
            // Now that donors are synced, upload and sync pledges on callback
            // of fresh app data fetch.
            fetchAppData({
              callback: () => {
                uploadAndSyncPledges({ callback, hideBackdrop, silentFail });
              },
              hideBackdrop,
              silentFail,
            });
          } else {
            setApiStatus(STATUS_TYPES.ERROR);
            handleBackdropUpdate({
              open: false,
              statusMessage: strings?.status_messages?.saving_data || "",
              value: 0,
            });
            if (!silentFail) {
              handleError(
                new Error(
                  strings?.messages?.errors?.upload_sync_data_donors ||
                    "Sorry, but there was an error uploading and syncing donors. Please try again."
                )
              );
            }
            fetchAppData({
              hideBackdrop,
              silentFail,
            });
          }
        })
        .catch((apiError) => {
          handleBackdropUpdate({
            open: false,
            statusMessage: strings?.status_messages?.saving_data || "",
            value: 0,
          });
          fetchAppData({ callback, hideBackdrop, silentFail });
          setApiStatus(STATUS_TYPES.ERROR);
          Sentry.captureException(apiError);
          if (!silentFail) {
            handleError(apiError);
          }
        });
    }
  }

  /**
   * Handler function for update user form submit click.
   *
   * @param {object} data - The new donor data object.
   */
  async function handleNewDonorFormSubmit(data) {
    // Save to local state, both in donor and new donors lists.
    const newDonorDataObject = {
      donorDisplayName: `${data.last_name}, ${data.first_name}`,
      donorID: null,
      firstName: data.first_name,
      lastName: data.last_name,
      localDonorID: generateLocalDonorId({
        donorDisplayName: `${data.last_name}, ${data.first_name}`,
        firstName: data.first_name,
        lastName: data.last_name,
      }),
      mobilePhone: "+0",
    };
    const newDonorList = [...donorList, newDonorDataObject];
    setDonorList(
      newDonorList.sort((a, b) =>
        a.donorDisplayName > b.donorDisplayName ? 1 : -1
      )
    );
  }

  /**
   * Handler function to trigger API calls to send final donation messages to donors.
   *
   * @param {Array<SmsListItem>} smsListData - Array of SMSListItem data objects.
   */
  async function sendDonorSummaryMessages(smsListData) {
    if (!smsListData || !smsListData.length) {
      setGenericDialogData({
        message:
          strings?.dialogs?.messages?.sms_list_messages_blank ||
          "No donors found on final SMS list.",
        open: true,
        title: "",
      });
    } else {
      const promisesData = [];
      smsListData.forEach((smsListItem) => {
        promisesData.push(smsListItem);
      });
      handleBackdropUpdate({
        open: true,
        statusMessage: strings?.status_messages?.submitting || "",
        value: 0,
      });
      setApiStatus(STATUS_TYPES.PENDING);
      Promise.allSettled(
        promisesData.map((data) => mCupAuctionAppApi.sms.sendSmsMessage(data))
      )
        // Do not assume all promises are fulfilled. Just because the code in the
        // `then` block is reached, one or more of the Promises may have a status
        // that is `rejected`, in which case there is a need to show the user a
        // message that an issue came up saving the entries. To do this, iterate
        // over the responses and ensure the status is not `rejected` and that
        // the response is complete via the helper method `apiResponseComplete`.
        .then((responses) => {
          let allFulfilled = true;
          responses.forEach((response) => {
            const promiseError =
              response.status.toLowerCase() === "rejected" ||
              (response.value.errors && response.value.errors.length > 0);
            if (promiseError) {
              allFulfilled = false;
            }
          });
          if (allFulfilled) {
            setApiStatus(STATUS_TYPES.RESOLVED);
            handleBackdropUpdate({
              open: false,
              statusMessage: strings?.status_messages?.submitting || "",
              value: 0,
            });
            // Show success message!
            setGenericDialogData({
              message:
                strings?.dialogs?.messages?.auction_closed ||
                "The auction has successfully been closed. All donor and pledge data has been synced and SMS messages have been sent to all donors.",
              open: true,
              title:
                strings?.dialogs?.titles?.auction_closed || "Auction Closed",
            });
          } else {
            setApiStatus(STATUS_TYPES.ERROR);
            handleBackdropUpdate({
              open: false,
              statusMessage: strings?.status_messages?.submitting || "",
              value: 0,
            });
            handleError(
              new Error(
                strings?.messages?.errors?.sms_list_messages ||
                  "Sorry, but there was an error sending SMS messages to donors. Please try again."
              )
            );
            fetchAppData();
          }
        })
        .catch((apiError) => {
          handleBackdropUpdate({
            open: false,
            statusMessage: strings?.status_messages?.saving_data || "",
            value: 0,
          });
          fetchAppData();
          setApiStatus(STATUS_TYPES.ERROR);
          Sentry.captureException(apiError);
          handleError(apiError);
        });
    }
  }

  /**
   * Handler function for auction close request. The logic of this function is
   * to trigger upload and sync of all local data, then call the API endpoint to
   * get the final SMS list and send messages to the donors.
   */
  async function handleCloseAuction() {
    handleUploadAndSyncData({
      // eslint-disable-next-line no-unused-vars
      callback: async ({ message, success }) => {
        handleBackdropUpdate({
          open: true,
          statusMessage: strings?.status_messages?.fetching_data || "",
          value: 0,
        });
        setApiStatus(STATUS_TYPES.PENDING);
        try {
          const smsListResponse = await mCupAuctionAppApi.sms.getSmsList();
          handleBackdropUpdate({
            open: false,
            statusMessage: strings?.status_messages?.fetching_data || "",
            value: 0,
          });
          if (apiResponseComplete(smsListResponse, "array")) {
            setApiStatus(STATUS_TYPES.RESOLVED);
            sendDonorSummaryMessages(smsListResponse.data);
          } else {
            const smsListError = new Error(smsListResponse.message);
            setApiStatus(STATUS_TYPES.ERROR);
            handleError(smsListError);
          }
          return null;
        } catch (smsError) {
          handleBackdropUpdate({
            open: false,
            statusMessage: strings?.status_messages?.fetching_data || "",
            value: 0,
          });
          setApiStatus(STATUS_TYPES.ERROR);
          handleError(smsError);
          Sentry.captureException(smsError);
          return null;
        }
      },
    });
  }

  /**
   * Effect to handle background upload and sync when donors list updates.
   */
  React.useEffect(() => {
    handleUploadAndSyncData({ hideBackdrop: true, silentFail: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run function to kick off Sentry initialization.
   */
  React.useEffect(() => {
    if (SENTRY_DSN) {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment:
          process.env.NODE_ENV === "production" ? "production" : "staging",
        release: `faith-story-app@${APP_VERSION}`,
        tracesSampleRate: 1.0,
      });
    }
  }, []);

  /**
   * Single-run convenience effect to confirm stored user is valid.
   */
  React.useEffect(() => {
    const now = new Date();
    if (user && now.getTime() < user.expires * 1000) {
      setUser((prevUser) => {
        return {
          ...prevUser,
          expires: now.getTime() + USER_STATUS_EXPIRY_TTL,
        };
      });
    } else {
      setUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run convenience effect to add class to body.
   */
  React.useEffect(() => {
    document.body.classList.add(IS_APP ? "app" : "website");
  }, []);

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {strings ? (
          <>
            {IS_APP ? (
              <AuctionApp
                apiStatus={apiStatus}
                className={classes.appWrapperContainer}
                donorList={donorList}
                onBackdropUpdate={handleBackdropUpdate}
                onCloseAuction={handleCloseAuction}
                onError={handleError}
                onFetchAppData={fetchAppData}
                onNewDonorSubmit={(data) => {
                  handleNewDonorFormSubmit(data);
                }}
                onResetApp={handleResetApp}
                onSignOut={handleSignOut}
                onUploadAndSyncData={handleUploadAndSyncData}
                onUserAuthenticated={handleUserAuthenticated}
                projectList={projectList}
                user={user}
              />
            ) : (
              <AuctionWebsite
                apiStatus={apiStatus}
                className={classes.appWrapperContainer}
                donorList={donorList}
                onBackdropUpdate={handleBackdropUpdate}
                onError={handleError}
                onFetchAppData={fetchAppData}
                onFetchPledgesForProject={fetchPledgesForProject}
                onNewDonorSubmit={(data) => {
                  handleNewDonorFormSubmit(data);
                }}
                onResetApp={handleResetApp}
                onSignOut={handleSignOut}
                onUserAuthenticated={handleUserAuthenticated}
                pledgesForProjectsList={pledgesForProjectsList}
                projectList={projectList}
                user={user}
              />
            )}

            {/* Error dialog confirmation. */}
            {error ? (
              <ConfirmationDialog
                confirmLabel={strings.labels.ok}
                message={error.message || ""}
                onClose={() => {
                  setError(null);
                }}
                onConfirmClick={() => {
                  setError(null);
                }}
                open={Boolean(error)}
              />
            ) : null}

            {/* Generic confirmation dialog. */}
            <ConfirmationDialog
              confirmLabel={strings.labels.ok}
              message={genericDialogData.message}
              onCancelClick={() => {
                setGenericDialogData(() => {
                  return defaultGenericDialogData;
                });
              }}
              onClose={() => {
                setGenericDialogData(() => {
                  return defaultGenericDialogData;
                });
              }}
              onConfirmClick={() => {
                setGenericDialogData(() => {
                  return defaultGenericDialogData;
                });
              }}
              open={Boolean(genericDialogData.open)}
              title={genericDialogData.title}
            />

            {/* Sign Out dialog. */}
            <ConfirmationDialog
              cancelLabel={strings.labels.cancel}
              confirmLabel={strings.labels.yes}
              message={strings.dialogs.messages.sign_out}
              onCancelClick={() => {
                setIsSignOutDialogOpen(false);
              }}
              onClose={() => {
                setIsSignOutDialogOpen(false);
              }}
              onConfirmClick={() => {
                setIsSignOutDialogOpen(false);
                logOutUser();
              }}
              open={Boolean(isSignOutDialogOpen)}
              title={strings.dialogs.titles.sign_out}
            />

            {/* Global backdrop overlay. */}
            <Backdrop className={classes.backdrop} open={backdropProps.open}>
              <Typography className={classes.backdropStatusMessage}>
                {backdropProps.statusMessage}
              </Typography>
              <LinearProgress
                className={classes.backdropLinearProgress}
                color="secondary"
                value={backdropProps.value}
                variant="indeterminate"
              />
            </Backdrop>
          </>
        ) : null}
      </MuiThemeProvider>
    </>
  );
}
