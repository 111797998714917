/* eslint-disable jsx-a11y/media-has-caption */
/**
 * @module HomeView
 */
import React from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Slide,
  Typography,
} from "@material-ui/core";
import { useLocale } from "context/locale";
import { useStyles } from "utils/styles";
import { COLORS, LOCAL_STORAGE_KEYS, STATUS_TYPES } from "utils/constants";
import {
  formatUSD,
  getArrayObjectByAttribute,
  useLocalStorageState,
} from "utils/helpers";
import { SignInView } from "../common/sign-in";

/**
 * Represents the Auction Website Home view.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.apiStatus] - The API status, to help determine the state of API calls.
 * @param {Array} props.donorList - The list of donors.
 * @param {Function} [props.onBackdropUpdate] - Function to call to update the backdrop properties state.
 * @param {Function} [props.onFetchAppData] - Handler function for data fetch request.
 * @param {Function} [props.onFetchPledgesForProject] - Handler function for pledges for project fetch request.
 * @param {Function} [props.onResetApp] - Handler function for app reset request.
 * @param {Function} [props.onUserAuthenticated] - The handler function for user authentication success.
 * @param {Array} props.pledgesForProjectsList - The list of pledges for projects.
 * @param {Array} props.projectList - The list of projects.
 * @param {User} [props.user] - The User data object.
 *
 * @returns {React.ReactElement} - The HomeView view component.
 */
export function HomeView({
  // eslint-disable-next-line no-unused-vars
  apiStatus,
  // eslint-disable-next-line no-unused-vars
  donorList,
  onBackdropUpdate,
  onFetchAppData,
  onFetchPledgesForProject,
  onResetApp,
  // eslint-disable-next-line no-unused-vars
  onUserAuthenticated,
  // eslint-disable-next-line no-unused-vars
  pledgesForProjectsList,
  // eslint-disable-next-line no-unused-vars
  projectList,
  // eslint-disable-next-line no-unused-vars
  user,
}) {
  const classes = useStyles();
  const { strings } = useLocale();
  const donorTickerRef = React.useRef(null);

  const [apiIntervalCount, setApiIntervalCount] = React.useState(0);
  const [configData, setConfigData] = React.useState(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = React.useState(false);
  const [initialPledgesRetrieved, setInitialPledgesRetrieved] =
    React.useState(false);
  const [lastFiveDonorsAndPledges, setLastFiveDonorsAndPledges] =
    useLocalStorageState(LOCAL_STORAGE_KEYS.lastFiveDonorsAndPledges, []); // React.useState([]);
  const nextPledgeItemForScroll =
    donorTickerRef?.current?.firstChild /* ?.nextSibling */ || null;
  const [pledgesForProject, setPledgesForProject] = useLocalStorageState(
    LOCAL_STORAGE_KEYS.pledgesForProject,
    []
  );
  const [donorPledgesForProject, setDonorPledgesForProject] =
    useLocalStorageState(LOCAL_STORAGE_KEYS.donorPledgesForProject, {});

  // State to hold donor refs for auto-scroll into view for pledges.
  const [pledgeRefs] = React.useState(
    Object.values(donorPledgesForProject || {}).reduce((acc, value) => {
      acc[value.pledgeID] = React.createRef();
      return acc;
    }, {})
  );

  /**
   * Convenience accumulator function to add values of pledges reduce call.
   *
   * @param {Function} accumulator - The accumulator function.
   * @param {object} value - The Pledge object.
   *
   * @returns {number} - The accumulated value.
   */
  function addPledge(accumulator, value) {
    return accumulator + (parseInt(value?.pledgeAmount, 10) || 0);
  }

  // Convenience variable to keep track of cumulative progress amount based on
  // API response data of pledges.
  const [cumulativeProgressAmount, setCumulativeProgressAmount] =
    React.useState(0);

  // Convenience constant, set as a 0 - 100 percentage range of progress.
  let pledgeAmountProgress = configData
    ? (cumulativeProgressAmount / configData.goalAmount) * 100
    : 0;
  // If pledge amount is more than the goal (i.e. more than 100%), set directly
  // as 100% to avoid "over progress" of the progress bar.
  if (pledgeAmountProgress > 100) {
    pledgeAmountProgress = 100;
  }

  // State to keep track of current milestone, which is based on the cumulative
  // progress amount (set above).
  const [currentMilestone, setCurrentMilestone] = React.useState(0);

  const newPledgeAudio = document.getElementById("newPledgeAudio");

  /**
   * Represents a divider line for Progress Bar Milestones.
   *
   * @param {object} props - The component props object.
   * @param {string} props.label - The label associated with the milestone.
   * @param {number} props.position - Integer-based position value at which to set the left value in the component style.
   *
   * @returns {React.ReactElement} The ProgressMilestoneLine component.
   */
  function ProgressMilestoneLine({ label, position }) {
    const numMilestones = configData.milestones.length;
    return (
      <Grid
        className={classes.websiteProgressMilestoneContainer}
        style={{
          right: `calc((100 - ${position}) / 100 * 100%)`,
          width: `calc((100 / ${numMilestones}) / 100 * 100%)`,
        }}
      >
        <Box className={classes.websiteProgressMilestoneLabelContainer}>
          <Typography
            className={classes.websiteProgressMilestoneLabel}
            variant="body1"
          >
            {label}
          </Typography>
        </Box>
        {/* Only show line border if not at the end position. */}
        {position < 100 ? (
          <Grid className={classes.websiteProgressMilestoneLineBorder}>
            <Box
              style={{
                backgroundColor: COLORS.white,
                flexGrow: "1",
                height: "100%",
                width: "2px",
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }

  ProgressMilestoneLine.propTypes = {
    label: PropTypes.string,
    position: PropTypes.number,
  };

  /**
   * Represents a list item element to display donor pledge data for the Donor
   * Ticker cards list.
   *
   * @param {object} props - The component props object.
   * @param {object} props.donorPledge - The Donor Pledge data object.
   * @param {*} props.itemRef - The Ref to apply to the component list item element.
   *
   * @returns {React.ReactElement} The DonorTickerCardListItem component.
   */
  function DonorTickerCardListItem({ donorPledge, ...props }) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <li
        className={[classes.websiteDonorTickerListItem].join(" ")}
        data-pledge-id={donorPledge.pledgeID}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Card
          className={
            donorPledge.donorDisplayName && donorPledge.pledgeAmount
              ? [classes.websiteDonorTickerCard].join(" ")
              : [classes.websiteDonorTickerCard, "blank"].join(" ")
          }
        >
          <CardContent
            className={[classes.websiteDonorTickerCardContent].join(" ")}
          >
            {donorPledge.donorDisplayName && donorPledge.pledgeAmount ? (
              <>
                <Typography
                  className={[
                    classes.textTruncate,
                    classes.websiteDonorTickerCardDonorName,
                  ].join(" ")}
                  variant="h3"
                >
                  {donorPledge.donorDisplayName}
                </Typography>
                <Typography
                  className={[classes.websiteDonorTickerCardPledgeAmount].join(
                    " "
                  )}
                  variant="body1"
                >
                  {formatUSD(`${donorPledge.pledgeAmount}`, 0)}
                </Typography>
              </>
            ) : null}
          </CardContent>
        </Card>
      </li>
    );
  }

  DonorTickerCardListItem.propTypes = {
    donorPledge: PropTypes.object,
  };

  /**
   * Convenience function to fetch and parse configuration JSON.
   *
   * @param {object} [params] - The function params object.
   * @param {Function} [params.callback] - The optional callback function to call after config fetch is complete.
   */
  function fetchConfig({ callback } = { callback: null }) {
    fetch(
      `${process.env.PUBLIC_URL}/assets/json/config.main.json?r=${Math.ceil(
        Math.random() * 10000000000
      )}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setConfigData(json);
        if (callback) {
          callback();
        }
      });
  }

  /**
   * Convenience function to calculate and set the last donors and pledges.
   *
   * @param {object} [params] - The function params object.
   * @param {boolean} [params.autoPop] - Optional boolean flag to denote whether or not to auto-pop the last item from the array of final pledges for the ticker.
   */
  function calculateAndSetLastDonorsAndPledges(
    { autoPop } = { autoPop: true }
  ) {
    // Get pledges and order by oldest/smallest pledgeID.
    const projectPledges = pledgesForProject || [];
    projectPledges.sort((a, b) => (a.pledgeID < b.pledgeID ? 1 : -1));
    let filteredProjectPledges =
      projectPledges.length > 5
        ? projectPledges.filter(
            (pledge) => !donorPledgesForProject[pledge.pledgeID]
          )
        : projectPledges;
    if (filteredProjectPledges.length <= 6) {
      filteredProjectPledges = projectPledges.slice(0, 6);
    }

    const finalPledgesForTicker = filteredProjectPledges;
    // Only set last five donors if there are more for scrolling. Further, only
    // try and get the last pledge if the `autoPop` param attribute is true.
    if (finalPledgesForTicker.length > 6) {
      const lastPledge = autoPop ? finalPledgesForTicker.pop() : null;
      finalPledgesForTicker.reverse();
      const newDonorPledges = {
        ...donorPledgesForProject,
      };
      if (lastPledge) {
        newDonorPledges[lastPledge.pledgeID] = lastPledge;
      }
      setDonorPledgesForProject(newDonorPledges);
      setLastFiveDonorsAndPledges(() => {
        return finalPledgesForTicker.slice(0, 6);
      });
    } else {
      finalPledgesForTicker.reverse();
      if (!lastFiveDonorsAndPledges.length && finalPledgesForTicker.length) {
        setLastFiveDonorsAndPledges(finalPledgesForTicker);
      } else if (
        finalPledgesForTicker.length &&
        lastFiveDonorsAndPledges.length &&
        finalPledgesForTicker[finalPledgesForTicker.length - 1].pledgeID !==
          lastFiveDonorsAndPledges[lastFiveDonorsAndPledges.length - 1].pledgeID
      ) {
        setLastFiveDonorsAndPledges(() => {
          return finalPledgesForTicker;
        });
      }
    }
  }

  /**
   * Convenience effect to set the list of last donors and pledges.
   */
  React.useEffect(() => {
    calculateAndSetLastDonorsAndPledges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pledgesForProject]);

  /**
   * Convenience function to calculate project pledge amount values.
   *
   * @param {object} [params] - The function params object.
   * @param {Function} [params.callback] - Optional callback function to invoke after calculations are complete.
   * @param {boolean} [params.shouldAutoSetState] - Optional boolean flag to specify whether or not the function should auto-set the state value for last donors and pledges.
   */
  const calculateProjectPledges = React.useCallback(
    (
      { callback, shouldAutoSetState } = {
        callback: null,
        shouldAutoSetState: false,
      }
    ) => {
      if (configData?.donorTickerCardDuration) {
        // Get pledges and order by oldest/smallest pledgeID.
        const projectPledges = pledgesForProject || [];
        projectPledges.sort((a, b) => (a.pledgeID < b.pledgeID ? 1 : -1));
        const finalVisibleProjectPledges = projectPledges.filter(
          (pledgeItem) =>
            donorPledgesForProject[pledgeItem.pledgeID] ||
            getArrayObjectByAttribute({
              array: lastFiveDonorsAndPledges,
              attribute: "pledgeID",
              attributeValue: pledgeItem.pledgeID,
            })
        );
        const newAmount = Object.values(finalVisibleProjectPledges)
          .map((pledge) => {
            return pledge;
          })
          .reduce(addPledge, 0);
        setCumulativeProgressAmount(newAmount);

        if (shouldAutoSetState) {
          if (callback) {
            callback();
          }
          if (lastFiveDonorsAndPledges.length) {
            const lastArrayItem = lastFiveDonorsAndPledges.slice(0, 1)[0];
            const lastItemPledgeId = lastArrayItem.pledgeID;
            const newDonorPledges = {
              ...donorPledgesForProject,
              [lastItemPledgeId]: lastArrayItem,
            };
            setDonorPledgesForProject(newDonorPledges);
          }
        } else if (callback) {
          callback();
        }
      } else if (callback) {
        callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      configData?.donorTickerCardDuration,
      cumulativeProgressAmount,
      donorPledgesForProject,
      lastFiveDonorsAndPledges,
      pledgesForProject,
      setCumulativeProgressAmount,
    ]
  );

  /**
   * Handler function for fetch pledges for project complete.
   *
   * @param {object} data - The data object returned from pledges for project retrieval.
   */
  const handleOnFetchPledgesForProject = React.useCallback(
    (data) => {
      if (data) {
        setPledgesForProject(data[configData.projectNumber]);
        calculateProjectPledges({
          callback: () => {
            // Get pledges and order by oldest/smallest pledgeID.
            const projectPledges = data[configData.projectNumber];
            projectPledges.sort((a, b) => (a.pledgeID < b.pledgeID ? 1 : -1));
            const finalVisibleProjectPledges = projectPledges.filter(
              (pledgeItem) =>
                donorPledgesForProject[pledgeItem.pledgeID] ||
                getArrayObjectByAttribute({
                  array: lastFiveDonorsAndPledges,
                  attribute: "pledgeID",
                  attributeValue: pledgeItem.pledgeID,
                })
            );
            const newAmount = Object.values(finalVisibleProjectPledges)
              .map((pledge) => {
                return pledge;
              })
              .reduce(addPledge, 0);
            setCumulativeProgressAmount(newAmount);
          },
        });
        setApiIntervalCount((prevCount) => {
          return prevCount + 1;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      calculateProjectPledges,
      configData?.projectNumber,
      cumulativeProgressAmount,
      donorPledgesForProject,
      setCumulativeProgressAmount,
      setPledgesForProject,
    ]
  );

  /**
   * Convenience effect to set repeated interval for API data retrieval to
   * simulate real-time data updates.
   */
  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (configData && initialPledgesRetrieved) {
      const apiInterval = setInterval(() => {
        onFetchPledgesForProject({
          callback: handleOnFetchPledgesForProject,
          hideBackdrop: true,
          projectNumber: configData.projectNumber,
          silentFail: true,
        });
      }, configData.donorTickerCardDuration);
      return () => clearInterval(apiInterval);
    }
  }, [
    apiIntervalCount,
    configData,
    handleOnFetchPledgesForProject,
    initialPledgesRetrieved,
    onFetchPledgesForProject,
  ]);

  /**
   * Convenience effect to calculate and set the current milestone based on the
   * cumulative progress amount relative to the various milestone amounts.
   */
  React.useEffect(() => {
    if (configData) {
      if (newPledgeAudio) {
        newPledgeAudio.play();
      }
      for (let i = 0; i < configData.milestones.length; i += 1) {
        if (cumulativeProgressAmount >= configData.milestones[i].amount) {
          // Keep calm and loop on!
        } else {
          setCurrentMilestone(i);
          break;
        }
      }
    }
  }, [configData, cumulativeProgressAmount, newPledgeAudio]);

  /**
   * Convenience effect to scroll donor ticker into place once set. Note that
   * this includes scrolling the first child into view, and also immediately
   * scrolling by the amount of one card to ensure there are smooth transitions
   * when the state is updated.
   */
  React.useEffect(
    () => {
      if (donorTickerRef?.current && !hasScrolledIntoView) {
        if (nextPledgeItemForScroll) {
          donorTickerRef.current.firstChild.scrollIntoView({
            behavior: "instant",
            inline: "start",
          });
          calculateAndSetLastDonorsAndPledges({ autoPop: false });
        }
        setHasScrolledIntoView(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      donorTickerRef?.current?.firstChild,
      hasScrolledIntoView,
      setHasScrolledIntoView,
    ]
  );

  /**
   * Convenience effect to scroll donor ticker when list of donors changes.
   */
  React.useEffect(() => {
    if (donorTickerRef?.current && hasScrolledIntoView) {
      // Calculate amount to scroll based on ticker card width.
      const amount = donorTickerRef
        ? donorTickerRef.current.firstChild.offsetWidth
        : 0;
      donorTickerRef?.current?.scrollBy({
        behavior: "instant",
        left: amount,
        top: 0,
      });
      setTimeout(() => {
        donorTickerRef?.current?.scrollBy({
          behavior: "smooth",
          left: amount * -1,
          top: 0,
        });
      }, 1000);
    }
    setTimeout(() => {
      calculateProjectPledges();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFiveDonorsAndPledges, donorTickerRef, hasScrolledIntoView]);

  /**
   * Convenience effect to calculate project pledges when config data is found.
   * Note this includes scrolling the first child into view, and also
   * immediately scrolling by the amount of one card to ensure there are smooth
   * transitions when the state is updated.
   */
  React.useEffect(
    () => {
      if (configData && !initialPledgesRetrieved) {
        calculateProjectPledges({
          callback: () => {
            onFetchPledgesForProject({
              callback: handleOnFetchPledgesForProject,
              hideBackdrop: true,
              projectNumber: configData.projectNumber,
              silentFail: true,
            });
            setInitialPledgesRetrieved(true);
            if (donorTickerRef?.current) {
              setTimeout(() => {
                if (nextPledgeItemForScroll) {
                  donorTickerRef.current.firstChild.scrollIntoView({
                    behavior: "instant",
                    inline: "start",
                  });
                  calculateAndSetLastDonorsAndPledges({ autoPop: false });
                }
              }, 1000);
            }
          },
          shouldAutoSetState: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // calculateProjectPledges,
      configData,
      // handleOnFetchPledgesForProject,
      initialPledgesRetrieved,
      // onFetchPledgesForProject,
    ]
  );

  /**
   * Convenience effect to fetch app data and config to ensure there is donor
   * and project list data, as well as website configuration data.
   */
  React.useEffect(() => {
    onFetchAppData({
      callback: () => {
        fetchConfig();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {strings && configData ? (
        <>
          <Grid
            className={[classes.defaultContainer, classes.websiteWrapper].join(
              " "
            )}
            container={true}
          >
            {/* Audio file for new pledges. */}
            <audio autoPlay={true} id="newPledgeAudio">
              <source src={configData.audioFile} type="audio/wav" />
            </audio>
            {/* Left Panel */}
            <Grid className={[classes.websiteLeftPanel].join(" ")} item={true}>
              {/* Milestone Image */}
              <Grid
                className={[classes.websiteMilestoneImage].join(" ")}
              ></Grid>

              {/* Donor Name + Amount Ticker */}
              {lastFiveDonorsAndPledges.length ? (
                <Slide direction="right" in={true}>
                  <div className={classes.websiteDonorTicker}>
                    <ul
                      className={classes.websiteDonorTickerList}
                      id="donor-ticker-list"
                      ref={donorTickerRef}
                    >
                      {lastFiveDonorsAndPledges.map((pledge, index) => (
                        <DonorTickerCardListItem
                          donorPledge={pledge}
                          id={`pledge-${pledge.pledgeID}`}
                          itemRef={pledgeRefs[pledge.pledgeID]}
                          key={`donor-${index}`}
                        />
                      ))}
                    </ul>
                  </div>
                </Slide>
              ) : null}

              {/* Progress Bar */}
              <Grid className={[classes.websiteProgressBar].join(" ")}>
                <LinearProgress
                  className={classes.websiteLinearProgress}
                  value={pledgeAmountProgress}
                  variant="determinate"
                />
                {configData.milestones.map((milestone, index) => {
                  return (
                    <ProgressMilestoneLine
                      key={`milestone-${milestone.label
                        .replace(" ", "-")
                        .toLowerCase()}-${index}`}
                      label={milestone.label}
                      position={milestone.progressPercent}
                    />
                  );
                })}
                <Box className={classes.websiteProgressBarLabelContainer}>
                  <Typography
                    className={classes.websiteProgressBarLabel}
                    variant="h3"
                  >
                    {formatUSD(`${cumulativeProgressAmount}`, 0)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Right Panel */}
            <Grid className={[classes.websiteRightPanel].join(" ")} item={true}>
              {/* Top Panel: Instructions */}
              <Grid
                className={[classes.websiteInstructions].join(" ")}
                item={true}
              >
                <Box onDoubleClick={onResetApp}>
                  <img
                    alt=""
                    className={[classes.websiteMobilePhoneImage].join(" ")}
                    src={configData.mobilePhoneImage}
                  />
                </Box>
                <div
                  className={classes.websiteInstructionsText}
                  dangerouslySetInnerHTML={{
                    __html: configData.instructionsText,
                  }}
                ></div>
                <div
                  className={classes.websiteInstructionsTextPhoneNumber}
                  dangerouslySetInnerHTML={{
                    __html: configData.instructionsTextPhoneNumber,
                  }}
                ></div>
                <Box onDoubleClick={onResetApp}>
                  <img
                    alt=""
                    className={[classes.websiteQrCodeImage].join(" ")}
                    src={configData.instructionsQRCode}
                  />
                </Box>
              </Grid>

              {/* Bottom Panel: Goal */}
              <Grid className={[classes.websiteGoal].join(" ")} item={true}>
                <Typography
                  className={[classes.websiteGoalContent].join(" ")}
                  variant="h3"
                >
                  {configData.goalText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
}

HomeView.propTypes = {
  apiStatus: PropTypes.string,
  donorList: PropTypes.array.isRequired,
  onBackdropUpdate: PropTypes.func,
  onFetchAppData: PropTypes.func,
  onFetchPledgesForProject: PropTypes.func,
  onNewDonorSubmit: PropTypes.func,
  onResetApp: PropTypes.func,
  onSignOut: PropTypes.func,
  onUserAuthenticated: PropTypes.func,
  pledgesForProjectsList: PropTypes.object.isRequired,
  projectList: PropTypes.array.isRequired,
  user: PropTypes.object,
};

HomeView.defaultProps = {
  apiStatus: STATUS_TYPES.IDLE,
  onBackdropUpdate: () => {},
  onFetchAppData: () => {},
  onFetchPledgesForProject: () => {},
  onNewDonorSubmit: () => {},
  onResetApp: () => {},
  onUserAuthenticated: () => {},
  user: null,
};
