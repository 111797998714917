/**
 * @module Locales
 */
import { TODAY } from 'utils/constants';

const strings = {
  en: {
    devices: {
      manual: 'manual',
      sms: 'SMS',
    },
    dialogs: {
      messages: {
        auction_closed:
          'The auction has successfully been closed. All donor and pledge data has been synced and SMS messages have been sent to all donors.',
        close_auction:
          'Are you sure you want to close the auction? This action cannot be undone.',
        donation_amount_more_than_remaining:
          'The donation amount specified is larger than what is available to be funded for the selected project. Please update the amount and try again.',
        donor_flagged_for_review: 'Donor flagged for review.',
        donor_marked_as_paid: 'Donor marked as paid.',
        flag_donor_for_review:
          'Are you sure you want to flag this donor for review?',
        flag_pledge_for_review:
          'Are you sure you want to flag this pledge for review?',
        mark_donor_as_paid: 'Are you sure you want to mark this donor as paid?',
        mark_pledge_as_paid:
          'Are you sure you want to mark this pledge as paid?',
        no_donor_selected:
          'No donor has been selected. Please select a donor and try again.',
        no_project_selected:
          'No project has been selected. Please select a project and try again.',
        pledge_flagged_for_review: 'Pledge flagged for review.',
        pledge_marked_as_paid: 'Donor marked as paid.',
        project_fully_funded:
          'The selected project is already fully funded. Please select another project and try again.',
        reset_app_data:
          'Are you sure you want to reset the app data? This will clear any unsynced data and reset the app to the last synced state.',
        sign_out: 'Are you sure you want to sign out?',
        sms_list_messages_blank: 'No donors found on final SMS list.',
        sync_success: 'The data was successfully synced!',
        upload_sync_no_data:
          'The app data is already fully up to date, and there is nothing needing to upload and sync.',
      },
      titles: {
        auction_closed: 'Auction Closed',
        close_auction: 'Close Auction',
        donation_amount_more_than_remaining:
          'Donation Amount Exceeds Available Project Amount',
        donor_list: 'Donor List',
        flag_donor_for_review: 'Flag Donor for Review',
        flag_pledge_for_review: 'Flag Pledge for Review',
        mark_donor_as_paid: 'Mark Donor as Paid',
        mark_pledge_as_paid: 'Mark Pledge as Paid',
        new_donor: 'New Donor',
        no_donor_selected: 'No Donor Selected',
        no_project_selected: 'No Project Selected',
        project_fully_funded: 'Project Fully Funded',
        project_list: 'Project List',
        reset_app_data: 'Reset App Data',
        sign_out: 'Sign Out',
        success: 'Success',
      },
    },
    forms: {
      create_pledge: {
        submit_error:
          'Sorry, there was an issue creating the pledge. Please try again.',
      },
      helperText: {
        amount: 'A valid pledge amount is required.',
        first_name: 'First name must be between 1 and 255 characters.',
        last_name: 'Last name must be between 1 and 255 characters.',
        password: 'Password',
        username: 'A valid username is required.',
      },
      sign_in: {
        login_error:
          'Sorry, the username and password you entered were not found. Please try again.',
        title: 'Sign In',
      },
    },
    labels: {
      add: 'Add',
      admin_view_data_entry: 'Data Entry',
      admin_view_report: 'Report',
      amount: 'Amount',
      back: 'Back',
      balance: 'Balance',
      cancel: 'Cancel',
      clear: 'Clear',
      close: 'Close',
      continue: 'Continue',
      delete: 'Delete',
      edit: 'Edit',
      go: 'Go',
      goal: 'Goal',
      half: 'Half',
      home: 'Home',
      loading: 'Loading...',
      new_donor: 'New Donor',
      next: 'Next',
      no: 'No',
      ok: 'OK',
      password: 'Password',
      project: 'Project',
      remainder: 'Remainder',
      save: 'Save',
      select_donor: 'Select Donor...',
      select_project: 'Select Project...',
      send: 'Send',
      submit: 'Submit',
      sync: 'Sync',
      toggle_password_visibility: 'Toggle Password Visibility',
      username: 'Username',
      yes: 'Yes',
    },
    link_titles: {
      return_to_home: 'Return to the Home page',
      sign_in: 'Sign In',
    },
    menu: {
      close_auction: 'Close Auction',
      download_data: 'Download Fresh Data',
      menu: 'Menu',
      reset_app_data: 'Reset App',
      sign_out: 'Sign Out',
      upload_data: 'Upload & Sync Data',
    },
    messages: {
      errors: {
        api_ping_status:
          "Sorry, but there was an error connecting to retrieve data. Please ensure that you are connected to the Internet and that your computer's security settings allow this website domain.",
        app_donation_saved: 'Error saving donation.',
        pledges_for_project:
          'Sorry, pledges for project could not be fetched. Please provide a valid project number and try again.',
        sms_list_messages:
          'Sorry, but there was an error sending SMS messages to donors. Please try again.',
        upload_sync_data_donors:
          'Sorry, but there was an error uploading and syncing donors. Please try again.',
        upload_sync_data_pledges:
          'Sorry, but there was an error uploading and syncing pledges. Please try again.',
      },
      success: {
        api_ping_status: 'Successfully connected to Faith Story App API.',
        app_donation_saved: 'Donation saved!',
        sms_list_messages:
          'Successfully sent donation summary SMS message to donors.',
        upload_sync_data: 'All data successfully uploaded and synced.',
      },
      user_data: {
        delete_data_message: 'Are you sure you want to delete this data?',
        delete_data_message_success: 'Data successfully deleted.',
      },
    },
    pages: {
      app: {
        home: {
          content_html: `<p>Welcome to the ${TODAY.getFullYear()} Messenger Cup Auction App!</p>`,
          title: '<h1>Messenger Cup Auction</h1>',
        },
      },
      not_found: {
        content_html: '<p>The page you requested was not found.</p>',
        title: '<h1>Page Not Found</h1>',
      },
      reset: {
        content_html:
          '<p>Use the actions listed here to help in managing and resetting your stored user data and locally-stored browser data. These actions can be beneficial in resetting data and enduring a clean slate for testing the site and its functionality.',
        instructions:
          'Use the actions listed here to help in managing and resetting your stored user data and locally-stored browser data. These actions can be beneficial in resetting data and enduring a clean slate for testing the site and its functionality.',
        sections: {
          clear_local_storage: {
            button_label: 'Clear Most Local Data',
            dialog_button_cancel: 'Cancel',
            dialog_button_confirm: 'Clear',
            dialog_message:
              'Are you sure you want to clear local data? This action cannot be undone.',
            dialog_message_success: 'Local data successfully deleted.',
            instructions:
              'Clear the locally-stored data kept in the browser except the authenticated user information.',
            title: 'Clear Most Local Data',
            type: 'clear_local_storage',
          },
          delete_user: {
            button_label: 'Clear All Local Data',
            dialog_button_cancel: 'Cancel',
            dialog_button_confirm: 'Delete',
            dialog_message:
              'Are you sure you want to delete the locally-stored user data? This will log you out and cause you to need to reauthenticate.',
            dialog_message_success: 'User successfully deleted.',
            instructions:
              'Clear all locally-stored data and user-specific data stored in the browser. This will log you out and cause you to need to reauthenticate.',
            title: 'Clear All Local Data',
            type: 'delete_user',
          },
        },
        title: 'Reset',
      },
      website: {
        home: {
          content_html: `<p>This section is still pending final design, functional requirements, and development. Please check back later.</p>`,
          title: `<h1>Messenger Cup Auction Site</h1>`,
        },
      },
    },
    placeholders: {
      amount: 'Amount',
      first_name: 'First Name',
      last_name: 'Last Name',
      password: 'Password',
      username: 'Username',
    },
    status_messages: {
      fetching_data: 'Fetching data.',
      processing: 'Processing.',
      saving_data: 'Saving data.',
      submitting: 'Submitting.',
    },
  },
};

/**
 * Utilities to handle Locale.
 *
 * @param {string} locale - The locale key for which to load (e.g. 'en').
 *
 * @returns {object} - The locale data object.
 */
export default function useLocaleUtils(locale) {
  return {
    strings: strings[locale] || null,
  };
}
