/**
 * @module SignInForm
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import validator from 'validator';
import { LoadingButton } from 'components/ui/buttons';
import { useLocale } from 'context/locale';
import { STATUS_TYPES } from 'utils/constants';
import { useStyles } from 'utils/styles';

/**
 * Represents the Sign In form component and controls.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.apiStatus] - The API status, to help determine the state of the sign-in and loading button.
 * @param {string} props.className - Additional classes to be applied to the form container.
 * @param {Error} [props.error] - The Error object associated with the form.
 * @param {Function} [props.onSubmit] - The function to call for Submit form click.
 * @param {string} [props.referrer] - Optional referrer query parameter value to pass along to Sign In.
 *
 * @returns {React.ReactElement} - The SignInForm component.
 */
export function SignInForm({
  apiStatus,
  className,
  error,
  onSubmit,
  // eslint-disable-next-line no-unused-vars
  referrer,
}) {
  const classes = useStyles();
  const { strings } = useLocale();
  const [formData, setFormData] = React.useState({
    password: {
      hasBeenTouched: false,
      isError: null,
      value: '',
    },
    username: {
      hasBeenTouched: false,
      isError: null,
      value: '',
    },
  });
  const [isEnabled, setIsEnabled] = React.useState(
    !formData?.password?.isError || !formData?.username?.isError,
  );
  const [showPassword, setShowPassword] = React.useState(false);

  /**
   * Handler function for form input elements blur events.
   *
   * @param {Event} event - The Event object associated with the blur event.
   */
  function handleFormInputBlur(event) {
    const { id: inputId, value: inputValue } = event.target;
    const inputHasBeenTouched =
      formData[inputId].hasBeenTouched ||
      (!formData[inputId].hasBeenTouched && formData[inputId].value !== '');
    let isError = false;
    if (inputId === 'password' || inputId === 'username') {
      isError = validator.isEmpty(inputValue);
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [inputId]: {
          hasBeenTouched: inputHasBeenTouched,
          isError,
          value: formData[inputId]?.value || '',
        },
      };
    });
  }

  /**
   * Handler function for form input elements change events.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleFormInputChange(event) {
    const { id: inputId, value: inputValue } = event.target;
    let isError = false;
    if (inputId === 'password' || inputId === 'username') {
      isError = validator.isEmpty(inputValue);
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [inputId]: {
          hasBeenTouched: false, // Set explicitly to avoid error state while user typing.
          isError: isError && formData[inputId]?.hasBeenTouched,
          value: inputValue,
        },
      };
    });
  }

  /**
   * Handler function for Password show/hide toggle.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handlePasswordVisibilityToggle(event) {
    event.preventDefault();
    setShowPassword(!showPassword);
  }

  /**
   * Handler function for Submit form click.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleSubmit(event) {
    event.preventDefault();
    onSubmit({
      password: formData.password.value,
      username: formData.username.value,
    });
  }

  /**
   * Convenience effect to auto-set `isEnabled` when `formData` changes.
   */
  React.useEffect(() => {
    setIsEnabled(
      (!formData?.username?.isError || !formData?.password?.isError) &&
        formData?.password?.value !== '' &&
        !formData?.password?.isError &&
        formData?.username?.value !== '' &&
        !formData?.username?.isError,
    );
  }, [formData]);

  return (
    <>
      {strings ? (
        <form className={className} onSubmit={handleSubmit}>
          {/* Title and Navigation */}
          <Grid
            className={[
              classes.contentCenter,
              classes.gridVerticalAlignCenter,
              classes.mb1,
            ].join(' ')}
            container={true}
          >
            <Grid
              className={[classes.contentCenter, classes.fullWidth].join(' ')}
              item={true}
            >
              <Box>
                <Typography variant="h1">
                  {strings.forms.sign_in.title}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Main Container */}
          <Paper className={classes.formContainer} direction="row">
            {/* Error Container */}
            {error ? (
              <Box className={classes.errorContainer} mb={2}>
                <Box className={classes.mr05}>
                  <ErrorIcon />
                </Box>
                <Box>
                  <Typography>{error.message}</Typography>
                </Box>
              </Box>
            ) : null}

            {/* Username Input */}
            <FormControl className={classes.formControl}>
              <TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
                error={
                  (formData?.username?.isError &&
                    formData?.username?.hasBeenTouched) ||
                  false
                }
                helperText={
                  formData?.username?.isError &&
                  formData?.username?.hasBeenTouched
                    ? strings.forms.helper_text.username
                    : ''
                }
                id="username"
                label={strings.labels.username}
                onBlur={handleFormInputBlur}
                onChange={handleFormInputChange}
                placeholder={strings.placeholders.username}
                required={true}
                type="text"
                value={formData?.username?.value || ''}
                variant="outlined"
              />
            </FormControl>

            {/* Password Input */}
            <FormControl className={classes.formControl}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label={strings.labels.toggle_password_visibility}
                        onClick={handlePasswordVisibilityToggle}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={
                  (formData?.password?.isError &&
                    formData?.password?.hasBeenTouched) ||
                  false
                }
                helperText={
                  formData?.password?.isError &&
                  formData?.password?.hasBeenTouched
                    ? strings.forms.helper_text.password
                    : ''
                }
                id="password"
                label={strings.labels.password}
                onBlur={handleFormInputBlur}
                onChange={handleFormInputChange}
                placeholder={strings.placeholders.password}
                required={true}
                type={showPassword ? 'text' : 'password'}
                value={formData?.password?.value || ''}
                variant="outlined"
              />
            </FormControl>

            {/* Submit Button */}
            <Grid
              className={[classes.gridVerticalAlignCenter, classes.mt1].join(
                ' ',
              )}
              container={true}
            >
              <Grid
                className={[classes.contentCenter, classes.fullWidth].join(' ')}
                item={true}
              >
                <FormControl className={[classes.formControl].join(' ')}>
                  <LoadingButton
                    className={[classes.formButton, classes.textUppercase].join(
                      ' ',
                    )}
                    color="secondary"
                    disabled={!isEnabled || apiStatus === STATUS_TYPES.PENDING}
                    id="btn_submit"
                    isLoading={apiStatus === STATUS_TYPES.PENDING}
                    type="submit"
                    variant="contained"
                  >
                    {strings.labels.continue}
                  </LoadingButton>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </form>
      ) : null}
    </>
  );
}

SignInForm.propTypes = {
  apiStatus: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
    name: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  referrer: PropTypes.string,
};

SignInForm.defaultProps = {
  apiStatus: STATUS_TYPES.IDLE,
  className: '',
  error: null,
  onSubmit: () => {},
  referrer: '',
};
