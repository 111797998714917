/**
 * @module ProjectListDialog
 */
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useLocale } from 'context/locale';
import { formatNumberForDisplay } from 'utils/helpers';
import { useStyles } from 'utils/styles';

/**
 * Represents the Project List Dialog view.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.closeOnSelect] - Optional boolean flag to determine whether or not to auto-close dialog when an item is selected.
 * @param {Array} props.data - The array of data objects used to populate the dialog list content.
 * @param {boolean} [props.fullScreen] - Optional boolean flag to set full screen property (Default: true).
 * @param {Function} props.onClose - The function to fire on dialog close.
 * @param {Function} props.onProjectSelect - The function to fire when a project is selected.
 * @param {boolean} [props.open] - Boolean flag for whether or not dialog is open.
 * @param {boolean} [props.selectedProject] - The optional selected project attribute.
 *
 * @returns {React.ReactElement} - The ProjectListDialog component.
 */
export function ProjectListDialog({
  closeOnSelect,
  data,
  fullScreen,
  onClose,
  onProjectSelect,
  open,
  selectedProject,
}) {
  const classes = useStyles();
  const { strings } = useLocale();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      {strings && data ? (
        <>
          <Dialog
            PaperProps={{ className: classes.projectListDialog }}
            aria-labelledby="confirmation-dialog-title"
            className={classes.projectListDialog}
            fullScreen={fullScreen}
            id="dialog_confirm"
            onClose={onClose}
            open={open}
            scroll="paper"
          >
            <Box className={classes.positionAbsoluteTopRight1}>
              <IconButton
                onClick={() => {
                  onClose();
                }}
              >
                <ClearIcon className={classes.appMenuCloseIcon} />
              </IconButton>
            </Box>
            <DialogTitle id="confirmation-dialog-title">
              {strings.dialogs.titles.project_list}
            </DialogTitle>
            <DialogContent dividers={false}>
              <Box>
                <ImageList
                  className={classes.projectListContainer}
                  cols={isSmall ? 1 : 2}
                  rowHeight="auto"
                >
                  {data.map((project, index) => {
                    return (
                      <ImageListItem
                        cols={1}
                        key={`${project.projectNumber}-${index}`}
                      >
                        <Card
                          className={
                            selectedProject &&
                            parseInt(selectedProject.projectNumber, 10) ===
                              parseInt(project.projectNumber, 10)
                              ? [classes.projectCard, 'selected'].join(' ')
                              : classes.projectCard
                          }
                          onClick={() => {
                            onProjectSelect(project);
                            if (closeOnSelect) {
                              onClose();
                            }
                          }}
                        >
                          <CardContent
                            className={[
                              classes.projectCardContent,
                              classes.textLeft,
                            ].join(' ')}
                          >
                            <Box>
                              <Typography
                                className={[
                                  classes.colorForest,
                                  classes.textBold,
                                ].join(' ')}
                                variant="caption"
                              >
                                {`Project ID: ${project.projectNumber}`}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                className={[
                                  classes.colorSand,
                                  classes.textBold,
                                  classes.textTruncate,
                                  'dark',
                                ].join(' ')}
                                variant="body2"
                              >
                                {project.projectName}
                              </Typography>
                            </Box>
                            <Box
                              className={[
                                classes.projectCardLocation,
                                classes.textTruncate,
                              ].join(' ')}
                            >
                              <Typography
                                className={[
                                  classes.projectCardLocation,
                                  classes.textBold,
                                  classes.textTruncate,
                                ].join(' ')}
                                variant="caption"
                              >
                                {`${project.projectRegion}`}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                className={classes.projectCardAmount}
                                variant="caption"
                              >
                                {`${formatNumberForDisplay(
                                  project.projectCost.toString(),
                                )}`}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              </Box>
            </DialogContent>
            <DialogActions className={classes.contentCenter}>
              <Button
                color="primary"
                id="btn_project_list_dialog_confirm"
                onClick={onClose}
                variant="contained"
              >
                {strings.labels.ok}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
}

ProjectListDialog.propTypes = {
  closeOnSelect: PropTypes.bool,
  data: PropTypes.array,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  onProjectSelect: PropTypes.func,
  open: PropTypes.bool,
  selectedProject: PropTypes.object,
};

ProjectListDialog.defaultProps = {
  closeOnSelect: false,
  fullScreen: true,
  open: false,
  selectedProject: null,
};
